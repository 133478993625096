import React, { useEffect, useState, useCallback } from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/working-together.json";
import { db } from "../../firebase/firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from "../../contexts/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Typography,
  Box,
  IconButton,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { VscFileBinary } from "react-icons/vsc";
import { BsPersonUp, BsPersonX, BsPersonAdd, BsRobot } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { format } from "date-fns";
import Slide from "@mui/material/Slide";
import { TfiClose } from "react-icons/tfi";
import { IoDocumentsOutline } from "react-icons/io5";

export default function Home() {
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [canViewPaySelection, setCanViewPaySelection] = useState(false);
  const [canViewNotifications, setCanViewNotifications] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastClearedDoc, setLastClearedDoc] = useState(null);
  const functions = getFunctions();
  const fetchEmployeeData = httpsCallable(functions, "fetchEmployeeData");
  const [userClaims, setUserClaims] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentAction, setCurrentAction] = useState("");

  const fetchUserClaims = useCallback(async (email) => {
    const getUserClaims = httpsCallable(functions, "getUserClaims");
    try {
      const result = await getUserClaims({ email });
      console.log("Fetched claims:", result.data);
      setUserClaims(result.data);
    } catch (error) {
      console.error("Error fetching user claims:", error);
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserClaims(user.email);
      setCanViewNotifications(
        user.photoURL.includes("Regional") ||
          user.photoURL.includes("Manager") ||
          user.photoURL.includes("Global") ||
          user.photoURL.includes("AGM") ||
          user.photoURL.includes("Accounting") ||
          user.photoURL.includes("OtherAdmin")
      );
      setCanViewPaySelection(
        user.photoURL.includes("Accounting") || user.photoURL.includes("Global")
      );
    }
  }, [user, fetchUserClaims]);

  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(
        propertiesRef,
        where("AdminEmail", "array-contains", email)
      );

      // Fetch based on roleEmail
      const querySnapshot1 = await getDocs(q1);
      const codes = [];
      querySnapshot1.forEach((doc) => {
        codes.push(doc.data().propCode);
      });

      // Fetch based on AdminEmail
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        const propCode = doc.data().propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
        }
      });

      setPropertyCodes(codes);
    };

    fetchPropertyCodes();
  }, [user]);

  const handleIconClick = async (docId, status) => {
    try {
      const entryRef = doc(db, "empRegister", docId);
      const entryDoc = await getDoc(entryRef);
      if (entryDoc.exists()) {
        const entryData = entryDoc.data();
        const currentStatus =
          status === "TERMINATED" ? entryData.terminated : entryData.Status;
        const updatedNotes = [
          ...(entryData.Notes || []),
          `${currentStatus} employee acknowledged by ${user.displayName}`,
        ];

        await updateDoc(entryRef, {
          [status === "TERMINATED" ? "terminated" : "Status"]: "APPROVED",
          Notes: updatedNotes,
        });

        // Show snackbar notification
        setSnackbarOpen(true);

        // Update data state to reflect the change
        setData((prevData) =>
          prevData.map((item) =>
            item.id === docId
              ? { ...item, Status: "APPROVED", Notes: updatedNotes }
              : item
          )
        );
        // Update data state to reflect the change
        setData((prevData) => prevData.filter((item) => item.id !== docId));
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const openDialog = (docId, status) => {
    setCurrentItem(docId);
    setCurrentAction(status);
    setDialogOpen(true);
  };

  const handleConfirm = async () => {
    if (currentItem && currentAction) {
      await handleIconClick(currentItem, currentAction);
    }
    setDialogOpen(false);
  };

  const fetchData = async () => {
    if (propertyCodes.length === 0) {
      setLoading(false);
      return;
    }
    console.log("FETCHING");
    const empRegisterRef = collection(db, "empRegister");
    const allDocs = [];

    for (let code of propertyCodes) {
      const queries = [
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("BANKING", "in", ["SUBMITTED", "REJECTED"])
        ),
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("ID", "in", ["SUBMITTED", "REJECTED"])
        ),
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("SSN", "in", ["SUBMITTED", "REJECTED"])
        ),
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("W4-IRS", "in", ["SUBMITTED", "REJECTED"])
        ),
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("W4-LOCAL", "in", ["SUBMITTED", "REJECTED"])
        ),
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("Status", "in", ["NEW", "REHIRED"])
        ),
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("paySelection", "in", ["SUBMITTED", "REJECTED", "RESUBMITTED"])
        ),
        query(
          empRegisterRef,
          where("PropID", "==", code),
          where("terminated", "in", ["TERMINATED"])
        ),
      ];

      for (let q of queries) {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          allDocs.push(doc);
        });
      }
    }

    const currentDate = new Date();
    const employeeMap = {};

    allDocs.forEach((doc) => {
      const data = doc.data();
      const dateUpdated = new Date(data.DateUpdated);
      const clearedBy = data.clearedBy || [];
      const userCleared = clearedBy.find(
        (entry) =>
          entry.userId === user.uid && new Date(entry.date) >= dateUpdated
      );

      if (userCleared) return; // Skip entries cleared by the user

      if (
        isNaN(dateUpdated) ||
        (dateUpdated && currentDate - dateUpdated > 14 * 24 * 60 * 60 * 1000)
      ) {
        return; // Skip entries older than 14 days
      }

      if (!employeeMap[data.EmpID]) {
        employeeMap[data.EmpID] = {
          id: doc.id,
          DateUpdated: format(dateUpdated, "MM/dd/yy"),
          EmpID: data.EmpID,
          NameFirst: data.NameFirst,
          NameLast: data.NameLast,
          PropID: data.PropID,
          SubmittedFields: new Set(),
          paySelection: data.paySelection,
          paySelectionApproval: data.paySelectionApproval,
          Status: data.Status,
          terminated: data.terminated,
        };
      }

      if (data["Status"] === "NEW")
        employeeMap[data.EmpID].SubmittedFields.add("New Hire");
      if (data["Status"] === "REHIRED")
        employeeMap[data.EmpID].SubmittedFields.add("Employee Rehired");
      if (data["terminated"] === "TERMINATED")
        employeeMap[data.EmpID].SubmittedFields.add("Employee Terminated");
      if (data.BANKING === "SUBMITTED")
        employeeMap[data.EmpID].SubmittedFields.add("Banking");
      if (data.ID === "SUBMITTED")
        employeeMap[data.EmpID].SubmittedFields.add("Identification");
      if (data.SSN === "SUBMITTED")
        employeeMap[data.EmpID].SubmittedFields.add("SSN");
      if (data["W4-IRS"] === "SUBMITTED")
        employeeMap[data.EmpID].SubmittedFields.add("W4 (IRS)");
      if (data["W4-LOCAL"] === "SUBMITTED")
        employeeMap[data.EmpID].SubmittedFields.add("W4 (Local)");
      if (data.paySelection === "SUBMITTED")
        employeeMap[data.EmpID].SubmittedFields.add("Pay Selection Submitted");

      if (data.BANKING === "REJECTED")
        employeeMap[data.EmpID].SubmittedFields.add(
          "Banking Document Rejected"
        );
      if (data.ID === "REJECTED")
        employeeMap[data.EmpID].SubmittedFields.add(
          "Identification Document Rejected"
        );
      if (data.SSN === "REJECTED")
        employeeMap[data.EmpID].SubmittedFields.add("SSN Document Rejected");
      if (data["W4-IRS"] === "REJECTED")
        employeeMap[data.EmpID].SubmittedFields.add("W4 Document Rejected");
      if (data["W4-LOCAL"] === "REJECTED")
        employeeMap[data.EmpID].SubmittedFields.add("W4 Document Rejected");
      if (data.paySelection === "REJECTED")
        employeeMap[data.EmpID].SubmittedFields.add("Pay Selection Rejected");
      if (data.paySelection === "RESUBMITTED")
        employeeMap[data.EmpID].SubmittedFields.add(
          "Pay Selection Resubmitted"
        );
    });

    const fetchedData = Object.values(employeeMap).map((item) => {
      const submittedFieldsArray = Array.from(item.SubmittedFields);

      const hasSubmittedOrRejectedFields = submittedFieldsArray.some((field) =>
        [
          "Banking",
          "Identification",
          "SSN",
          "W4 (IRS)",
          "W4 (Local)",
          "Pay Selection Submitted",
          "Banking Document Rejected",
          "Identification Document Rejected",
          "SSN Document Rejected",
          "W4 Document Rejected",
          "Pay Selection Rejected",
          "Pay Selection Resubmitted",
        ].includes(field)
      );

      const isAdmin = userClaims[`${item.PropID} Employee Admin`] === true;
      const ackEmployee = userClaims[`${item.PropID} Ack Emp Status`] === true;

      return {
        ...item,
        SubmittedFields: submittedFieldsArray.join(", "),
        ActionButtons: (
          <Box
            display="flex"
            justifyContent="left"
            alignItems="center"
            width="100%"
            sx={{ borderRadius: "36px", overflow: "hidden" }}
            bgcolor="#f2f2f2"
          >
            {item.Status === "NEW" && (canViewPaySelection || ackEmployee) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "4.5rem",
                  textAlign: "center",
                }}
              >
                <Tooltip title="ack new hire">
                  <IconButton
                    style={{
                      backgroundColor: "transparent",
                      border: "0",
                      color: "#202426",
                      fontSize: "1.35rem",
                    }}
                    onClick={() => openDialog(item.id, "NEW")}
                  >
                    <BsPersonAdd />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {item.Status === "REHIRED" &&
              (canViewPaySelection || ackEmployee) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "4.5rem",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="ack re-hire">
                    <IconButton
                      style={{
                        backgroundColor: "transparent",
                        border: "0",
                        color: "#202426",
                        fontSize: "1.35rem",
                      }}
                      onClick={() => openDialog(item.id, "REHIRED")}
                    >
                      <BsPersonUp />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            {item.terminated === "TERMINATED" &&
              (canViewPaySelection || ackEmployee) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "4.5rem",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="ack termination">
                    <IconButton
                      style={{
                        backgroundColor: "transparent",
                        border: "0",
                        color: "#202426",
                        fontSize: "1.35rem",
                      }}
                      onClick={() => openDialog(item.id, "TERMINATED")}
                    >
                      <BsPersonX />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            {canViewPaySelection &&
              (item.paySelection === "SUBMITTED" ||
                item.paySelection === "REJECTED" ||
                item.paySelection === "RESUBMITTED") && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "4.5rem",
                    textAlign: "center",
                  }}
                >
                  {canViewPaySelection && (
                    <a
                      href={item.paySelectionApproval}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Tooltip title="pay selection">
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color:
                              item.paySelection === "SUBMITTED"
                                ? "#202426"
                                : item.paySelection === "REJECTED"
                                ? "#F98B85"
                                : item.paySelection === "RESUBMITTED"
                                ? "#FFA500"
                                : "#202426",
                            fontSize: "1.5rem",
                          }}
                        >
                          <VscFileBinary />
                        </IconButton>
                      </Tooltip>
                    </a>
                  )}
                </Box>
              )}
            {isAdmin && hasSubmittedOrRejectedFields ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "4.5rem",
                  textAlign: "center",
                }}
              >
                <Tooltip title="review uploads">
                  <IconButton
                    style={{
                      backgroundColor: "transparent",
                      border: "0",
                      color: "#202426",
                      fontSize: "1.5rem",
                    }}
                    onClick={() =>
                      navigate("/update-Record", {
                        state: {
                          propertyCode: item.PropID,
                          selectedEmployee: item.EmpID,
                          formType: "Employee",
                        },
                      })
                    }
                  >
                    <IoDocumentsOutline />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "4.5rem",
                  textAlign: "center",
                }}
              >
                <Tooltip title="employee file">
                  <IconButton
                    style={{
                      backgroundColor: "transparent",
                      border: "0",
                      color: "#202426",
                      fontSize: "1.35rem",
                    }}
                    onClick={() =>
                      navigate("/update-Record", {
                        state: {
                          propertyCode: item.PropID,
                          selectedEmployee: item.EmpID,
                          formType: "Employee",
                        },
                      })
                    }
                  >
                    <BsRobot />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        ),
      };
    });

    setData(fetchedData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [propertyCodes, canViewPaySelection]);

  const handleClearEntry = async (docId) => {
    const entryRef = doc(db, "empRegister", docId);
    await updateDoc(entryRef, {
      clearedBy: arrayUnion({
        userId: user.uid,
        date: new Date().toISOString(), // Store the current date and time
      }),
    });

    // Set the last cleared document to enable undo
    setLastClearedDoc(docId);

    // Show snackbar
    setSnackbarOpen(true);

    // Update data state to reflect the change
    setData((prevData) => prevData.filter((item) => item.id !== docId));
  };

  const handleUndoClear = async () => {
    if (lastClearedDoc) {
      const docRef = doc(db, "empRegister", lastClearedDoc);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        const newClearedBy = docData.clearedBy.filter(
          (entry) => entry.userId !== user.uid
        );
        await updateDoc(docRef, {
          clearedBy: newClearedBy,
        });
        setLastClearedDoc(null);
        setSnackbarOpen(false);
        fetchData();
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString;
    }
    return format(date, "dd/MM/yy");
  };

  if (loading) {
    return (
      <div>
        <Lottie
          animationData={animationData}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }

  return (
    <div>
      {canViewNotifications ? (
        data.length > 0 ? (
          <Card
            sx={{
              borderRadius: "18px",
              padding: "1rem",
              backgroundColor: "#fcfcfc",
            }}
          >
            <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={maintitle200header} colSpan={4}>
                      <Typography
                        sx={{
                          ...sharedTextStyles,
                          marginRight: 9,
                          letterSpacing: "0.45rem",
                          fontWeight: "300",
                          fontSize: "1.71rem",
                          color: "#202426",
                        }}
                        variant="body1"
                        align="left"
                      >
                        CHANGES
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={maintitle200header}>
                      PROPERTY
                    </TableCell>
                    <TableCell align="left" sx={maintitle200header}>
                      SUBMITTED
                    </TableCell>
                    <TableCell align="left" sx={maintitle200header}>
                      ACTIONS
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={maintitle200header}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" size="small" sx={maintable}>
                        {item.DateUpdated}
                      </TableCell>
                      <TableCell align="left" size="small" sx={maintable}>
                        {item.EmpID}
                      </TableCell>
                      <TableCell align="left" size="small" sx={maintable}>
                        {item.NameFirst}
                      </TableCell>
                      <TableCell align="left" size="small" sx={maintable}>
                        {item.NameLast}
                      </TableCell>
                      <TableCell align="left" size="small" sx={maintable}>
                        {item.PropID}
                      </TableCell>
                      <TableCell align="left" size="small" sx={maintable}>
                        {item.SubmittedFields.split(", ").map(
                          (field, index) => (
                            <div key={index}>{field}</div>
                          )
                        )}
                      </TableCell>
                      <TableCell align="left" size="small" sx={maintable}>
                        {item.ActionButtons}
                      </TableCell>
                      <TableCell align="left" size="small" sx={maintable}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: "4.5rem",
                            textAlign: "center",
                          }}
                        >
                          <IconButton
                            style={{
                              backgroundColor: "transparent",
                              border: "0",
                              color: "#6C733D",
                              fontSize: "1.5rem",
                            }}
                            onClick={() => handleClearEntry(item.id)}
                          >
                            <MdClear />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              PaperProps={{
                sx: {
                  padding: "1rem",
                  borderRadius: "36px",
                  backgroundColor: "#f5f5f5",
                  position: "relative",
                },
              }}
            >
              <IconButton
                onClick={() => setDialogOpen(false)}
                sx={{
                  position: "absolute",
                  top: 9,
                  right: 9,
                  color: "#101010",
                }}
              >
                <TfiClose />
              </IconButton>
              <DialogTitle
                sx={{
                  letterSpacing: "0.2rem",
                  fontWeight: "400",
                  fontSize: "0.9rem",
                  color: "#101010",
                }}
                id="alert-dialog-title"
              >
                {"EMPLOYEE CHANGE"}
              </DialogTitle>
              <DialogContent
                sx={{
                  letterSpacing: "0.1rem",
                  fontWeight: "400",
                  fontSize: "0.9rem",
                  color: "#101010",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "0.9rem",
                    color: "#101010",
                  }}
                >
                  Acknowledge employee change?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleConfirm}
                  color="primary"
                  autoFocus
                  fullWidth
                  sx={{
                    backgroundColor: "#c7d57f",
                    borderRadius: "9px",
                    padding: "9.9px 18px 9.9px 18px",
                    color: "#010101",
                    letterSpacing: "0.1rem",
                    fontWeight: "800",
                    fontSize: "0.72rem",
                    border: "2px solid #f9f9f9",
                  }}
                >
                  ACKNOWLEDGE
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              sx={{
                width: 900,
                color: "secondary",
                "& .MuiSnackbarContent-root": {
                  backgroundColor: "#202426",
                  fontSize: "0.9rem",
                  fontWeight: "800",
                },
                "& .MuiButton-root": {
                  color: "black",
                  fontSize: "0.9rem",
                  fontWeight: "800",
                },
              }}
              open={snackbarOpen}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={3699}
              TransitionComponent={Slide}
              TransitionProps={{ enter: true, exit: false }}
              onClose={() => setSnackbarOpen(false)}
              message={
                <span
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "200",
                    letterSpacing: "0.09rem",
                  }}
                >
                  ACKNOWLEDGED
                </span>
              }
              action={
                <Button color="inherit" size="large" onClick={handleUndoClear}>
                  UNDO
                </Button>
              }
            />
          </Card>
        ) : (
          <Lottie
            animationData={animationData}
            style={{ width: "100%", height: "100%" }}
          />
        )
      ) : (
        <Lottie
          animationData={animationData}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </div>
  );
}

const maintable = {
  fontWeight: 400,
  fontSize: "0.9rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
  rowHeight: "0.1rem",
};
const maintitle200header = {
  fontWeight: 800,
  fontSize: "0.81rem",
  backgroundColor: "#f5f5f5",
  color: "#020202",
  letterSpacing: "0.1rem",
};

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};
