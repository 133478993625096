import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment";
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  FormControlLabel,
  Box,
  Card,
  CardHeader,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
} from "@mui/material";
import { IoMdBatteryCharging } from "react-icons/io";
import {
  MdClose,
  MdCheck,
  MdOutlineKingBed,
  MdOutlineSentimentNeutral,
  MdExpandCircleDown,
} from "react-icons/md";
import {
  BsEmojiSunglasses,
  BsCalendarWeek,
  BsFillPatchCheckFill,
  BsBatteryCharging
} from "react-icons/bs";
import { FcChargeBattery } from "react-icons/fc";
import { BiCoffee } from "react-icons/bi";
import { CiCirclePlus } from "react-icons/ci";
import {
  TbClockHour10,
  TbSquareCheckFilled,
  TbMoodSad2,
  TbMoodAngry,
} from "react-icons/tb";
import {
  FaBusinessTime,
  FaChevronRight,
  FaChevronLeft,
  FaCheck,
  FaUserLock,
} from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import {
  FcUnlock,
  FcPrivacy,
  FcDisclaimer,
  FcExpired,
  FcPlus,
  FcImport,
  FcLock,
} from "react-icons/fc";
import { RiEmotionHappyLine } from "react-icons/ri";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getFunctions, httpsCallable } from "firebase/functions";

const EmployeeTimePunches = () => {
  const { user } = useAuth();
  const [employees, setEmployees] = useState([]);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [payCycle, setPayCycle] = useState("");
  const [closePayPeriodDialogOpen, setClosePayPeriodDialogOpen] =
      useState(false);
  const [punches, setPunches] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [openAddPunchModal, setOpenAddPunchModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [isPayPeriodClosed, setIsPayPeriodClosed] = useState(false);
  const [closedPayPeriods, setClosedPayPeriods] = useState({});
  const [dialogState, setDialogState] = useState({
    A: 0,
    B: 0,
    C: 0,
    BreakDuration: 0,
  });
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const handlePropertyCodeChange = (event) => {
    setSelectedPropertyCode(event.target.value);
  };
  const [isAddPunchDialogOpen, setIsAddPunchDialogOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [missedPunchesDialogOpen, setMissedPunchesDialogOpen] = useState(false);
  const [missedPunches, setMissedPunches] = useState([]);
  const [confirmDeleteChecked, setConfirmDeleteChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [closeAllDialogOpen, setCloseAllDialogOpen] = useState(false);
  const [reOpenPayPeriodDialogOpen, setReOpenPayPeriodDialogOpen] =
      useState(false);
  const [noShowChecked, setNoShowChecked] = useState(false);
  const [minWage, setMinWage] = useState([]);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [logMessages, setLogMessages] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [newChange, setNewChange] = useState("");


  const [claims, setClaims] = useState({});

  const canViewRates =
      user.photoURL.includes("Global") ||
      user.photoURL.includes("Accounting") ||
      user.photoURL.includes("Regional") ||
      (claims[`${selectedPropertyCode} Employee Admin`] &&
          claims[`${selectedPropertyCode} View Pay Rates`]);

  useEffect(() => {
    if (user && user.email) {
      const functions = getFunctions();
      const getUserClaims = httpsCallable(functions, "getUserClaims");
      getUserClaims({ email: user.email })
          .then((result) => {
            setClaims(result.data);
            console.log("Fetched claims:", result.data);
          })
          .catch((error) => {
            console.error("Error fetching user claims:", error);
          });
    }
  }, [user, user.email]);

  useEffect(() => {
    if (openModal && selectedEmployee !== null) {
      const initialDepartment = employees[selectedEmployee].Department;
      const initialEvent = {
        target: { value: initialDepartment },
      };
      handleModifyDepartmentChange(initialEvent);
    }
  }, [openModal, selectedEmployee]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
          collection(db, "empRegister"),
          where("PropID", "==", selectedPropertyCode)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const employees = snapshot.docs.map((doc) => ({
          id: doc.id,
          EmployeeId: doc.data().EmpID, // Map EmpID to EmployeeId
          FirstName: doc.data().NameFirst, // Map NameFirst to FirstName
          LastName: doc.data().NameLast, // Map NameLast to LastName
        }));

        // Set allEmployees state
        setAllEmployees(employees);

        // Set employeeName state
        if (employees.length > 0) {
          setEmployeeName(`${employees[0].FirstName} ${employees[0].LastName}`);
        } else {
          setEmployeeName("");
        }
      });

      return unsubscribe;
    }
  }, [selectedPropertyCode]);

  const findPayPeriodStartDateIndex = (punches, payPeriodStart) => {
    const payPeriodStartDate = moment(payPeriodStart);

    for (let i = 0; i < punches.length; i++) {
      const punchDate = moment(punches[i].InTime.toDate());
      if (punchDate.isSameOrAfter(payPeriodStartDate)) {
        return i;
      }
    }

    return -1;
  };

  const handleEmployeeMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmployeeMenuClose = (employeeId) => {
    setSelectedEmployeeId(employeeId);
    setAnchorEl(null);
  };

  const handleConfirmDeleteChange = (event) => {
    setConfirmDeleteChecked(event.target.checked);
  };

  const openReOpenPayPeriodDialog = () => {
    setReOpenPayPeriodDialogOpen(true);
  };

  const closeReOpenPayPeriodDialog = () => {
    setReOpenPayPeriodDialogOpen(false);
  };

  const handleNoShowCheckboxChange = (event) => {
    setNoShowChecked(event.target.checked);
  };

  const getMissedPunches = async (propertyCode) => {
    const missedPunchesQuery = query(
        collection(db, "missedPunches"),
        where("propCode", "==", propertyCode)
    );
    const missedPunchesSnapshot = await getDocs(missedPunchesQuery);
    let fetchedMissedPunches = missedPunchesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Sort missed punches by FirstName
    fetchedMissedPunches.sort((a, b) => {
      let nameA = a.FirstName.toUpperCase(); // Ignore upper and lowercase
      let nameB = b.FirstName.toUpperCase(); // Ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // Names must be equal
      return 0;
    });

    setMissedPunches(fetchedMissedPunches);
  };

  useEffect(() => {
    const updateBadgeCount = () => {
      const count = missedPunches.filter((missedPunch) => {
        const inTime = missedPunch.InTime ? missedPunch.InTime.toDate() : null;
        const outTime = missedPunch.OutTime
            ? missedPunch.OutTime.toDate()
            : null;
        return (
            (inTime && inTime >= payPeriodStart && inTime <= payPeriodEnd) ||
            (outTime && outTime >= payPeriodStart && outTime <= payPeriodEnd)
        );
      }).length;
      setBadgeCount(count);
    };

    if (selectedPropertyCode) {
      getMissedPunches(selectedPropertyCode).then(updateBadgeCount);
    }
  }, [selectedPropertyCode, missedPunches, payPeriodStart, payPeriodEnd]);

  const handleDeleteMissedPunch = async (missedPunchId) => {
    try {
      const missedPunchRef = doc(db, "missedPunches", missedPunchId);
      await deleteDoc(missedPunchRef);
      // Refresh missed punches data after deletion
      getMissedPunches(selectedPropertyCode);
    } catch (error) {
      console.error("Error deleting missed punch: ", error);
    }
  };

  const handleModifyDepartmentChange = (event) => {
    const department = event.target.value;

    if (employeeData) {
      let payRate, payType;

      if (department === employeeData.PayD1) {
        payRate = parseFloat(employeeData.PayD1_Rate); // Parse payRate as a number
        payType = employeeData.PayD1_Type;
      } else if (department === employeeData.PayD2) {
        payRate = parseFloat(employeeData.PayD2_Rate); // Parse payRate as a number
        payType = employeeData.PayD2_Type;
      }

      setDialogState((prevDialogState) => ({
        ...prevDialogState,
        Department: department,
        PayRate: payRate,
        PayType: payType,
      }));
    }
  };

  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(
          propertiesRef,
          where("AdminEmail", "array-contains", email)
      );

      // Fetch based on roleEmail
      const querySnapshot1 = await getDocs(q1);
      const codes = [];
      querySnapshot1.forEach((doc) => {
        codes.push(doc.data().propCode);
      });

      // Fetch based on AdminEmail
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        // Avoid duplicates
        const propCode = doc.data().propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
        }
      });

      setPropertyCodes(codes);
      if (!selectedPropertyCode && codes.length > 0) {
        setSelectedPropertyCode(codes[0]);
      }
    };

    fetchPropertyCodes();
  }, [user, selectedPropertyCode]);

  //Fetches property data from the "Properties" collection in the database,
  //calculates the pay period start and end dates based on the pay cycle, and updates the corresponding
  //state variables with the fetched data and calculated dates.
  useEffect(() => {
    const fetchProperties = async () => {
      if (!selectedPropertyCode) return;

      const propertiesDoc = await getDoc(
          doc(db, "Properties", selectedPropertyCode)
      );
      const { payPeriod, payCycle, minWage } = propertiesDoc.data();
      const currentDate = new Date();
      let payPeriodStartDate = new Date(payPeriod.seconds * 1000);
      let payPeriodEndDate;

      setPayCycle(payCycle);
      setMinWage(minWage);

      switch (payCycle) {
        case "Monthly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setMonth(payPeriodEndDate.getMonth() + 1);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Bi-Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 14);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        case "Weekly":
          while (payPeriodStartDate < currentDate) {
            payPeriodEndDate = new Date(payPeriodStartDate);
            payPeriodEndDate.setDate(payPeriodEndDate.getDate() + 7);
            if (payPeriodEndDate > currentDate) break;
            payPeriodStartDate = new Date(payPeriodEndDate);
          }
          break;
        default:
          break;
      }

      setPayPeriodStart(payPeriodStartDate);
      setPayPeriodEnd(payPeriodEndDate);
    };

    fetchProperties();
  }, [selectedPropertyCode]);

  //Fetches the closed pay periods from the "ClosedPayPeriods" collection in the database

  //Sets up a real-time listener for punch data in the "PUNCHES" collection where the "PropCode"
  //matches the user's "photoURL". The listener filters the data based on the pay period start and end dates
  //and updates the employees state with the fetched data.
  useEffect(() => {
    if (payPeriodStart && payPeriodEnd) {
      const q = query(
          collection(db, "PUNCHES"),
          where("PropCode", "==", selectedPropertyCode)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        // Clear existing employee data
        setEmployees([]);

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          const inTime = data.InTime.toDate();
          if (inTime >= payPeriodStart && inTime <= payPeriodEnd) {
            setEmployees((prevEmployees) => {
              const existingEmployee = prevEmployees.find(
                  (employee) => employee.id === doc.id
              );

              if (existingEmployee) {
                // Update existing employee
                return prevEmployees.map((employee) =>
                    employee.id === doc.id
                        ? {
                          id: doc.id,
                          ...data,
                          isEditable:
                              data.Status === "Open" ||
                              data.Status === "Manual" ||
                              data.Status === "Modified" ||
                              data.Status === "Missing",
                        }
                        : employee
                );
              } else {
                // Add new employee
                return [
                  ...prevEmployees,
                  {
                    id: doc.id,
                    ...data,
                    isEditable:
                        data.Status === "Open" ||
                        data.Status === "Manual" ||
                        data.Status === "Modified" ||
                        data.Status === "Missing",
                  },
                ];
              }
            });
          }
        });
      });

      return unsubscribe;
    }
  }, [user, payPeriodStart, payPeriodEnd, selectedPropertyCode]);

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const isUserInputValid = () => {
    return userInput.toUpperCase() === "Y";
  };

  const openEditModal = (id) => {
    const index = employees.findIndex((employee) => employee.id === id);
    setSelectedEmployee(index);

    // Set the initial values of A, B, and C in the dialog state
    const currentA = employees[index].A || 0;
    const currentB = employees[index].B || 0;
    const currentC = employees[index].C || 0;

    setDialogState({ A: currentA, B: currentB, C: currentC });

    setOpenModal(true);
  };

  const closeEditModal = () => {
    setOpenModal(false);
    setDialogState({ A: 0, B: 0, C: 0 });
  };

  const openAddPunchModalFn = () => {
    // if (!selectedEmployeeId) {
    //   alert("Please select an employee before adding a punch");
    //   return;
    // }
    setOpenAddPunchModal(true);
    setIsAddPunchDialogOpen(true); // Set isAddPunchDialogOpen to true when opening the dialog
  };

  const closeAddPunchModalFn = () => {
    setOpenAddPunchModal(false);
  };

  const openClosePayPeriodDialog = () => {
    setClosePayPeriodDialogOpen(true);
  };

  const closeClosePayPeriodDialog = () => {
    setClosePayPeriodDialogOpen(false);
  };

  // Function checks if the pay period is closed for a given employee by checking the status of their punches.
  const checkIfPayPeriodClosed = useCallback(
      (employeeId) => {
        const employeePunches = employees.filter(
            (employee) => employee.EmployeeId === employeeId
        );

        return employeePunches.every(
            (punch) =>
                punch.Status === "Closed" ||
                punch.Status === "Mod/Closed" ||
                punch.Status === "Man/Closed" ||
                punch.Status === "Mod/Missing"
        );
      },
      [employees]
  );

  // Updates the "closedPayPeriods" state based on the pay period closure status of employees.
  useEffect(() => {
    const updatedClosedPayPeriods = { ...closedPayPeriods };
    employees.forEach((employee) => {
      updatedClosedPayPeriods[employee.EmployeeId] = checkIfPayPeriodClosed(
          employee.EmployeeId
      );
    });
    setClosedPayPeriods(updatedClosedPayPeriods);
  }, [selectedEmployeeId, employees, checkIfPayPeriodClosed, closedPayPeriods]);

  const voidPreviousRecord = async (PeriodEndDate, EmployeeId) => {
    const payRollCollectionRef = collection(db, "payRoll");

    const querySnapshot = await getDocs(
        query(
            payRollCollectionRef,
            where("PeriodEndDate", "==", PeriodEndDate),
            where("EmployeeId", "==", EmployeeId),
            where("Status", "==", "Yes")
        )
    );

    querySnapshot.forEach((doc) => {
      updateDoc(doc.ref, { Status: "Void" });
    });
  };

  // const calculateEmployeePayData = (punches, employeeData, payPeriodStart) => {
  //   if (
  //       !punches ||
  //       !employeeData ||
  //       !employeeData.PayD1 ||
  //       !employeeData.PayD2
  //   ) {
  //     console.error(
  //         "Punches, department, or employeeData is null or undefined."
  //     );
  //     return {
  //       totalHoursForPayD1: 0,
  //       totalHoursForPayD2: 0,
  //       overtimeHoursForPayD1: 0,
  //       overtimeHoursForPayD2: 0,
  //       ptoHoursForPayD1: 0,
  //       ptoHoursForPayD2: 0,
  //       holidayHoursForPayD1: 0,
  //       holidayHoursForPayD2: 0,
  //       totalAmountForPayD1: 0,
  //       totalAmountForPayD2: 0,
  //       overallTotal: 0,
  //     };
  //   }
  //
  //   const totalHoursForPayD1 = calculateDecimalHoursForDepartment(
  //       punches,
  //       employeeData.PayD1,
  //       payPeriodStart,
  //       employeeData
  //   );
  //   const totalHoursForPayD2 = calculateDecimalHoursForDepartment(
  //       punches,
  //       employeeData.PayD2,
  //       payPeriodStart,
  //       employeeData
  //   );
  //
  //   const overtimeHoursForPayD1 = calculateOvertimeForDepartment(
  //       punches,
  //       employeeData.PayD1,
  //       payPeriodStart
  //   );
  //   const overtimeHoursForPayD2 = calculateOvertimeForDepartment(
  //       punches,
  //       employeeData.PayD2,
  //       payPeriodStart
  //   );
  //
  //   const ptoHoursForPayD1 = calculateTotalPTOForDepartment(
  //       punches,
  //       employeeData.PayD1
  //   );
  //   const ptoHoursForPayD2 = calculateTotalPTOForDepartment(
  //       punches,
  //       employeeData.PayD2
  //   );
  //
  //   const holidayHoursForPayD1 = calculateTotalHolidayHoursForDepartment(
  //       punches,
  //       employeeData.PayD1
  //   );
  //   const holidayHoursForPayD2 = calculateTotalHolidayHoursForDepartment(
  //       punches,
  //       employeeData.PayD2
  //   );
  //
  //   const overallTips = Number(
  //       punches
  //           .reduce(
  //               (total, employee) => total + (Number(employee.Tips) || 0),
  //               0
  //           )
  //           .toFixed(2));
  //
  //   const payRateD1 = employeeData.PayD1_Rate || 0;
  //   const payRateD2 = employeeData.PayD2_Rate || 0;
  //
  //   const totalAmountForPayD1 =
  //       (totalHoursForPayD1 + overtimeHoursForPayD1) * payRateD1 +
  //       ptoHoursForPayD1 +
  //       holidayHoursForPayD1 ;
  //   const totalAmountForPayD2 =
  //       (totalHoursForPayD2 + overtimeHoursForPayD2) * payRateD2 +
  //       ptoHoursForPayD2 +
  //       holidayHoursForPayD2;
  //
  //   const overallTotal = totalAmountForPayD1 + totalAmountForPayD2 + overallTips;
  //
  //   return {
  //     totalHoursForPayD1,
  //     totalHoursForPayD2,
  //     overtimeHoursForPayD1,
  //     overtimeHoursForPayD2,
  //     ptoHoursForPayD1,
  //     ptoHoursForPayD2,
  //     holidayHoursForPayD1,
  //     holidayHoursForPayD2,
  //     totalAmountForPayD1,
  //     totalAmountForPayD2,
  //     overallTotal,
  //   };
  // };

  //This async function is responsible for closing the pay period by updating the punch data in the "PUNCHES"
  //collection with the new status and other calculated data.
  const closePayPeriod = async () => {
    if (isUserInputValid()) {
      setIsLoading(true);

      const updatedEmployees = selectedEmployeeFiltered;

      // Define departments to exclude
      const excludedDepartments = [
        "N/A",
        "FIX",
        "NO-SHOW",
        "IN-HOUSE",
        "0",
        "VERIFY",
      ];

      // Check if any punch is in a restricted department
      const hasRestrictedDepartment = selectedEmployeeFiltered.some(
          (employee) => excludedDepartments.includes(employee.Department)
      );

      if (hasRestrictedDepartment) {
        console.log(
            "Cannot close pay period: Employee has punches in restricted departments."
        );
        setIsLoading(false);
        return; // Early exit
      }

      // Calculate total time in minutes for selected employee
      const totalTimeInMinutes = selectedEmployeeFiltered.reduce(
          (total, employee) => {
            if (employee.PayType === "PER ROOM") {
              const inTime = employee.InTime.toDate();
              const outTime = employee.OutTime.toDate();
              const timeDifference = getTimeDifference(inTime, outTime);
              const totalTimeDecimal = parseFloat(
                  differenceToDecimal(timeDifference)
              );
              return total + totalTimeDecimal * 60;
            } else {
              return total;
            }
          },
          0
      );

      // Calculate average time per room
      const averageTimePerRoom = totalTimeInMinutes / totalRoomsCleaned;

      for (const punch of updatedEmployees) {
        const punchDocRef = doc(db, "PUNCHES", punch.id);

        const timeDifference = getTimeDifference(
            punch.InTime.toDate(),
            punch.OutTime.toDate()
        );

        const decimalHours = differenceToDecimal(timeDifference);

        let newStatus;
        if (punch.Status === "Modified") {
          newStatus = "Mod/Closed";
        } else if (punch.Status === "Manual") {
          newStatus = "Man/Closed";
        } else if (punch.Status === "Missing") {
          newStatus = "Mod/Missing";
        } else {
          newStatus = "Closed";
        }

        const punchData = {
          TotalTime: formatTimeDifference(timeDifference),
          Decimal: punch.Holiday ? 0 : decimalHours,
          Holiday: punch.Holiday === undefined ? false : punch.Holiday, // Add default value for Holiday field
          Status: newStatus,
        };

        try {
          await updateDoc(punchDocRef, punchData);
        } catch (error) {
          console.error("Error updating document: ", error);
        }
      }

      const payRollData = {
        PeriodEndDate: Timestamp.fromDate(payPeriodEnd),
        PropertyCode: selectedPropertyCode,
        EmployeeId: selectedEmployeeId,
        FirstName: selectedEmployeeFiltered[0]?.FirstName,
        LastName: selectedEmployeeFiltered[0]?.LastName,
        Status: "Yes",
        EntryDate: new Date(),
        Totals: {
          Time: formatTimeDifference(
              selectedEmployeeFiltered.reduce((total, employee) => {
                const inTime =
                    employee.InTime instanceof Date
                        ? employee.InTime
                        : employee.InTime.toDate();
                const outTime =
                    employee.OutTime instanceof Date
                        ? employee.OutTime
                        : employee.OutTime.toDate();
                return total + getTimeDifference(inTime, outTime);
              }, 0)
          ),
          Overtime: Number(
              calculateOvertimeForEmployees(
                  selectedEmployeeFiltered,
                  payPeriodStart
              ).toFixed(2)
          ),
          Decimal: Number(
              calculateRegularHoursForEmployees(selectedEmployeeFiltered).toFixed(
                  2
              )
          ),
          PTO: calculateTotalPTOForEmployees(selectedEmployeeFiltered),
          Holiday: Number(
              parseFloat(
                  calculateTotalHolidayHoursForEmployees(selectedEmployeeFiltered)
              ).toFixed(2)
          ),
          Tips: Number(
              selectedEmployeeFiltered
                  .reduce(
                      (total, employee) => total + (Number(employee.Tips) || 0),
                      0
                  )
                  .toFixed(2)
          ),
          Rooms: selectedEmployeeFiltered.reduce(
              (total, employee) => total + employee.Rooms,
              0
          ),
          A: selectedEmployeeFiltered.reduce(
              (total, employee) => total + employee.A,
              0
          ),
          B: selectedEmployeeFiltered.reduce(
              (total, employee) => total + employee.B,
              0
          ),
          C: selectedEmployeeFiltered.reduce(
              (total, employee) => total + employee.C,
              0
          ),
          AverageTimePerRoom: averageTimePerRoom,
          Gross: overallTotal.toFixed(2),
        },
        D1: {
          HRS: totalHoursForPayD1.toFixed(2),
          OT: overtimeHoursForPayD1.toFixed(2),
          VAC: ptoHoursForPayD1,
          HOL: holidayHoursForPayD1.toFixed(2),
        },
        D2: {
          HRS: totalHoursForPayD2.toFixed(2),
          OT: overtimeHoursForPayD2.toFixed(2),
          VAC: ptoHoursForPayD2,
          HOL: holidayHoursForPayD2.toFixed(2),
        },
      };

      // Void the previous record if exists before writing the new one
      try {
        await voidPreviousRecord(
            Timestamp.fromDate(payPeriodEnd),
            selectedEmployeeId
        );
      } catch (error) {
        console.error("Error updating previous document to Void: ", error);
      }

      // Write the data to the "payRoll" collection
      try {
        const payRollCollectionRef = collection(db, "payRoll");
        await addDoc(payRollCollectionRef, payRollData);
      } catch (error) {
        console.error("Error adding document to payRoll collection: ", error);
      }

      closeClosePayPeriodDialog();
      setIsLoading(false); // Set isLoading to false after all the processing is done
    }
  };

  const closeAllPayPeriods = async () => {
    setIsLoading(true);
    setOperationInProgress(true);
    setLogMessages([]);

    const excludedDepartments = [
      "N/A",
      "FIX",
      "NO-SHOW",
      "IN-HOUSE",
      "0",
      "VERIFY",
    ];
    const payRollCollectionRef = collection(db, "payRoll");
    const batch = writeBatch(db);

    const filteredPunches = employees
        .filter((emp) => {
          const inTime = emp.InTime.toDate();
          const outTime = emp.OutTime.toDate();
          return (
              inTime >= payPeriodStart &&
              outTime < new Date(payPeriodEnd.getTime() + 86400000) &&
              !excludedDepartments.includes(emp.Department)
          );
        })
        .reduce((acc, emp) => {
          if (!acc[emp.EmployeeId]) acc[emp.EmployeeId] = [];
          acc[emp.EmployeeId].push(emp);
          return acc;
        }, {});

    for (const [employeeId, punchesForEmployee] of Object.entries(
        filteredPunches
    )) {
      if (
          punchesForEmployee.length === 0 ||
          checkIfPayPeriodClosed(employeeId)
      ) {
        console.log(
            `Skipping employee ${employeeId} - no records or already closed.`
        );
        continue;
      }

      const querySnapshot = await getDocs(
          query(
              payRollCollectionRef,
              where("EmployeeId", "==", employeeId),
              where("PeriodEndDate", "==", Timestamp.fromDate(payPeriodEnd)),
              where("Status", "==", "Yes")
          )
      );

      querySnapshot.forEach((docSnapshot) => {
        batch.update(docSnapshot.ref, { Status: "Void" });
      });

      const validPunches = punchesForEmployee.filter(
          (punch) => !excludedDepartments.includes(punch.Department)
      );

      punchesForEmployee.forEach((punch) => {
        if (!excludedDepartments.includes(punch.Department)) {
          const punchDocRef = doc(db, "PUNCHES", punch.id);
          const timeDifference = getTimeDifference(
              punch.InTime.toDate(),
              punch.OutTime.toDate()
          );
          const decimalHours = differenceToDecimal(timeDifference);

          let newStatus;
          if (punch.Status === "Modified") {
            newStatus = "Mod/Closed";
          } else if (punch.Status === "Manual") {
            newStatus = "Man/Closed";
          } else if (punch.Status === "Missing") {
            newStatus = "Mod/Missing";
          } else {
            newStatus = "Closed";
          }

          const punchData = {
            TotalTime: formatTimeDifference(timeDifference),
            Decimal: punch.Holiday ? 0 : decimalHours,
            Holiday: punch.Holiday === undefined ? false : punch.Holiday,
            Status: newStatus,
          };

          batch.update(punchDocRef, punchData);
        }
      });

      const employeeDataDoc = await getDoc(doc(db, "empRegister", employeeId));
      if (!employeeDataDoc.exists) {
        console.error(`Employee data not found for ID: ${employeeId}`);
        continue;
      }
      const employeeData = employeeDataDoc.data();

      // Calculate totals and amounts
      const totalHoursForPayD1 = calculateDecimalHoursForDepartment(
          punchesForEmployee,
          employeeData.PayD1,
          payPeriodStart,
          employeeData
      );
      const totalHoursForPayD2 = calculateDecimalHoursForDepartment(
          punchesForEmployee,
          employeeData.PayD2,
          payPeriodStart,
          employeeData
      );

      const overtimeHoursForPayD1 = calculateOvertimeForDepartment(
          punchesForEmployee,
          employeeData.PayD1,
          payPeriodStart
      );
      const overtimeHoursForPayD2 = calculateOvertimeForDepartment(
          punchesForEmployee,
          employeeData.PayD2,
          payPeriodStart
      );

      const ptoHoursForPayD1 = calculateTotalPTOForDepartment(
          punchesForEmployee,
          employeeData.PayD1
      );
      const ptoHoursForPayD2 = calculateTotalPTOForDepartment(
          punchesForEmployee,
          employeeData.PayD2
      );

      const holidayHoursForPayD1 = calculateTotalHolidayHoursForDepartment(
          punchesForEmployee,
          employeeData.PayD1
      );
      const holidayHoursForPayD2 = calculateTotalHolidayHoursForDepartment(
          punchesForEmployee,
          employeeData.PayD2
      );

      // Calculate dollar amounts
      const regularDollarAmountForPayD1 =
          employeeData.PayD1_Type === "PER HOUR"
              ? totalHoursForPayD1 * employeeData.PayD1_Rate
              : punchesForEmployee.reduce(
                  (total, employee) =>
                      total +
                      (employee.Department === employeeData.PayD1
                          ? employee.Rooms || 0
                          : 0) *
                      employeeData.PayD1_Rate,
                  0
              );

      const regularDollarAmountForPayD2 =
          totalHoursForPayD2 * employeeData.PayD2_Rate;

      const overtimeDollarAmounts = calculateOvertimeDollarAmount(
          punchesForEmployee,
          employeeData,
          payPeriodStart
      );

      const vacationDollarAmounts = calculateVacationDollarAmount(
          punchesForEmployee,
          employeeData
      );

      const holidayDollarAmounts = calculateHolidayDollarAmount(
          punchesForEmployee,
          employeeData,
          payPeriodStart
      );

      const overallTips = Number(
          punchesForEmployee
              .reduce(
                  (total, employee) => total + (Number(employee.Tips) || 0),
                  0
              )
              .toFixed(2));

      const totalAmountForPayD1 = [
        regularDollarAmountForPayD1,
        overtimeDollarAmounts.overtimeDollarAmountForPayD1,
        vacationDollarAmounts.vacationDollarAmountForPayD1,
        holidayDollarAmounts.holidayDollarAmountForPayD1,
      ].reduce((sum, amount) => sum + parseFloat(amount), 0);

      const totalAmountForPayD2 = [
        regularDollarAmountForPayD2,
        overtimeDollarAmounts.overtimeDollarAmountForPayD2,
        vacationDollarAmounts.vacationDollarAmountForPayD2,
        holidayDollarAmounts.holidayDollarAmountForPayD2,
      ].reduce((sum, amount) => sum + parseFloat(amount), 0);

      const overallTotal = totalAmountForPayD1 + totalAmountForPayD2 + overallTips;

      const payRollData = {
        PeriodEndDate: Timestamp.fromDate(payPeriodEnd),
        PropertyCode: selectedPropertyCode,
        EmployeeId: employeeId,
        FirstName: punchesForEmployee[0]?.FirstName,
        LastName: punchesForEmployee[0]?.LastName,
        Status: "Yes",
        EntryDate: new Date(),
        Totals: {
          Time: formatTimeDifference(
              punchesForEmployee.reduce((total, employee) => {
                const inTime =
                    employee.InTime instanceof Date
                        ? employee.InTime
                        : employee.InTime.toDate();
                const outTime =
                    employee.OutTime instanceof Date
                        ? employee.OutTime
                        : employee.OutTime.toDate();
                return total + getTimeDifference(inTime, outTime);
              }, 0)
          ),
          Overtime: Number(
              calculateOvertimeForEmployees(
                  punchesForEmployee,
                  payPeriodStart
              ).toFixed(2)
          ),
          Decimal: Number(
              calculateRegularHoursForEmployees(punchesForEmployee).toFixed(2)
          ),
          PTO: calculateTotalPTOForEmployees(punchesForEmployee),
          Holiday: Number(
              parseFloat(
                  calculateTotalHolidayHoursForEmployees(punchesForEmployee)
              ).toFixed(2)
          ),
          Tips: Number(
              punchesForEmployee
                  .reduce(
                      (total, employee) => total + (Number(employee.Tips) || 0),
                      0
                  )
                  .toFixed(2)
          ),
          Rooms: punchesForEmployee.reduce(
              (total, employee) => total + employee.Rooms,
              0
          ),
          A: punchesForEmployee.reduce(
              (total, employee) => total + employee.A,
              0
          ),
          B: punchesForEmployee.reduce(
              (total, employee) => total + employee.B,
              0
          ),
          C: punchesForEmployee.reduce(
              (total, employee) => total + employee.C,
              0
          ),
          AverageTimePerRoom: totalTimeInMinutes / totalRoomsCleaned,
          Gross: overallTotal.toFixed(2),
        },
        D1: {
          HRS: totalHoursForPayD1.toFixed(2),
          OT: overtimeHoursForPayD1.toFixed(2),
          VAC: ptoHoursForPayD1,
          HOL: holidayHoursForPayD1.toFixed(2),
        },
        D2: {
          HRS: totalHoursForPayD2.toFixed(2),
          OT: overtimeHoursForPayD2.toFixed(2),
          VAC: ptoHoursForPayD2,
          HOL: holidayHoursForPayD2.toFixed(2),
        },
      };

      const newPayRollDocRef = doc(payRollCollectionRef);
      batch.set(newPayRollDocRef, payRollData);
    }

    try {
      await batch.commit();
      console.log("Payroll processing completed successfully.");
    } catch (error) {
      console.error("Error in batch operation: ", error);
    }

    setIsLoading(false);
    setLogMessages((prevMessages) => [...prevMessages, `---------`]);
    setLogMessages((prevMessages) => [...prevMessages, `ALL`]);
    setLogMessages((prevMessages) => [...prevMessages, `TIMECARDS`]);
    setLogMessages((prevMessages) => [...prevMessages, `PROCESSED`]);
  };

  const reOpenClosedPayPeriod = async () => {
    setIsLoading(true);

    const updatedEmployees = selectedEmployeeFiltered.filter((employee) =>
        ["Mod/Closed", "Man/Closed", "Closed", "Mod/Missing"].includes(
            employee.Status
        )
    );

    for (const punch of updatedEmployees) {
      const punchDocRef = doc(db, "PUNCHES", punch.id);

      let newStatus;
      if (punch.Status === "Mod/Closed") {
        newStatus = "Modified";
      } else if (punch.Status === "Man/Closed") {
        newStatus = "Manual";
      } else if (punch.Status === "Closed") {
        newStatus = "Open";
      } else if (punch.Status === "Mod/Missing") {
        newStatus = "Missing";
      } else {
        continue; // Skip if the status is neither "Mod/Closed", "Man/Closed" nor "Closed"
      }

      const punchData = {
        Status: newStatus,
      };

      try {
        await updateDoc(punchDocRef, punchData);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }

    setIsLoading(false);
    closeReOpenPayPeriodDialog();
  };

  //Function converts a JavaScript Date object to a local ISO string format,
  //truncated to include only date and time(up to minutes).
  const toLocalISOString = (date) => {
    const offset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date - offset).toISOString();
    return localISOTime.slice(0, 16);
  };

  const getTimeDifference = (inTime, outTime) => {
    const difference = outTime - inTime;
    return difference;
  };

  //Function formats the time difference (in milliseconds) into a readable format with hours and minutes
  const formatTimeDifference = (difference) => {
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  //Function converts the time difference (in milliseconds) into a decimal representation of hours
  const differenceToDecimal = (difference) => {
    const hours = difference / (1000 * 60 * 60);
    return hours.toFixed(2);
  };

  const getMoodIcon = (mood) => {
    switch (mood) {
      case "Chill":
        return <BsEmojiSunglasses />;
      case "Happy":
        return <RiEmotionHappyLine />;
      case "Neutral":
        return <MdOutlineSentimentNeutral />;
      case "Unhappy":
        return <TbMoodSad2 />;
      case "Angry":
        return <TbMoodAngry />;
      case "Break":
        return <BiCoffee />;
      case "Vac":
        return <FcChargeBattery />;
      case "+":
        return <CiCirclePlus />;
      case "Manual":
        return <MdClose />;
      case "Actual":
        return <MdCheck />;
      case "Missing":
        return <MdClose />;
      default:
        return null;
    }
  };

  const calculateEarningsPerHour = (
      payType,
      payRate,
      employee,
      employeeData
  ) => {
    if (payType !== "PER ROOM" || !employeeData) {
      return 0;
    }

    const aEarnings = (employee.A || 0) * (employeeData.PayD1_Rate || 0);
    const bEarnings = (employee.B || 0) * (employeeData.PR2 || 0);
    const cEarnings = (employee.C || 0) * (employeeData.PR3 || 0);

    // Consider employee.Rooms only if employee.A is not greater than 0
    const roomsEarnings =
        employee.A > 0
            ? 0
            : (employee.Rooms || 0) * (employeeData.PayD1_Rate || 0);

    const totalEarnings = aEarnings + bEarnings + cEarnings + roomsEarnings;

    const inTime =
        employee.InTime instanceof Timestamp
            ? employee.InTime.toDate()
            : employee.InTime;
    const outTime =
        employee.OutTime instanceof Timestamp
            ? employee.OutTime.toDate()
            : employee.OutTime;

    const totalTimeDecimal = differenceToDecimal(
        getTimeDifference(inTime, outTime)
    );

    return totalTimeDecimal > 0 ? totalEarnings / totalTimeDecimal : 0;
  };

  const groupByWeek = (punchesList, payPeriodStart) => {
    const payPeriodStartMoment = moment(payPeriodStart);

    return punchesList.reduce((acc, punch) => {
      const punchDate = punch.InTime.toDate();
      const punchMoment = moment(punchDate);
      const weeksDifference = punchMoment.diff(payPeriodStartMoment, "weeks");
      const weekNumber = 1 + weeksDifference;

      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }
      acc[weekNumber].push(punch);
      return acc;
    }, {});
  };

  // Helper function to convert a value to a Date
  const toDate = (value) => {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    // If value is not a Firebase Timestamp, assume it's a Date
    return value;
  };

  const calculateOvertimeHours = (decimalHoursWorked, holidayHours) => {
    const standardWorkWeekHours = 40;
    decimalHoursWorked =
        typeof decimalHoursWorked === "number" ? decimalHoursWorked : 0;
    holidayHours = typeof holidayHours === "number" ? holidayHours : 0;
    const effectiveWorkHours =
        decimalHoursWorked > 40
            ? decimalHoursWorked - holidayHours
            : decimalHoursWorked;

    const overtimeHours = Math.max(
        0,
        effectiveWorkHours - standardWorkWeekHours
    );

    return overtimeHours;
  };

  const calculateTotalDecimalWithoutPayTypeRestriction = (
      punches,
      payPeriodStart
  ) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce((weekTotal, punch) => {
        if (!punch.InTime || !punch.OutTime) {
          console.error("InTime or OutTime is null or undefined:", punch);
          return weekTotal;
        }
        const inTime = toDate(punch.InTime);
        const outTime = toDate(punch.OutTime);
        const hours = Number(
            differenceToDecimal(getTimeDifference(inTime, outTime))
        );
        return weekTotal + hours;
      }, 0);
      return weeklyTotal + weeklyHours;
    }, 0);
  };

  const calculateOvertimeForEmployees = (employees, payPeriodStart) => {
    const employeeIds = [
      ...new Set(employees.map((employee) => employee.EmployeeId)),
    ];

    return employeeIds.reduce((totalOvertime, employeeId) => {
      const employeePunches = employees.filter(
          (employee) => employee.EmployeeId === employeeId
      );

      // Group the punches by week
      const punchesByWeek = groupByWeek(employeePunches, payPeriodStart);

      // Calculate the total overtime for each week
      const totalOvertimeForEmployee = Object.values(punchesByWeek).reduce(
          (total, weekPunches) => {
            const weeklyTotalDecimal =
                calculateTotalDecimalWithoutPayTypeRestriction(
                    weekPunches,
                    payPeriodStart
                );
            const weeklyHolidayHours =
                calculateTotalHolidayHoursForEmployees(weekPunches);
            const weeklyOvertime = calculateOvertimeHours(
                weeklyTotalDecimal,
                weeklyHolidayHours
            );
            return total + weeklyOvertime;
          },
          0
      );

      return totalOvertime + totalOvertimeForEmployee;
    }, 0);
  };

  const calculateRegularHours = (punches) => {
    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    return Object.values(punchesByWeek).reduce((weeklyTotal, punchesInWeek) => {
      const weeklyHours = punchesInWeek.reduce((weekTotal, punch) => {
        if (punch.PayType === "PER HOUR") {
          const inTime =
              punch.InTime instanceof Date
                  ? punch.InTime
                  : punch.InTime?.toDate();
          const outTime =
              punch.OutTime instanceof Date
                  ? punch.OutTime
                  : punch.OutTime?.toDate();

          if (inTime && outTime) {
            return (
                weekTotal +
                Number(differenceToDecimal(getTimeDifference(inTime, outTime)))
            );
          }
        }
        return weekTotal;
      }, 0);

      // Calculate holiday hours only for employees who are paid by the hour
      const weeklyHolidayHours = punchesInWeek
          .filter((p) => p.PayType === "PER HOUR")
          .reduce((holidayTotal, punch) => {
            return (
                holidayTotal +
                (punch.HolidayHours ? parseFloat(punch.HolidayHours) : 0)
            );
          }, 0);

      const regularHours = weeklyHours - weeklyHolidayHours;

      return weeklyTotal + Math.min(40, Math.max(0, regularHours));
    }, 0);
  };

  const calculateRegularHoursForEmployees = (employees) => {
    const employeeIds = [
      ...new Set(employees.map((employee) => employee.EmployeeId)),
    ];

    return employeeIds.reduce((total, employeeId) => {
      const employeePunches = employees.filter(
          (employee) => employee.EmployeeId === employeeId
      );
      const employeeRegularHours = calculateRegularHours(employeePunches);

      return total + Number(employeeRegularHours);
    }, 0);
  };

  const calculateCombinedTotalHoursForHourlyPay = (
      employeeData,
      selectedEmployeeFiltered,
      payPeriodStart
  ) => {
    // Check if employeeData is not null and has the required properties
    if (!employeeData || !employeeData.PayD1 || !employeeData.PayD2) {
      return 0; // Return 0 hours if data is not available
    }

    let totalHoursForPayD1 = 0;
    let totalHoursForPayD2 = 0;

    // Check if PayD1 is "PER HOUR" and calculate total hours
    if (employeeData && employeeData.PayD1_Type === "PER HOUR") {
      totalHoursForPayD1 = calculateDecimalHoursForDepartment(
          selectedEmployeeFiltered,
          employeeData.PayD1,
          payPeriodStart,
          employeeData
      );
    }

    // PayD2 is assumed to always be "PER HOUR" based on the context provided
    totalHoursForPayD2 = calculateDecimalHoursForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD2,
        payPeriodStart,
        employeeData
    );

    // Combine the total hours for PayD1 and PayD2
    return totalHoursForPayD1 + totalHoursForPayD2;
  };

  //Function calculates the total PTO (Paid Time Off) for all employees by reducing the PTO values of each employee.
  const calculateTotalPTOForEmployees = (employees) => {
    return employees.reduce(
        (total, employee) =>
            total + (employee.PTO ? parseFloat(employee.PTO) : 0),
        0
    );
  };

  //Function calculates the total holiday hours for all employees by reducing the holiday hours of each employee.
  function calculateTotalHolidayHoursForEmployees(employees) {
    return employees.reduce(
        (total, employee) =>
            total + (employee.HolidayHours ? parseFloat(employee.HolidayHours) : 0),
        0
    );
  }

  //Function changes the pay period based on the given direction ("left" or "right")
  //and updates the pay period start and end dates accordingly.
  const changePayPeriod = (direction) => {
    let newPayPeriodStart = new Date(payPeriodStart);
    let newPayPeriodEnd = new Date(payPeriodEnd);

    switch (payCycle) {
      case "Monthly":
        if (direction === "left") {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() - 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() - 1);
        } else {
          newPayPeriodStart.setMonth(newPayPeriodStart.getMonth() + 1);
          newPayPeriodEnd.setMonth(newPayPeriodEnd.getMonth() + 1);
        }
        break;
      case "Bi-Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 14);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 14);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 14);
        }
        break;
      case "Weekly":
        if (direction === "left") {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() - 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() - 7);
        } else {
          newPayPeriodStart.setDate(newPayPeriodStart.getDate() + 7);
          newPayPeriodEnd.setDate(newPayPeriodEnd.getDate() + 7);
        }
        break;
      default:
        break;
    }

    setPayPeriodStart(newPayPeriodStart);
    setPayPeriodEnd(newPayPeriodEnd);
  };

  //Function is called when the "Save" button is clicked after modifying an employee's record.
  //It updates the existing punch document in the database with the modified data, and then sets the row to non - editable.
  const handleSave = useCallback(
      async (index) => {
        const updatedEmployees = [...employees];
        const punch = updatedEmployees[index];
        const punchDocRef = doc(db, "PUNCHES", punch.id);

        const newRoomsTotal = dialogState.A + dialogState.B + dialogState.C;

        const breakEnabled = dialogState.BreakEnabled || false;

        let punchOutTime;
        let inTime = dialogState.InTime
            ? dialogState.InTime instanceof Date
                ? dialogState.InTime
                : new Date(dialogState.InTime.toDate())
            : new Date(punch.InTime.toDate());

        if (breakEnabled) {
          punchOutTime = dialogState.BreakOut
              ? Timestamp.fromDate(dialogState.BreakOut)
              : punch.OutTime;
        } else {
          punchOutTime = dialogState.OutTime
              ? dialogState.OutTime instanceof Date
                  ? dialogState.OutTime
                  : new Date(dialogState.OutTime.toDate())
              : new Date(punch.OutTime.toDate());
        }

        // Calculate the difference in hours
        let hoursDifference = Math.abs(punchOutTime - inTime) / 36e5;

        // Determine the new status
        let newStatus;
        if (punch.Status === "Missing") {
          newStatus = "Mod/Missing";
        } else {
          newStatus = "Modified";
        }

        const punchData = {
          Department: dialogState.Department,
          InTime: Timestamp.fromDate(inTime),
          MoodIn: punch.MoodIn,
          MoodOut: breakEnabled ? "Break" : punch.MoodOut,
          OutTime: punchOutTime,
          PayRate: dialogState.PayRate,
          PayType: dialogState.PayType,
          Status: newStatus,
          Tips: punch.Tips,
          A: dialogState.A,
          B: dialogState.B,
          C: dialogState.C,
          Rooms: newRoomsTotal,
          Holiday: dialogState.Holiday || false,
          HolidayHours: dialogState.Holiday ? hoursDifference : 0,
        };

        try {
          // Check if the original Department was "FIX"
          if (punch.Department === "FIX") {
            // Get a document from the 'missedPunches' collection that has the same name as the punch being modified
            const missedPunchDocRef = doc(db, "missedPunches", punch.id);
            const missedPunchSnap = await getDoc(missedPunchDocRef);

            // If the document exists in 'missedPunches' collection, delete it
            if (missedPunchSnap.exists()) {
              await deleteDoc(missedPunchDocRef);
            }
          }

          // Check if the original Department was "VERIFY"
          if (punch.Department === "VERIFY") {
            // Get a document from the 'missedPunches' collection that has the same name as the punch being modified
            const missedPunchDocRef = doc(db, "missedPunches", punch.id);
            const missedPunchSnap = await getDoc(missedPunchDocRef);

            // If the document exists in 'missedPunches' collection, delete it
            if (missedPunchSnap.exists()) {
              await deleteDoc(missedPunchDocRef);
            }
          }

          // Optimistically update the local state first
          updatedEmployees[index] = {
            ...updatedEmployees[index],
            ...punchData,
            isEditable: false,
          };
          setEmployees(updatedEmployees);

          // Then attempt to update the Firebase document
          await updateDoc(punchDocRef, punchData);

          setDialogState({ A: 0, B: 0, C: 0 });

          if (breakEnabled) {
            const newInTime = new Date(
                dialogState.BreakOut.getTime() + dialogState.BreakDuration * 60000
            ); // Assuming break duration is in minutes

            const newPunchData = {
              ...punchData,
              InTime: Timestamp.fromDate(newInTime),
              OutTime: dialogState.OutTime
                  ? Timestamp.fromDate(dialogState.OutTime)
                  : punch.OutTime,
              EmployeeId: punch.EmployeeId,
              FirstName: punch.FirstName,
              LastName: punch.LastName,
              PTO: 0,
              PropCode: punch.PropCode,
              MoodIn: "Break",
              MoodOut: punch.MoodOut,
            };

            // Inserting the new punch data to the PUNCHES collection
            await addDoc(collection(db, "PUNCHES"), newPunchData);
          }

          localStorage.setItem(
              "selectedEmployee",
              JSON.stringify(updatedEmployees[index])
          );
        } catch (error) {
          console.error("Error updating document: ", error);
          // Handle error accordingly
        }
      },
      [employees, dialogState]
  );

  const handleDelete = async (index) => {
    const updatedEmployees = [...employees];
    const punch = updatedEmployees[index];
    const punchDocRef = doc(db, "PUNCHES", punch.id);

    try {
      await deleteDoc(punchDocRef);
      updatedEmployees.splice(index, 1);
      setEmployees(updatedEmployees);
      closeEditModal();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  //Function is called when an input value changes in the editable row.
  // It updates the employee data in the state with the new values.
  const handleInputChange = useCallback(
      (event, index) => {
        const { name, value } = event.target;
        const updatedEmployees = [...employees];

        if (name === "InTime" || name === "OutTime") {
          if (isValidDate(value)) {
            const dateValue = new Date(value);
            updatedEmployees[index][name] = Timestamp.fromDate(dateValue);
          }
        } else if (name === "A" || name === "B" || name === "C") {
          updatedEmployees[index][name] = parseInt(value, 10) || 0;
        } else {
          updatedEmployees[index][name] = value;
        }
        setEmployees(updatedEmployees);
      },
      [employees]
  );

  const formatDate = (date) => {
    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const day = dayNames[date.getDay()];
    const dayOfMonth = date.getDate();
    return `${dayOfMonth} ${day}`;
  };

  const formatDateTime = (date) => {
    const month = date.getMonth() + 1;
    const dayOfMonth = date.getDate();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    // return `${month}/${dayOfMonth} ${hours}:${minutes}`;
    return `${hours}:${minutes}`;
  };

  //Function checks if the pay period can be closed by verifying that an employee is selected,
  // the pay period is not already closed, and all employees' statuses are either Closed, Mod/Closed, or Man/Closed.
  const canClosePayPeriod = () => {
    return (
        closedPayPeriods[selectedEmployeeId] ||
        isAnyPunchNeedsFix(selectedEmployeeId) ||
        areAllPunchesApproved(selectedEmployeeId)
    );
  };

  const canClosePayPeriod2 = () => {
    return (
        closedPayPeriods[selectedEmployeeId] ||
        areAllPunchesApproved(selectedEmployeeId)
    );
  };

  const hasPayPeriodEnded = () => {
    // Assuming payPeriodEnd is a Date object
    const currentDate = new Date();
    return currentDate > payPeriodEnd;
  };

  const canClosePayPeriodBasedOnDate = () => {
    // This function returns true if the pay period has not yet ended
    return !hasPayPeriodEnded();
  };

  //Variable is an object that groups the employees by week number, based on the payPeriodStart value.
  const groupedEmployeesByWeeks = groupByWeek(employees, payPeriodStart);

  //Variable filters the employees to only include the employee with the selected ID.
  const selectedEmployeeFiltered = employees.filter(
      (employee) => employee.EmployeeId === selectedEmployeeId
  );

  const initialStateNewPunch = {
    InTime: "",
    OutTime: "",
    Department: "",
    Holiday: false,
    Rooms: 0,
    Tips: 0,
    PTO: 0,
    A: 0,
    B: 0,
    C: 0,
  };

  const [newPunch, setNewPunch] = useState(initialStateNewPunch);

  //Function is called when an input value changes in the "Add Punch" modal.
  //It updates the newPunch state object with the new values.
  const handleNewPunchInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "InTime" || name === "OutTime") {
      if (isValidDate(value)) {
        setNewPunch((prevState) => ({ ...prevState, [name]: value }));
      }
    } else {
      setNewPunch((prevPunch) => ({
        ...prevPunch,
        [name]:
            name === "Rooms"
                ? parseInt(value)
                : name === "Tips"
                    ? parseFloat(value)
                    : value,
      }));
    }
  };

  //Function is called when the "Holiday" checkbox is checked or unchecked in the "Add Punch" modal.
  //It updates the newPunch state object with the new value.
  const handleCheckboxChange = (e) => {
    setNewPunch({
      ...newPunch,
      Holiday: e.target.checked,
    });
  };

  //Function takes an employee ID and returns the full name of the employee.
  const getEmployeeName = (employeeId) => {
    const employee = allEmployees.find((e) => e.EmployeeId === employeeId);
    return employee ? `${employee.FirstName} ${employee.LastName}` : "";
  };

  //Function is called when the "Add Punch" button is clicked in the "Add Punch" modal.
  //It creates a new punch document in the database with the data from the newPunch state object.
  const handleAddPunch = async () => {
    const selectedEmployee = allEmployees.find(
        (employee) => employee.EmployeeId === selectedEmployeeId
    );

    if (!selectedEmployee) {
      console.error("Selected employee not found");
      return;
    }

    const totalRooms =
        parseInt(newPunch.A, 10) +
        parseInt(newPunch.B, 10) +
        parseInt(newPunch.C, 10);

    const PTO = parseFloat(newPunch.PTO);

    let newPunchData = {
      ...newPunch,
      InTime: Timestamp.fromDate(new Date(newPunch.InTime)),
      OutTime: Timestamp.fromDate(new Date(newPunch.OutTime)),
      EmployeeId: selectedEmployeeId,
      FirstName: selectedEmployee.FirstName,
      LastName: selectedEmployee.LastName,
      PropCode: selectedPropertyCode,
      MoodIn: PTO > 0 ? "Vac" : "+",
      MoodOut: PTO > 0 ? "Vac" : "+",
      Department: newPunch.Department,
      PayRate: parseFloat(newPunch.PayRate),
      PayType: newPunch.PayType,
      Status: "Modified",
      PTO: PTO,
      A: parseInt(newPunch.A, 10),
      B: parseInt(newPunch.B, 10),
      C: parseInt(newPunch.C, 10),
      Rooms: totalRooms,
    };

    // If noShowChecked is true, modify the punch data accordingly
    if (noShowChecked) {
      newPunchData = {
        ...newPunchData,
        MoodIn: "Missing",
        MoodOut: "Missing",
        Department: "NO-SHOW",
        PayType: "error",
        OutTime: newPunchData.InTime, // Set OutTime to the same value as InTime
      };
    }

    try {
      await addDoc(collection(db, "PUNCHES"), newPunchData);
      setOpenAddPunchModal(false);
      setIsAddPunchDialogOpen(false);
      setNewPunch(initialStateNewPunch);
    } catch (error) {
      console.error("Error adding new punch: ", error);
    }
  };

  //This useEffect hook fetches employee data from the "empRegister" collection in the database based on the selectedEmployeeId
  //and updates the corresponding state variables with the fetched data.
  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (selectedEmployeeId) {
        const docRef = doc(db, "empRegister", selectedEmployeeId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setEmployeeData({
            ...docSnap.data(),
            PR2: docSnap.data().PR2,
            PR3: docSnap.data().PR3,
            PayD1_Rate: docSnap.data().PayD1_Rate,
          });
          setDepartments([docSnap.data().PayD1, docSnap.data().PayD2]);
        } else {
          setEmployeeData(null);
          setDepartments([]);
        }
      }
    };

    fetchEmployeeData();
  }, [selectedEmployeeId]);

  const handleDepartmentChange = (event) => {
    const department = event.target.value;

    if (employeeData) {
      let payRate, payType;

      if (department === employeeData.PayD1) {
        payRate = employeeData.PayD1_Rate;
        payType = employeeData.PayD1_Type;
      } else if (department === employeeData.PayD2) {
        payRate = employeeData.PayD2_Rate;
        payType = employeeData.PayD2_Type;
      }

      setNewPunch((prevPunch) => ({
        ...prevPunch,
        Department: department,
        PayRate: payRate,
        PayType: payType,
      }));
    }
  };

  const getSelectedDepartmentPayType = () => {
    if (!isAddPunchDialogOpen || !employeeData || !newPunch.Department) {
      return "";
    }

    if (newPunch.Department === employeeData.PayD1) {
      return employeeData.PayD1_Type;
    } else if (newPunch.Department === employeeData.PayD2) {
      return employeeData.PayD2_Type;
    }

    return "";
  };

  //Function checks if the form in the "Add Punch" modal is valid and returns a boolean value.
  //It checks for conditions like employee selection, pay period closure, empty department, and any conflicting input values.
  const validateForm = () => {
    if (
        !selectedEmployeeId || // Check if an employee is selected
        isPayPeriodClosed || // Check if the pay period is already closed
        employees.every(
            (employee) =>
                employee.Status === "Closed" ||
                employee.Status === "Mod/Closed" ||
                employee.Status === "Man/Closed" ||
                employee.Status === "Mod/Missing"
        )
    ) {
      return false;
    }

    if (
        !newPunch.Department ||
        !isValidDate(newPunch.InTime) ||
        !isValidDate(newPunch.OutTime)
    ) {
      return false;
    }

    if (newPunch.PayType === "PER HOUR" && newPunch.PTO === 0) {
      if (
          newPunch.Rooms !== 0 ||
          (!newPunch.InTime && !newPunch.OutTime) ||
          (newPunch.InTime &&
              newPunch.OutTime &&
              new Date(newPunch.OutTime) <= new Date(newPunch.InTime))
      ) {
        return false;
      }
    }

    if (
        newPunch.Holiday &&
        (!isValidDate(newPunch.InTime) || !isValidDate(newPunch.OutTime))
    ) {
      return false;
    }

    if (newPunch.PTO > 0) {
      if (
          newPunch.InTime !== newPunch.OutTime ||
          newPunch.Rooms !== 0 ||
          newPunch.Tips !== 0
      ) {
        return false;
      }
    }

    return true;
  };

// Handle input change for both PTO and Department
  const handleVacationInputChange = (e) => {
    const { name, value } = e.target;

    setVacationData((prev) => ({
      ...prev,
      [name]: name === "Hours" || name === "Minutes" ? parseFloat(value) || 0 : value, // Only parse Hours and Minutes as numbers
    }));
  };

  // Validate form to ensure PTO and Department are selected
  const validateVacationForm = () => {
    const { Department, Hours, Minutes } = vacationData;

    // Ensure department is selected, hours and minutes are valid, and an employee is selected
    if (!Department || (!Hours && !Minutes)) {
      return false;
    }

    const selectedEmployee = allEmployees.find(
        (employee) => employee.EmployeeId === selectedEmployeeId
    );

    if (!selectedEmployee) {
      return false; // Return false if no employee is selected
    }

    return true; // If all conditions are met, form is valid
  };

  const handleSubmit = () => {
    if (validateVacationForm()) {
      const selectedDate = new Date(vacationData.Date); // Get the selected date

      const selectedEmployee = allEmployees.find(
          (employee) => employee.EmployeeId === selectedEmployeeId
      );

      if (!selectedEmployee) {
        console.error("Selected employee not found");
        return;
      }

      // Convert Hours and Minutes to decimal PTO value
      const hours = vacationData.Hours || 0;
      const minutes = vacationData.Minutes || 0;
      const PTO = (parseFloat(hours) + parseFloat(minutes) / 60).toFixed(2);

      // Set InTime and OutTime to the selected date and time
      const InTime = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          9,
          0,
          0
      );

      const OutTime = InTime;

      // Create the new punch data, including PTO and default fields
      let newVacationData = {
        InTime: Timestamp.fromDate(InTime),
        OutTime: Timestamp.fromDate(OutTime),
        EmployeeId: selectedEmployeeId,
        FirstName: selectedEmployee.FirstName,
        LastName: selectedEmployee.LastName,
        PropCode: selectedPropertyCode,
        MoodIn: "Vac",
        MoodOut: "Vac",
        Department: vacationData.Department,
        Status: "Open",
        PTO: parseFloat(PTO),
        A: 0,
        B: 0,
        C: 0,
        Rooms: 0,
      };

      handleVacationSubmit(newVacationData);
      setVacationData(initialVacationState);
      closeVacationModalFn();
    }
  };

  // Define state for the modal visibility
  const [openVacationModal, setVacationModalOpen] = useState(false);

// Function to close the Vacation Modal
  const closeVacationModalFn = () => {
    setVacationModalOpen(false);
  };

// Initial state for vacation data
  const initialVacationState = {
    Department: "",
    Hours: 0,
    Minutes: 0,
    Date: "",
    Time: "09:00",
  };

// Handle the submission of vacation data
  const handleVacationSubmit = async (newVacationData) => {
    try {
      await addDoc(collection(db, "PUNCHES"), newVacationData); // Save the vacation punch
      setVacationModalOpen(false); // Close the modal after submission
    } catch (error) {
      console.error("Error adding vacation punch: ", error);
    }
  };

  const [vacationData, setVacationData] = useState(initialVacationState);

// Assume you have a way to get the selected employee's name
  const selectedEmployeeName = selectedEmployeeId ? getEmployeeName(selectedEmployeeId) : "Select Employee";


  //Function sorts a list of employees by their "InTime" property in ascending order.
  const sortEmployeesByInTime = (employeesList) => {
    return employeesList.sort(
        (a, b) => a.InTime.toDate().getTime() - b.InTime.toDate().getTime()
    );
  };

  // Function renders the "Week Total" row for the selected employee with their total
  //regular hours, overtime, and earnings for the specified week.
  const renderWeekTotal = (weekNumber, selectedEmployeeFilteredInWeek) => {
    // Filter punches for the current week
    const punchesForCurrentWeek = selectedEmployeeFilteredInWeek.filter(
        (punch) => {
          const punchDate = punch.InTime.toDate();
          const punchMoment = moment(punchDate);
          const weeksDifference = punchMoment.diff(
              moment(payPeriodStart),
              "weeks"
          );
          const currentWeekNumber = 1 + weeksDifference;
          return currentWeekNumber === parseInt(weekNumber);
        }
    );

    const sumA = punchesForCurrentWeek.reduce(
        (total, employee) => total + employee.A,
        0
    );
    const sumB = punchesForCurrentWeek.reduce(
        (total, employee) => total + employee.B,
        0
    );
    const sumC = punchesForCurrentWeek.reduce(
        (total, employee) => total + employee.C,
        0
    );

    return (
        <TableFooter sx={{ width: "100%" }}>
          <TableRow>
            <TableCell
                sx={{
                  letterSpacing: "0.3rem",
                  backgroundColor: "#ebefe3",
                  color: "#020202",
                  fontWeight: 200,
                  fontSize: "0.9rem",
                }}
                align="left"
                size="small"
            >{`WK:${weekNumber}`}</TableCell>
            <TableCell
                align="center"
                size="small"
                sx={{
                  letterSpacing: "0.1rem",
                  fontWeight: 600,
                  fontSize: "0.7rem",
                  backgroundColor: "#ebefe3",
                  color: "020202",
                }}
            >
              TIME
            </TableCell>
            <TableCell
                align="center"
                size="small"
                sx={{
                  letterSpacing: "0.1rem",
                  fontWeight: 600,
                  fontSize: "0.7rem",
                  backgroundColor: "#ebefe3",
                  color: "020202",
                }}
            >
              VAC
            </TableCell>
            <TableCell
                align="center"
                size="small"
                sx={{
                  letterSpacing: "0.1rem",
                  fontWeight: 600,
                  fontSize: "0.7rem",
                  backgroundColor: "#ebefe3",
                  color: "020202",
                }}
            >
              HOL
            </TableCell>
            <TableCell
                align="center"
                size="small"
                sx={{
                  letterSpacing: "0.1rem",
                  fontWeight: 600,
                  fontSize: "0.7rem",
                  backgroundColor: "#ebefe3",
                  color: "020202",
                }}
            >
              TIPS
            </TableCell>

            <TableCell
                align="center"
                size="small"
                sx={{
                  letterSpacing: "0.1rem",
                  fontWeight: 600,
                  fontSize: "0.7rem",
                  backgroundColor: "#ebefe3",
                  color: "020202",
                }}
            >
              RMS
            </TableCell>
            {sumA !== 0 && (
                <TableCell
                    align="center"
                    size="small"
                    sx={{
                      letterSpacing: "0.1rem",
                      fontWeight: 600,
                      fontSize: "0.7rem",
                      backgroundColor: "#ebefe3",
                      color: "020202",
                    }}
                >
                  @ ${employeeData ? employeeData.PayD1_Rate : "..."}
                </TableCell>
            )}
            {sumB !== 0 && (
                <TableCell
                    align="center"
                    size="small"
                    sx={{
                      letterSpacing: "0.1rem",
                      fontWeight: 600,
                      fontSize: "0.7rem",
                      backgroundColor: "#ebefe3",
                      color: "020202",
                    }}
                >
                  @ ${employeeData ? employeeData.PR2 : "..."}
                </TableCell>
            )}
            {sumC !== 0 && (
                <TableCell
                    align="center"
                    size="small"
                    sx={{
                      letterSpacing: "0.1rem",
                      fontWeight: 600,
                      fontSize: "0.7rem",
                      backgroundColor: "#ebefe3",
                      color: "020202",
                    }}
                >
                  @ ${employeeData ? employeeData.PR3 : "..."}
                </TableCell>
            )}
          </TableRow>
          <TableRow>
            {/* SPACE */}
            <TableCell
                align="center"
                size="small"
                sx={{
                  fontWeight: 400,
                  fontSize: "0.7rem",
                  backgroundColor: "#f9f9f9",
                }}
            ></TableCell>
            {/* TIME */}
            <TableCell
                align="center"
                size="small"
                sx={{
                  ...weeklytotals,
                  fontWeight: 600,
                  fontSize: "0.8rem",
                  maxWidth: "3.6rem",
                  backgroundColor:
                      punchesForCurrentWeek.reduce((total, employee) => {
                        const inTime =
                            employee.InTime instanceof Date
                                ? employee.InTime
                                : employee.InTime.toDate();
                        const outTime =
                            employee.OutTime instanceof Date
                                ? employee.OutTime
                                : employee.OutTime.toDate();
                        return total + getTimeDifference(inTime, outTime);
                      }, 0) >
                      40 * 60 * 60 * 1000 // Convert 40 hours to milliseconds
                          ? "#F98B85"
                          : "inherit",
                  color:
                      punchesForCurrentWeek.reduce((total, employee) => {
                        const inTime =
                            employee.InTime instanceof Date
                                ? employee.InTime
                                : employee.InTime.toDate();
                        const outTime =
                            employee.OutTime instanceof Date
                                ? employee.OutTime
                                : employee.OutTime.toDate();
                        return total + getTimeDifference(inTime, outTime);
                      }, 0) >
                      40 * 60 * 60 * 1000 // Convert 40 hours to milliseconds
                          ? "#f9f9f9"
                          : "inherit",
                }}
            >
              {formatTimeDifference(
                  punchesForCurrentWeek.reduce((total, employee) => {
                    const inTime =
                        employee.InTime instanceof Date
                            ? employee.InTime
                            : employee.InTime.toDate();
                    const outTime =
                        employee.OutTime instanceof Date
                            ? employee.OutTime
                            : employee.OutTime.toDate();
                    return total + getTimeDifference(inTime, outTime);
                  }, 0)
              )}
            </TableCell>
            {/* VACATION */}
            <TableCell align="center" size="small" sx={weeklytotals}>
              {calculateTotalPTOForEmployees(punchesForCurrentWeek)}
            </TableCell>
            {/* HOLIDAY */}
            <TableCell align="center" size="small" sx={weeklytotals}>
              {parseFloat(
                  calculateTotalHolidayHoursForEmployees(punchesForCurrentWeek)
              ).toFixed(2)}
            </TableCell>
            {/* TIPS */}
            <TableCell align="center" size="small" sx={weeklytotals}>
              {punchesForCurrentWeek
                  .reduce(
                      (total, employee) => total + (Number(employee.Tips) || 0),
                      0
                  )
                  .toFixed(2)}
            </TableCell>
            {/* SPACE */}

            {/* RMS */}
            <TableCell align="center" size="small" sx={weeklytotals}>
              {punchesForCurrentWeek.reduce(
                  (total, employee) => total + employee.Rooms,
                  0
              )}
            </TableCell>
            {sumA !== 0 && (
                <TableCell
                    align="center"
                    size="small"
                    sx={{
                      fontWeight: 400,
                      fontSize: "0.8rem",
                      minWidth: "2.7rem",
                      backgroundColor: "#f9f9f9",
                    }}
                >
                  {sumA}
                </TableCell>
            )}
            {sumB !== 0 && (
                <TableCell
                    align="center"
                    size="small"
                    sx={{
                      fontWeight: 400,
                      fontSize: "0.8rem",
                      minWidth: "2.7rem",
                      backgroundColor: "#f9f9f9",
                    }}
                >
                  {sumB}
                </TableCell>
            )}
            {sumC !== 0 && (
                <TableCell
                    align="center"
                    size="small"
                    sx={{
                      fontWeight: 400,
                      fontSize: "0.8rem",
                      minWidth: "2.7rem",
                      backgroundColor: "#f9f9f9",
                    }}
                >
                  {sumC}
                </TableCell>
            )}
          </TableRow>
        </TableFooter>
    );
  };

  const calculateTimePerRoom = (totalTimeDecimal, roomsCleaned) => {
    if (!roomsCleaned || totalTimeDecimal === 0) {
      return 0;
    }
    return totalTimeDecimal / roomsCleaned;
  };

  const totalTimeInMinutes = selectedEmployeeFiltered.reduce(
      (total, employee) => {
        if (employee.PayType === "PER ROOM") {
          const inTime =
              employee.InTime instanceof Timestamp
                  ? employee.InTime.toDate()
                  : employee.InTime;
          const outTime =
              employee.OutTime instanceof Timestamp
                  ? employee.OutTime.toDate()
                  : employee.OutTime;
          const timeDifference = getTimeDifference(inTime, outTime);
          const totalTimeDecimal = parseFloat(
              differenceToDecimal(timeDifference)
          );
          return total + totalTimeDecimal * 60;
        } else {
          return total;
        }
      },
      0
  );

  const totalRoomsCleaned = selectedEmployeeFiltered.reduce(
      (total, employee) => {
        return total + (employee.A || 0) + (employee.B || 0) + (employee.C || 0);
      },
      0
  );

  const averageMinutesPerRoom =
      totalRoomsCleaned > 0 ? totalTimeInMinutes / totalRoomsCleaned : 0;

  const isValidDate = (dateString) => {
    const timestamp = Date.parse(dateString);
    if (isNaN(timestamp)) return false;

    const currentDate = new Date();
    const pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - 180);

    const inputDate = new Date(timestamp);
    return inputDate >= pastDate && inputDate <= currentDate;
  };

  const resetUserInput = () => {
    setUserInput("");
  };

  const areAllPunchesApproved = useCallback(
      (employeeId) => {
        const employeePunches = employees.filter(
            (employee) => employee.EmployeeId === employeeId
        );

        return employeePunches.some((punch) => punch.Approved === "yes");
      },
      [employees]
  );

  const isAnyPunchNeedsFix = useCallback(
      (employeeId) => {
        // Define the list of restricted departments
        const restrictedDepartments = ["FIX", "N/A", "NO-SHOW", "0", "VERIFY"]; // Add other departments as needed

        const employeePunches = employees.filter(
            (employee) => employee.EmployeeId === employeeId
        );

        // Check if any punch is in a restricted department
        return employeePunches.some((punch) =>
            restrictedDepartments.includes(punch.Department)
        );
      },
      [employees]
  );

  const isPayPeriodClosedForAll = () => {
    // Check if all employee's pay periods are closed
    return employees.every((employee) => closedPayPeriods[employee.EmployeeId]);
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // We do this to compare dates only, not times
  const isPayPeriodEndGreaterThanCurrent = new Date(payPeriodEnd) > currentDate;
  const payPeriodStartISO = payPeriodStart
      ? payPeriodStart.toISOString().slice(0, -8)
      : "";
  const payPeriodEndPlusOneISO = payPeriodEnd
      ? new Date(payPeriodEnd.getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, -8)
      : "";

  const getEndOfDay = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999); // Set to the last millisecond of the day
    return endOfDay.toISOString().slice(0, -8);
  };

  // Modify - Break date restrictions InTime as the minimum datetime value
  const getMinDateTime = () => {
    if (dialogState.InTime) {
      return toLocalISOString(dialogState.InTime);
    } else if (
        employees[selectedEmployee] &&
        employees[selectedEmployee].InTime
    ) {
      return toLocalISOString(employees[selectedEmployee].InTime.toDate());
    }
    return "";
  };
  // Modify - Break date restrictions InTime as the minimum datetime value
  const getMaxDateTime = () => {
    if (dialogState.InTime) {
      return getEndOfDay(dialogState.InTime);
    } else if (
        employees[selectedEmployee] &&
        employees[selectedEmployee].InTime
    ) {
      return getEndOfDay(employees[selectedEmployee].InTime.toDate());
    }
    return "";
  };

  const minDateTime = getMinDateTime();
  const maxDateTime = getMaxDateTime();

  const calculateDecimalHoursForDepartment = (
      punches,
      department,
      payPeriodStart,
      employeeData
  ) => {
    if (!punches || !department || !employeeData) {
      console.error("Punches, department, or employeeData is null or undefined.");
      return 0;
    }

    // If PayD1_Type is "PER ROOM", return 0 because decimal hours are not applicable
    if (employeeData.PayD1_Type === "PER ROOM") {

      return 0;
    }

    // Proceed with current logic if not "PER ROOM"
    const punchesByWeek = groupByWeek(punches, payPeriodStart);
    const totalHours = Object.values(punchesByWeek).reduce((total, weekPunches) => {
      // Filter punches for the specific department in this week
      const departmentWeekPunches = weekPunches.filter(
          (punch) => punch.Department === department
      );

      // Calculate total hours worked for the department in the week
      const weeklyDepartmentHours = departmentWeekPunches.reduce((deptTotal, punch) => {
        const inTime = toDate(punch.InTime);
        const outTime = toDate(punch.OutTime);
        if (inTime && outTime) {
          const hoursWorked = Number(differenceToDecimal(getTimeDifference(inTime, outTime)));
          return deptTotal + hoursWorked;
        }
        return deptTotal;
      }, 0);

      // Adjust total hours for holidays and PTO
      const weeklyHolidayHours = calculateTotalHolidayHoursForDepartment(
          weekPunches,
          department
      );

      // Calculate overtime hours for the week using all punches
      const weeklyOvertimeHours = calculateOvertimeForDepartment(
          weekPunches,
          department,
          payPeriodStart,
          employeeData
      );

      // Calculate regular hours for the department
      const adjustedWeeklyHours = Math.max(
          0,
          weeklyDepartmentHours - weeklyOvertimeHours - weeklyHolidayHours
      );

      return total + adjustedWeeklyHours;
    }, 0);

    return totalHours;
  };


  const calculateOvertimeForDepartment = (
      punches,
      department,
      payPeriodStart,
      employeeData,
      overtimeThreshold = 40
  ) => {
    if (!punches || !department || !employeeData) {
      console.error("Punches, department, or employeeData is null or undefined.");
      return 0;
    }

    let departmentOvertime = 0; // Track overtime for the single department
    const punchesByWeek = groupByWeek(punches, payPeriodStart); // Group punches by week

    // Iterate through each week of punches
    Object.values(punchesByWeek).forEach((weekPunches) => {
      let totalWorkedHours = 0; // Reset total hours for the week
      let weeklyOvertimeThreshold = overtimeThreshold; // Reset the weekly threshold
      let weeklyOvertime = 0; // Track weekly overtime
      let holidayHoursInWeek = 0; // Track total holiday hours in the week

      // First pass: Calculate total holiday hours in this week
      weekPunches.forEach((punch) => {
        if (punch.Holiday && punch.Department === department) {
          holidayHoursInWeek += punch.HolidayHours || 0;
        }
      });

      // Second pass: Calculate overtime and adjust based on holiday hours
      weekPunches.forEach((punch) => {
        const inTime = toDate(punch.InTime);
        const outTime = toDate(punch.OutTime);

        if (inTime && outTime && punch.Department === department) {
          const hoursWorked = Number(differenceToDecimal(getTimeDifference(inTime, outTime)));

          // Accumulate total worked hours across all punches in the week
          totalWorkedHours += hoursWorked;

          // If total worked hours exceed the weekly overtime threshold, calculate punch-level overtime
          if (totalWorkedHours > weeklyOvertimeThreshold) {
            let overtimeForPunch = totalWorkedHours - weeklyOvertimeThreshold;

            // Overtime cannot exceed hours worked in the current punch
            overtimeForPunch = Math.min(overtimeForPunch, hoursWorked);

            // Adjust overtime by the holiday hours in the week, if any
            if (holidayHoursInWeek > 0) {
              const adjustedOvertime = Math.max(0, overtimeForPunch - holidayHoursInWeek);
              holidayHoursInWeek -= overtimeForPunch; // Reduce the holiday hours by the overtime
              overtimeForPunch = adjustedOvertime; // Apply the adjusted overtime
            }

            // Track overtime
            departmentOvertime += overtimeForPunch;
            weeklyOvertime += overtimeForPunch; // Track weekly overtime

            // Track punch-level overtime
            punch.OvertimeHours = overtimeForPunch;

            // Adjust weekly overtime threshold to prevent double-counting in future punches
            weeklyOvertimeThreshold = totalWorkedHours;
          }
        }
      });
    });

    return departmentOvertime; // Return the total overtime hours for the department
  };


  const calculateOvertimePayPerRoom = (punches, employeeData, payPeriodStart) => {
    let overtimePay = 0;
    let totalWorkedHours = 0;  // Track the total worked hours across the week

    const punchesByWeek = groupByWeek(punches, payPeriodStart);

    // Iterate over each week
    Object.values(punchesByWeek).forEach((weekPunches) => {
      totalWorkedHours = 0;  // Reset total hours for each week

      weekPunches.forEach((punch) => {
        const totalHours = Number(differenceToDecimal(getTimeDifference(toDate(punch.InTime), toDate(punch.OutTime))));
        const roomsCleaned = punch.Rooms || 0;  // Ensure rooms cleaned are available

        // Calculate the total hours worked up to this point in the week
        totalWorkedHours += totalHours;

        if (totalWorkedHours > 40 && roomsCleaned > 0) {
          // Calculate how much of the current punch is overtime
          let overtimeHoursForPunch = totalWorkedHours - 40;

          // Overtime cannot exceed hours worked in the current punch
          overtimeHoursForPunch = Math.min(overtimeHoursForPunch, totalHours);

          // Calculate the number of rooms cleaned per hour during the punch
          const roomsPerHour = roomsCleaned / totalHours;

          // Calculate how many rooms were cleaned during the overtime hours
          const overtimeRoomsCleaned = roomsPerHour * overtimeHoursForPunch;

          // Calculate overtime pay for rooms cleaned during the overtime portion of the punch
          const dailyOvertimeRate = employeeData.PayD1_Rate * 1.5; // Overtime is 1.5x the rate
          const overtimePayForPunch = overtimeRoomsCleaned * dailyOvertimeRate;

          overtimePay += overtimePayForPunch;

          // Adjust the total worked hours to avoid calculating overtime again
          totalWorkedHours = 40;  // Cap the total worked hours at 40 after overtime is calculated
        } else {
        }
      });
    });
    return overtimePay;
  };


  const calculateOvertimeDollarAmount = (
      punches, // Use punches for calculating overtime
      employeeData,
      payPeriodStart
  ) => {
    let overtimeDollarAmountForPayD1 = 0;
    let overtimeDollarAmountForPayD2 = 0;

    if (
        employeeData &&
        employeeData.PayD1 &&
        employeeData.PayD2 &&
        employeeData.PayD1_Rate &&
        employeeData.PayD2_Rate
    ) {
      const overtimeHoursForPayD1 = calculateOvertimeForDepartment(
          punches,
          employeeData.PayD1,
          payPeriodStart,
          employeeData
      );
      const overtimeHoursForPayD2 = calculateOvertimeForDepartment(
          punches,
          employeeData.PayD2,
          payPeriodStart,
          employeeData
      );

      // For PayD1 "PER HOUR"
      if (employeeData.PayD1_Type === "PER HOUR") {
        overtimeDollarAmountForPayD1 =
            overtimeHoursForPayD1 * employeeData.PayD1_Rate * 1.5;
      } else if (employeeData.PayD1_Type === "PER ROOM") {
        // For PayD1 "PER ROOM", calculate based on room cleaning during overtime
        overtimeDollarAmountForPayD1 = calculateOvertimePayPerRoom(
            punches.filter((punch) => punch.Department === employeeData.PayD1),
            employeeData,
            payPeriodStart
        );
      }

      // For PayD2 (always "PER HOUR")
      overtimeDollarAmountForPayD2 =
          overtimeHoursForPayD2 * employeeData.PayD2_Rate * 1.5;
    }

    return { overtimeDollarAmountForPayD1, overtimeDollarAmountForPayD2 };
  };

  const overtimeDollarAmounts = calculateOvertimeDollarAmount(
      selectedEmployeeFiltered,
      employeeData,
      payPeriodStart
  );

  // VACATION PER DEPT
  const calculateTotalPTOForDepartment = (punches, department) => {
    const departmentPunches = punches.filter(punch => punch.Department === department);

    // Sum PTO for punches that have a PTO value
    return departmentPunches.reduce((total, punch) => {
      return total + (punch.PTO ? parseFloat(punch.PTO) : 0);
    }, 0);
  };

  const calculateVacationDollarAmount = (punches, employeeData) => {
    let vacationDollarAmountForPayD1 = 0;
    let vacationDollarAmountForPayD2 = 0;

    if (!employeeData || !employeeData.PayD1 || !employeeData.PayD2) {
      return { vacationDollarAmountForPayD1, vacationDollarAmountForPayD2 };
    }

    // PayD1: Calculate PTO hours and dollar amount
    if (employeeData.PayD1_Type === "PER HOUR" && employeeData.PayD1_Rate) {
      const ptoHoursForPayD1 = calculateTotalPTOForDepartment(punches, employeeData.PayD1);
      vacationDollarAmountForPayD1 = ptoHoursForPayD1 * employeeData.PayD1_Rate;
    }

    // PayD2: Calculate PTO hours and dollar amount
    if (employeeData.PayD2_Rate) {
      const ptoHoursForPayD2 = calculateTotalPTOForDepartment(punches, employeeData.PayD2);
      vacationDollarAmountForPayD2 = ptoHoursForPayD2 * employeeData.PayD2_Rate;
    }

    return { vacationDollarAmountForPayD1, vacationDollarAmountForPayD2 };
  };

  // HOLIDAY PER DEPT
  const calculateTotalHolidayHoursForDepartment = (punches, department) => {
    const departmentPunches = punches.filter(punch => punch.Department === department);

    // Sum adjusted holiday hours, considering overtime deductions
    return departmentPunches.reduce((total, punch) => {
      const holidayHours = punch.AdjustedHolidayHours !== undefined ? punch.AdjustedHolidayHours : (punch.HolidayHours || 0);
      return total + parseFloat(holidayHours);
    }, 0);
  };


  const calculateHolidayDollarAmount = (
      punches, // Use punches data to calculate
      employeeData,
      payPeriodStart
  ) => {
    let holidayDollarAmountForPayD1 = 0;
    let holidayDollarAmountForPayD2 = 0;

    // Early return if employeeData is not defined to prevent errors
    if (!employeeData || !employeeData.PayD1 || !employeeData.PayD2) {
      return { holidayDollarAmountForPayD1, holidayDollarAmountForPayD2 };
    }

    // Calculate holiday hours for PayD1 and PayD2 departments
    const holidayHoursForPayD1 = calculateTotalHolidayHoursForDepartment(
        punches,
        employeeData.PayD1
    );
    const holidayHoursForPayD2 = calculateTotalHolidayHoursForDepartment(
        punches,
        employeeData.PayD2
    );

    // PayD1 calculation
    if (employeeData.PayD1_Type === "PER HOUR") {
      holidayDollarAmountForPayD1 =
          holidayHoursForPayD1 * (employeeData.PayD1_Rate * 1.5);
    } else if (employeeData.PayD1_Type === "PER ROOM") {
      // Calculate dollar amount for rooms cleaned during holidays for PayD1
      const totalRoomsDuringHolidayForPayD1 = punches.reduce(
          (totalRooms, punch) => {
            if (punch.Department === employeeData.PayD1 && punch.Holiday) {
              return totalRooms + (punch.Rooms || 0);
            }
            return totalRooms;
          },
          0
      );
      holidayDollarAmountForPayD1 =
          totalRoomsDuringHolidayForPayD1 * (employeeData.PayD1_Rate * 1.5);
    }

    // PayD2 calculation is always PER HOUR
    holidayDollarAmountForPayD2 =
        holidayHoursForPayD2 * (employeeData.PayD2_Rate * 1.5);

    return { holidayDollarAmountForPayD1, holidayDollarAmountForPayD2 };
  };

  // STATS PER DEPT
  let totalHoursForPayD1 = 0;
  let totalHoursForPayD2 = 0;
  let overtimeHoursForPayD1 = 0;
  let overtimeHoursForPayD2 = 0;
  let ptoHoursForPayD1 = 0;
  let ptoHoursForPayD2 = 0;
  let holidayHoursForPayD1 = 0;
  let holidayHoursForPayD2 = 0;
  let dollarAmountForPayD1 = 0;
  let dollarAmountForPayD2 = 0;

  const payD1Type = employeeData?.PayD1_Type;
  const payD1Rate = employeeData?.PayD1_Rate;
  const payD2Rate = employeeData?.PayD2_Rate;

  // Calculate total rooms for PayD1 if needed and employeeData is not null
  let totalRoomsForPayD1 = employeeData
      ? selectedEmployeeFiltered.reduce((total, employee) => {
        // Check for PayD1 "PER ROOM" and ensure employee.Rooms is not undefined
        return (
            total +
            (employee.Department === employeeData.PayD1 ? employee.Rooms || 0 : 0)
        );
      }, 0)
      : 0;

  if (employeeData) {
    // Assuming these calculations occur after employeeData and selectedEmployeeFiltered are confirmed to be loaded
    totalHoursForPayD1 = calculateDecimalHoursForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD1,
        payPeriodStart,
        employeeData
    );
    totalHoursForPayD2 = calculateDecimalHoursForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD2,
        payPeriodStart,
        employeeData
    );

    // Recalculate dollar amounts only after total hours are updated
    dollarAmountForPayD1 =
        payD1Type === "PER HOUR"
            ? totalHoursForPayD1 * payD1Rate
            : totalRoomsForPayD1 * payD1Rate;
    dollarAmountForPayD2 = totalHoursForPayD2 * payD2Rate; // Since PayD2 is always "PER HOUR"
  }

  if (employeeData && employeeData.PayD1 && employeeData.PayD2) {
    // Calculate total hours for each department
    totalHoursForPayD1 = calculateDecimalHoursForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD1,
        payPeriodStart,
        employeeData
    );
    totalHoursForPayD2 = calculateDecimalHoursForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD2,
        payPeriodStart,
        employeeData
    );

    // Calculate overtime hours for each department
    overtimeHoursForPayD1 = calculateOvertimeForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD1,
        payPeriodStart,
        employeeData
    );
    overtimeHoursForPayD2 = calculateOvertimeForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD2,
        payPeriodStart,
        employeeData
    );

    // Calculate PTO hours for each department
    ptoHoursForPayD1 = calculateTotalPTOForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD1
    );
    ptoHoursForPayD2 = calculateTotalPTOForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD2
    );

    // Calculate holiday hours and pass the calculated overtime as an argument
    holidayHoursForPayD1 = calculateTotalHolidayHoursForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD1,
        overtimeHoursForPayD1 // Passing overtime hours for PayD1
    );
    holidayHoursForPayD2 = calculateTotalHolidayHoursForDepartment(
        selectedEmployeeFiltered,
        employeeData.PayD2,
        overtimeHoursForPayD2 // Passing overtime hours for PayD2
    );
  }

  // Safely apply toFixed by checking if the value is undefined first
  const toFixedSafely = (value, digits = 2) => {
    return (value ?? 0).toFixed(digits);
  };

  // Use the safe method for all your toFixed calls
  const regularDollarAmountForPayD1 = toFixedSafely(dollarAmountForPayD1);
  const regularDollarAmountForPayD2 = toFixedSafely(dollarAmountForPayD2);
  const overtimeAmountForPayD1 = toFixedSafely(
      overtimeDollarAmounts.overtimeDollarAmountForPayD1
  );
  const overtimeAmountForPayD2 = toFixedSafely(
      overtimeDollarAmounts.overtimeDollarAmountForPayD2
  );

  // Ensure calculateVacationDollarAmount and calculateHolidayDollarAmount functions
  // are returning objects with the properties `vacationDollarAmountForPayD1`, etc.
  // even when the calculations cannot be performed due to missing data
  const vacationDollarAmounts = calculateVacationDollarAmount(
      selectedEmployeeFiltered,
      employeeData
  );
  const holidayDollarAmounts = calculateHolidayDollarAmount(
      selectedEmployeeFiltered,
      employeeData,
      payPeriodStart
  );

  const vacationAmountForPayD1 = toFixedSafely(
      vacationDollarAmounts.vacationDollarAmountForPayD1
  );
  const vacationAmountForPayD2 = toFixedSafely(
      vacationDollarAmounts.vacationDollarAmountForPayD2
  );
  const holidayAmountForPayD1 = toFixedSafely(
      holidayDollarAmounts.holidayDollarAmountForPayD1
  );
  const holidayAmountForPayD2 = toFixedSafely(
      holidayDollarAmounts.holidayDollarAmountForPayD2
  );

  const overallTips = Number(
      selectedEmployeeFiltered
          .reduce(
              (total, employee) => total + (Number(employee.Tips) || 0),
              0
          )
          .toFixed(2));

  // Calculate total amounts with parsed floats to ensure arithmetic operations
  const totalAmountForPayD1 = [
    regularDollarAmountForPayD1,
    overtimeAmountForPayD1,
    vacationAmountForPayD1,
    holidayAmountForPayD1,
  ].reduce((sum, amount) => sum + parseFloat(amount), 0);

  const totalAmountForPayD2 = [
    regularDollarAmountForPayD2,
    overtimeAmountForPayD2,
    vacationAmountForPayD2,
    holidayAmountForPayD2,
  ].reduce((sum, amount) => sum + parseFloat(amount), 0);

  // Overall total
  const overallTotal = totalAmountForPayD1 + totalAmountForPayD2 + overallTips;

  // PAYROLL NOTES

  const subtractOneDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);

    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");
    const year = newDate.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const formattedPayPeriodEnd = subtractOneDay(payPeriodEnd);
  const [existingChanges, setExistingChanges] = useState([]);

  const handleAddPayrollChange = async () => {
    if (!newChange) return;

    try {
      const payrollChangesRef = collection(db, "payrollChanges");
      const payPeriodEndDate = payPeriodEnd.toDate
          ? payPeriodEnd.toDate()
          : payPeriodEnd;
      const formattedPayPeriodEnd = subtractOneDay(payPeriodEndDate);

      await addDoc(payrollChangesRef, {
        EmpID: selectedEmployeeId,
        NameFirst: selectedEmployeeFiltered[0]?.FirstName,
        NameLast: selectedEmployeeFiltered[0]?.LastName,
        changes: [newChange],
        payPeriod: formattedPayPeriodEnd,
        ChangedBy: user.displayName,
        propCode: selectedPropertyCode,
      });

      setNewChange(""); // Clear the input after saving
      alert("Payroll change added successfully.");

      // Re-fetch the existing changes after adding a new one
      const q = query(
          payrollChangesRef,
          where("EmpID", "==", selectedEmployeeId),
          where("propCode", "==", selectedPropertyCode),
          where("payPeriod", "==", formattedPayPeriodEnd)
      );

      const querySnapshot = await getDocs(q);
      const changes = querySnapshot.docs.map((doc) => doc.data());
      setExistingChanges(changes);
    } catch (error) {
      console.error("Error adding payroll change: ", error);
      alert("Failed to add payroll change.");
    }
  };

  useEffect(() => {
    const fetchExistingChanges = async () => {
      try {
        const payrollChangesRef = collection(db, "payrollChanges");
        const q = query(
            payrollChangesRef,
            where("EmpID", "==", selectedEmployeeId),
            where("propCode", "==", selectedPropertyCode),
            where("payPeriod", "==", formattedPayPeriodEnd)
        );

        const querySnapshot = await getDocs(q);
        const changes = querySnapshot.docs.map((doc) => doc.data());
        setExistingChanges(changes);
      } catch (error) {
        console.error("Error fetching payroll changes: ", error);
      }
    };

    fetchExistingChanges();
  }, [selectedEmployeeId, selectedPropertyCode, formattedPayPeriodEnd]);

  return (
      <div>
        {/*VACATION DIALOG*/}
        <Dialog
            open={openVacationModal}
            onClose={closeVacationModalFn}
            PaperProps={{
              sx: {
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              },
            }}
        >
          <Box
              sx={{
                display: "flex",
                justifyContent: "center",

                position: "absolute",
                top: 9,
                right: 9,
                backgroundColor: "#f5f5f5",
                padding: "0.9rem 1.8rem",

              }}
          >
            <IconButton onClick={() => closeVacationModalFn(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle
              sx={{
                letterSpacing: "0.1rem",
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "1.08rem",
                color: "#ffffff",
                backgroundColor: "#93A683",
                borderRadius: "18px",
              }}
          >
            {selectedEmployeeName || "Select Employee"}
          </DialogTitle>

          <DialogContent
              sx={{
                fontFamily: "Outfit",
                letterSpacing: "0.4rem",
                fontWeight: "400",
                fontSize: "1rem",
              }}
          >
            <Grid container spacing={2}>
              {/* Department Selector */}
              <Grid item xs={12} container justifyContent="center">
                <FormControl variant="standard" fullWidth margin="normal">
                  <InputLabel shrink id="department-select-label" sx={{
                    ...commonSx,
                    ".MuiInputBase-input": { fontSize: "1rem" },
                  }}>
                    DEPARTMENT
                  </InputLabel>
                  <Select
                      labelId="department-select-label"
                      id="department-select"
                      value={vacationData.Department || ""}
                      onChange={handleVacationInputChange}
                      name="Department"
                  >
                    {departments.map((department) => (
                        <MenuItem key={department} value={department}>
                          {department}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Date Selector for Vacation Date */}
              <Grid item xs={12}>
                <TextField
                    label="VACATION DATE"
                    type="date"
                    name="Date"
                    value={vacationData.Date || ""}
                    onChange={handleVacationInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: payPeriodStartISO,
                      max: payPeriodEndPlusOneISO,
                    }}
                    variant="outlined"
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": { fontSize: "1rem" },
                    }}
                />
              </Grid>

              {/* Hours and Minutes Input for PTO */}
              <Grid item xs={6}>
                <TextField
                    label="HOURS"
                    type="number"
                    name="Hours"
                    value={vacationData.Hours || "0"}
                    onChange={handleVacationInputChange}
                    inputProps={{ min: 0, step: 1 }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": { fontSize: "1rem" },
                    }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                    label="MINUTES"
                    type="number"
                    name="Minutes"
                    value={vacationData.Minutes || "0"}
                    onChange={handleVacationInputChange}
                    inputProps={{ min: 0, max: 59, step: 1 }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": { fontSize: "1rem" },
                    }}
                />
              </Grid>

            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleSubmit} color="primary" sx={{
              borderRadius: "18px",
              padding: "9.9px 27px 9.9px 27px",
              marginRight: "2.7px",
              marginBottom: "2.7px",
              fontWeight: "800",
              fontSize: "1rem",
              fontFamily: "Nunito",
              letterSpacing: "0.1rem",
              border: "3.6px solid lightgrey",
              backgroundColor: "lightgrey",
              color: "#202426",
              "&:hover": {
                border: "3.6px solid #grey",
                backgroundColor: "transparent",
                color: "#202426",
              },
            }} disabled={!validateVacationForm()}>
              Submit vaca
            </Button>

          </DialogActions>
        </Dialog>
        {/* ADD PUNCH DIALOG */}
        <Dialog
            open={openAddPunchModal}
            onClose={closeAddPunchModalFn}
            PaperProps={{
              sx: {
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              },
            }}
        >
          <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 1,
              }}
          >
            <Tooltip title="Missing Punches">
              <IconButton
                  sx={{
                    fontSize: "1.5rem",
                    color: badgeCount > 0 ? "#EF3340" : "#202426",
                    paddingLeft: "1px",
                  }}
                  onClick={() => setMissedPunchesDialogOpen(true)}
                  label="MISSING PUNCH"
              >
                <Badge badgeContent={badgeCount} color="error">
                  <EventBusyIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <IconButton onClick={() => closeAddPunchModalFn(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* Dialog title */}
          <DialogTitle
              sx={{
                letterSpacing: "0.1rem",
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "1.08rem",
                color: "#ffffff",
                backgroundColor: "#93A683",
                borderRadius: "18px",
              }}
          >
            <Button
                size="small"
                onClick={handleEmployeeMenuClick}
                sx={{
                  borderRadius: "9px",
                  color: "#ffffff",
                  fontFamily: "Outfit",
                  letterSpacing: "0.1rem",
                  fontWeight: "400",
                  fontSize: "1.08rem",
                  display: "flex",
                  justifyContent: "left",
                  margin: "0 auto",

                  "&:hover": {
                    backgroundColor: "#202426",
                    opacity: "0.6425339366515838",
                    color: "#f9f9f9",
                  },
                }}
            >
              {selectedEmployeeId
                  ? getEmployeeName(selectedEmployeeId)
                  : "Press To Select Employee"}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
              {allEmployees
                  .slice()
                  .sort((a, b) => {
                    return a.FirstName.localeCompare(b.FirstName);
                  })
                  .map((employee) => (
                      <MenuItem
                          key={employee.EmployeeId}
                          onClick={() => handleEmployeeMenuClose(employee.EmployeeId)}
                      >
                        {employee.FirstName} {employee.LastName} (
                        {employee.EmployeeId})
                      </MenuItem>
                  ))}
            </Menu>
          </DialogTitle>
          <DialogContent
              sx={{
                letterSpacing: "0.4rem",
                fontWeight: "400",
                fontSize: "1rem",
              }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} container justifyContent="center">
                {" "}
                {/* Dropdown for Department */}
                <FormControl variant="standard" fullWidth margin="normal">
                  <InputLabel shrink sx={commonSx} id="department-select-label" sx={{
                    ...commonSx,
                    ".MuiInputBase-input": { fontSize: "1rem" },
                  }}>
                    DEPARTMENT
                  </InputLabel>
                  <Select
                      labelId="department-select-label"
                      id="department-select"
                      value={newPunch.Department}
                      onChange={handleDepartmentChange}
                      name="Department"
                      sx={{
                        borderRadius: "18px",
                        marginBottom: "0.2rem",
                        transition: "0.3s",
                        border: "none",
                        ".MuiInputLabel-root": {
                          fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
                          fontWeight: 600,
                          color: "#020202",
                        },
                        ".MuiInputBase-input": {
                          fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
                        },
                      }}
                  >
                    {departments.map((department) => (
                        <MenuItem key={department} value={department}>
                          {department}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                {/* TextField for In Time */}
                <TextField
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": { fontSize: "1rem" },
                    }}
                    label="IN TIME"
                    type="datetime-local"
                    name="InTime"
                    value={newPunch.InTime}
                    onChange={handleNewPunchInputChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: payPeriodStartISO,
                      max: payPeriodEndPlusOneISO,
                    }}
                />
              </Grid>
              <Grid item xs={6}>
                {/* TextField for Out Time */}
                <TextField
                    sx={{
                      ...commonSx,
                      ".MuiInputBase-input": { fontSize: "1rem" },
                    }}
                    label="OUT TIME"
                    type="datetime-local"
                    name="OutTime"
                    value={newPunch.OutTime}
                    onChange={handleNewPunchInputChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: payPeriodStartISO,
                      max: payPeriodEndPlusOneISO,
                    }}
                />
              </Grid>
              <Grid item xs={3}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                >
                  <Typography sx={{ fontWeight: 600, marginRight: 1 }}>
                    Rooms:
                  </Typography>
                  <Typography>
                    {(parseInt(newPunch.A, 10) || 0) +
                        (parseInt(newPunch.B, 10) || 0) +
                        (parseInt(newPunch.C, 10) || 0)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                {/* TextField for A */}
                <TextField
                    sx={commonSx}
                    label="A"
                    type="number"
                    name="A"
                    value={newPunch.A || 0}
                    onChange={handleNewPunchInputChange}
                    inputProps={{ min: 0, step: 1 }}
                    fullWidth
                    margin="normal"
                    disabled={getSelectedDepartmentPayType() !== "PER ROOM"} // Disable the input field if the pay type is not "PER ROOM"
                />
              </Grid>
              <Grid item xs={3}>
                {/* TextField for B */}
                <TextField
                    sx={commonSx}
                    label="B"
                    type="number"
                    name="B"
                    value={newPunch.B || 0}
                    onChange={handleNewPunchInputChange}
                    inputProps={{ min: 0, step: 1 }}
                    fullWidth
                    margin="normal"
                    disabled={getSelectedDepartmentPayType() !== "PER ROOM"} // Disable the input field if the pay type is not "PER ROOM"
                />
              </Grid>
              <Grid item xs={3}>
                {/* TextField for C */}
                <TextField
                    sx={commonSx}
                    label="C"
                    type="number"
                    name="C"
                    value={newPunch.C || 0}
                    onChange={handleNewPunchInputChange}
                    inputProps={{ min: 0, step: 1 }}
                    fullWidth
                    margin="normal"
                    disabled={getSelectedDepartmentPayType() !== "PER ROOM"} // Disable the input field if the pay type is not "PER ROOM"
                />
              </Grid>
              <Grid item xs={12}>
                <Accordion
                    sx={{
                      padding: "none",
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#f5f5f5",
                    }}
                >
                  <AccordionSummary>
                    <MdExpandCircleDown
                        size={19}
                        color="#4a6741"
                        style={{
                          verticalAlign: "middle",
                          marginRight: "99px",
                        }}
                    />
                    ENTER TIPS
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={9}>
                        {/* TextField for Tips */}
                        <TextField
                            sx={commonSx}
                            label="ENTER TIPS"
                            type="number"
                            name="Tips"
                            value={newPunch.Tips}
                            onChange={handleNewPunchInputChange}
                            inputProps={{ min: 0.00, step: 0.01 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                      </Grid>

                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                      padding: "none",
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#f5f5f5",
                    }}
                >
                  <AccordionSummary>
                    <MdExpandCircleDown
                        size={19}
                        color="#4a6741"
                        style={{
                          verticalAlign: "middle",
                          marginRight: "99px",
                        }}
                    />
                    PAID HOLIDAY{" "}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={newPunch.Holiday} // Ensure that newPunch.Holiday is a boolean value
                                  onChange={handleCheckboxChange}
                              />
                            }
                            label="MARK THIS PUNCH AS A PAID HOLIDAY"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              height: "90%",
                              fontSize: "0.9rem",
                              marginLeft: "99.9",
                            }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                      padding: "none",
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#f5f5f5",
                    }}
                >
                  <AccordionSummary>
                    <MdExpandCircleDown
                        size={19}
                        color="RED"
                        style={{
                          verticalAlign: "middle",
                          marginRight: "99px",
                        }}
                    />
                    NO-SHOW{" "}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={noShowChecked}
                                  onChange={handleNoShowCheckboxChange}
                              />
                            }
                            label="MARK THIS PUNCH AS A NO-SHOW"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              height: "90%",
                              fontSize: "0.9rem",
                              marginLeft: "99.9",
                            }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={() => {
                  handleAddPunch();
                  closeAddPunchModalFn();
                }}
                color="primary"
                disabled={!validateForm() || !canClosePayPeriod} // Disable the button if the validation fails
                sx={{
                  borderRadius: "18px",
                  padding: "9.9px 27px 9.9px 27px",
                  marginRight: "2.7px",
                  marginBottom: "2.7px",
                  fontWeight: "800",
                  fontSize: "1rem",
                  fontFamily: "Nunito",
                  letterSpacing: "0.1rem",
                  border: "3.6px solid lightgrey",
                  backgroundColor: "lightgrey",
                  color: "#202426",
                  "&:hover": {
                    border: "3.6px solid #grey",
                    backgroundColor: "transparent",
                    color: "#202426",
                  },
                }}
            >
              ADD PUNCH
            </Button>
          </DialogActions>
        </Dialog>
        {/* CLOSE PAY PERIOD DIALOG */}
        <Dialog
            open={closePayPeriodDialogOpen}
            onClose={() => {
              closeClosePayPeriodDialog();
              resetUserInput();
            }}
            PaperProps={{
              sx: {
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              },
            }}
        >
          <DialogTitle
              sx={{
                letterSpacing: "0.1rem",
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "1.08rem",
                color: "#ffffff",
                backgroundColor: "#93A683",
                borderRadius: "18px",
              }}
          >
            CLOSE TIMECARD
          </DialogTitle>
          <DialogContent>
            <DialogTitle
                sx={{
                  fontWeight: "400",
                  fontSize: "1.1rem",
                  color: "#202426",
                }}
            >
              Type "Y" to close the timecard for:
            </DialogTitle>
            <DialogTitle
                sx={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: "#202426",
                }}
            >
              {getEmployeeName(selectedEmployeeId)}
            </DialogTitle>
            <TextField
                autoFocus
                margin="dense"
                id="confirm-input"
                label="CONFIRM"
                fullWidth
                variant="outlined"
                value={userInput}
                onChange={handleUserInput}
                sx={{
                  ...commonSx,
                  ".MuiInputBase-input": { fontSize: "1rem" },
                }}
            />
          </DialogContent>
          <DialogActions>

            <Button
                onClick={closePayPeriod}
                color="secondary"
                disabled={!isUserInputValid() || isLoading}
                sx={{
                  borderRadius: "18px",
                  padding: "9.9px 27px 9.9px 27px",
                  marginRight: "2.7px",
                  marginBottom: "2.7px",
                  fontWeight: "800",
                  fontSize: "1rem",
                  fontFamily: "Nunito",
                  letterSpacing: "0.1rem",
                  border: "3.6px solid lightgrey",
                  backgroundColor: "lightgrey",
                  color: "#202426",
                  "&:hover": {
                    border: "3.6px solid #grey",
                    backgroundColor: "transparent",
                    color: "#202426",
                  },
                }}
            >
              CLOSE TIMECARD
            </Button>
          </DialogActions>
        </Dialog>
        {/* MISSING PUNCH DIALOG */}
        <Dialog
            open={missedPunchesDialogOpen}
            onClose={() => setMissedPunchesDialogOpen(false)}
            fullWidth={true}
            maxWidth={"lg"}
            PaperProps={{
              sx: {
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f9f9f9",
                width: "80%",
              },
            }}
        >
          <Box
              sx={{
                display: "flex",
                justifyContent: "center",

                position: "absolute",
                top: 9,
                right: 9,
                backgroundColor: "#f5f5f5",
                padding: "0.9rem 1.8rem",

              }}
          >
            <IconButton onClick={() => setMissedPunchesDialogOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle
              sx={{
                letterSpacing: "0.1rem",
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "1.08rem",
                color: "#ffffff",
                backgroundColor: "#93A683",
                borderRadius: "18px",
              }}
          >
            MISSING PUNCHES
          </DialogTitle>
          <DialogContent>
            <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  borderRadius: "9px",
                }}
            >
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" size="small">
                      Name
                    </TableCell>
                    <TableCell align="center" size="small"></TableCell>
                    <TableCell align="left" size="small">
                      In
                    </TableCell>
                    <TableCell align="left" size="small">
                      Out
                    </TableCell>
                    <TableCell align="left" size="small">
                      Department
                    </TableCell>
                    <TableCell align="center" size="small">
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {missedPunches
                      .filter((missedPunch) => {
                        const inTime = missedPunch.InTime
                            ? missedPunch.InTime.toDate()
                            : null;
                        const outTime = missedPunch.OutTime
                            ? missedPunch.OutTime.toDate()
                            : null;
                        return (
                            (inTime &&
                                inTime >= payPeriodStart &&
                                inTime <= payPeriodEnd) ||
                            (outTime &&
                                outTime >= payPeriodStart &&
                                outTime <= payPeriodEnd)
                        );
                      })
                      .map((missedPunch, index) => (
                          <TableRow key={index}>
                            <TableCell align="left" size="small">
                              {missedPunch.FirstName}
                            </TableCell>
                            <TableCell align="left" size="small">
                              {missedPunch.LastName}
                            </TableCell>
                            <TableCell align="left" size="small">
                              {missedPunch.InTime
                                  ? missedPunch.InTime.toDate().toLocaleString()
                                  : "X"}
                            </TableCell>
                            <TableCell align="left" size="small">
                              {missedPunch.OutTime
                                  ? missedPunch.OutTime.toDate().toLocaleString()
                                  : "X"}
                            </TableCell>
                            <TableCell align="left" size="small">
                              {missedPunch.Department}
                            </TableCell>
                            <TableCell align="center" size="small">
                              <IconButton
                                  onClick={() =>
                                      handleDeleteMissedPunch(missedPunch.id)
                                  }
                                  color="#F98B85"
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
        {/* CLOSE ALL PUNCHES */}
        <Dialog
            open={closeAllDialogOpen}
            onClose={() => setCloseAllDialogOpen(false)}
            PaperProps={{
              sx: {
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              },
            }}
        >
          <DialogTitle
              sx={{
                letterSpacing: "0.1rem",
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "1.08rem",
                color: "#ffffff",
                backgroundColor: "#93A683",
                borderRadius: "18px",
              }}
          >
            {operationInProgress
                ? "PLEASE WAIT FOR PROCESSING TO COMPLETE BEFORE NAVIGATING AWAY"
                : "CLOSE ALL TIMECARDS FOR THIS PERIOD?"}
          </DialogTitle>

          {operationInProgress ? (
              <DialogContent>
                <DialogTitle></DialogTitle>
                <Typography
                    sx={{
                      letterSpacing: "0.1rem",
                      fontWeight: "600",
                      fontSize: "1rem",
                      color: "#202426",
                      backgroundColor: "#f5f5f5",
                    }}
                >
                  {payPeriodStart.toLocaleDateString()} -
                  {payPeriodEnd.toLocaleDateString()}
                </Typography>
                <DialogTitle></DialogTitle>
                {logMessages.map((message, index) => (
                    <Typography
                        key={index}
                        sx={{
                          fontSize: "0.8rem",
                          fontWeight: 200,
                          color: "#202426",
                        }}
                    >
                      {message}
                    </Typography>
                ))}
                <DialogTitle></DialogTitle>
                <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                      gap: "1rem",
                    }}
                >
                  {[
                    ...new Map(
                        employees
                            .slice()
                            .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                            .map((employee) => [
                              employee.EmployeeId,
                              {
                                ...employee,
                                StatusIcon: closedPayPeriods[employee.EmployeeId] ? (
                                    <BsFillPatchCheckFill color="#4a6741" />
                                ) : isAnyPunchNeedsFix(employee.EmployeeId) ? (
                                    <BsFillPatchCheckFill color="#EF3340" />
                                ) : areAllPunchesApproved(employee.EmployeeId) ? (
                                    <BsFillPatchCheckFill color="#FFC107" />
                                ) : null,
                              },
                            ])
                    ).values(),
                  ].map((employee) => (
                      <Box
                          key={employee.EmployeeId}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                            color: "#202426",
                          }}
                      >
                        {employee.StatusIcon}{" "}
                        {`${employee.FirstName} ${employee.LastName} (${employee.EmployeeId})`}
                      </Box>
                  ))}
                </Box>
              </DialogContent>
          ) : (
              <DialogActions
                  sx={{
                    justifyContent: "space-between", // Positions buttons on opposite ends
                  }}
              >
                <Button
                    onClick={closeAllPayPeriods}
                    color="secondary"
                    disabled={isLoading} // Assuming 'isLoading' is a state indicating loading status
                    sx={{
                      borderRadius: "18px",
                      padding: "9.9px 27px 9.9px 27px",
                      marginRight: "2.7px",
                      marginBottom: "2.7px",
                      fontWeight: "800",
                      fontSize: "1rem",
                      fontFamily: "Nunito",
                      letterSpacing: "0.1rem",
                      border: "3.6px solid lightgrey",
                      backgroundColor: "lightgrey",
                      color: "#202426",
                      "&:hover": {
                        border: "3.6px solid #grey",
                        backgroundColor: "transparent",
                        color: "#202426",
                      },
                    }}
                >
                  Yes, close all
                </Button>
                <Button
                    onClick={() => setCloseAllDialogOpen(false)}
                    color="secondary"
                    sx={{
                      borderRadius: "27px",
                      padding: "0.5rem",
                      color: "#202426",
                      letterSpacing: "0.1rem",
                      fontSize: "1rem",
                    }}
                >
                  Cancel
                </Button>
              </DialogActions>
          )}
        </Dialog>
        {/* REOPEN PAY PERIOD DIALOG */}
        <Dialog
            open={reOpenPayPeriodDialogOpen}
            onClose={() => {
              closeReOpenPayPeriodDialog();
              resetUserInput();
            }}
            PaperProps={{
              sx: {
                padding: "1rem",
                borderRadius: "36px",
                backgroundColor: "#f5f5f5",
              },
            }}
        >
          <DialogTitle
              sx={{
                letterSpacing: "0.1rem",
                fontFamily: "Nunito",
                fontWeight: "400",
                fontSize: "1.08rem",
                color: "#ffffff",
                backgroundColor: "#93A683",
                borderRadius: "18px",
              }}
          >
            EDIT CLOSED TIMECARD
          </DialogTitle>
          <DialogContent>
            <DialogTitle
                sx={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: "#202426",
                }}
            ></DialogTitle>
            <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "1.1rem",
                  color: "#202426",
                  fontFamily: "Outfit",
                }}
            >
              RE-OPEN TIMECARD FOR:
            </Typography>
            <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: "#202426",
                  fontFamily: "Outfit",
                }}
            >
              {getEmployeeName(selectedEmployeeId)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={() => setReOpenPayPeriodDialogOpen(false)}
                color="secondary"
                sx={{
                  borderRadius: "27px",
                  padding: "0.5rem",
                  color: "#202426",
                  letterSpacing: "0.1rem",
                  fontSize: "1rem",
                }}
            >
              Cancel
            </Button>
            <Button
                onClick={reOpenClosedPayPeriod}
                color="secondary"
                sx={{
                  borderRadius: "18px",
                  padding: "9.9px 27px 9.9px 27px",
                  marginRight: "2.7px",
                  marginBottom: "2.7px",
                  fontWeight: "800",
                  fontSize: "1rem",
                  fontFamily: "Nunito",
                  letterSpacing: "0.1rem",
                  border: "3.6px solid lightgrey",
                  backgroundColor: "lightgrey",
                  color: "#202426",
                  "&:hover": {
                    border: "3.6px solid #grey",
                    backgroundColor: "transparent",
                    color: "#202426",
                  },
                }}
            >
              RE-OPEN
            </Button>
          </DialogActions>
        </Dialog>
        <Card
            sx={{
              borderRadius: "18px",
            }}
        >
          {/* Main container grid */}
          <Grid
              container
              spacing={2}
              sx={{
                padding: "1rem",
                borderRadius: "9px",
                backgroundColor: "#ffffff",
              }}
          >
            {/* Grid for icon buttons */}
            <Grid item xs>
              <Grid item>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                    paddingBottom="1rem"
                >
                  <Grid item>
                    <Tooltip
                        title={isPayPeriodClosedForAll() ? "Pay Period Closed" : ""}
                    >
                      <Box display="flex" flexDirection="row" alignItems="center">
                        {isPayPeriodClosedForAll() ? (
                            <FaCheck
                                style={{ color: "#c7d57f", fontSize: "1.5rem" }}
                            />
                        ) : canClosePayPeriodBasedOnDate() ? (
                            ""
                        ) : (
                            <Tooltip title="Close ALL Timecards">
                          <span>
                            <IconButton
                                sx={{ fontSize: "1.5rem", color: "#F98B85" }}
                                onClick={() => setCloseAllDialogOpen(true)}
                                disabled={isPayPeriodClosedForAll()}
                            >
                              <FcLock />
                            </IconButton>
                          </span>
                            </Tooltip>
                        )}
                      </Box>
                    </Tooltip>
                  </Grid>

                  {!isPayPeriodClosedForAll() && (
                      <Grid item>
                        <Tooltip title="Add Punch">
                          <IconButton
                              sx={{
                                fontSize: "1.5rem",
                                color: "#4a6741",
                              }}
                              onClick={openAddPunchModalFn}
                              label="ADD PUNCH"
                          >
                            <FcPlus />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                  )}
                  <Grid item>
                    <Tooltip title="Missing Punches">
                      <IconButton
                          sx={{
                            fontSize: "1.5rem",
                            color: badgeCount > 0 ? "#EF3340" : "#202426",
                          }}
                          onClick={() => setMissedPunchesDialogOpen(true)}
                          label="MISSING PUNCH"
                      >
                        <Badge badgeContent={badgeCount} color="error">
                          <EventBusyIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {!isPayPeriodClosedForAll() &&(
                      <Grid item>
                        <Tooltip title="Add Vacation Hours">
                          <IconButton
                              sx={{
                                fontSize: "1.62rem",
                                color: "#F2B70A",
                              }}
                              onClick={() => setVacationModalOpen(true)} // This opens the vacation dialog
                              label="ADD VACATION HOURS"
                          >
                            <BsBatteryCharging />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                  )}
                  <Grid item>
                    {!canClosePayPeriod2() ? (
                        <Tooltip title="Close Employee Timecard">
                      <span>
                        <IconButton
                            sx={{
                              fontSize: "1.5rem",
                              color: "grey",
                            }}
                            onClick={openClosePayPeriodDialog}
                            disabled={
                                !selectedEmployeeId || // Check if an employee is selected
                                canClosePayPeriod() ||
                                canClosePayPeriodBasedOnDate() ||
                                isPayPeriodClosedForAll()
                            }
                        >
                          <FaUserLock />
                        </IconButton>
                      </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Re-open Employee Timecard">
                      <span>
                        <IconButton
                            sx={{
                              fontSize: "1.5rem",
                              color: "#c7d57f",
                            }}
                            onClick={openReOpenPayPeriodDialog}
                            disabled={!canClosePayPeriod2()}
                        >
                          <FcUnlock />
                        </IconButton>
                      </span>
                        </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Grid for Pay Period */}
              <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  paddingBottom="1rem"
              >
                {payPeriodStart && payPeriodEnd && (
                    <Grid item xs={12}>
                      <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                      >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >
                          <Grid item>
                            <IconButton
                                onClick={() => changePayPeriod("left")}
                                sx={{
                                  fontSize: "1.3rem",
                                  color: "#8C8C88",
                                  transition: "0.3s",
                                }}
                            >
                              <FaChevronLeft />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <header className="pay-period">
                              {(() => {
                                const payPeriodEndDisplay = new Date(payPeriodEnd);
                                payPeriodEndDisplay.setDate(
                                    payPeriodEndDisplay.getDate() - 1
                                );
                                return `${payPeriodStart.toLocaleDateString()}  -  ${payPeriodEndDisplay.toLocaleDateString()}`;
                              })()}
                            </header>
                          </Grid>
                          <Grid item>
                            <IconButton
                                onClick={() => changePayPeriod("right")}
                                disabled={isPayPeriodEndGreaterThanCurrent}
                                sx={{
                                  fontSize: "1.3rem",
                                  color: "#202426",
                                  transition: "0.3s",
                                }}
                            >
                              <FaChevronRight />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel
                        htmlFor="property-code"
                        sx={{
                          fontSize: "0.7rem",
                          fontWeight: 400,
                          color: "#020202",
                          transition: "0.3s",
                        }}
                    ></InputLabel>
                    <Select
                        value={selectedPropertyCode}
                        onChange={handlePropertyCodeChange}
                        inputProps={{
                          name: "property-code",
                          id: "property-code",
                        }}
                        sx={{
                          fontSize: "0.7rem",
                          backgroundColor: "#ffffff",
                          borderRadius: "27px",
                        }}
                    >
                      {propertyCodes.map((code) => (
                          <MenuItem
                              sx={{
                                fontSize: "0.7rem",
                                fontWeight: 400,
                                color: "#202426",
                                opacity: "0.6425339366515838",
                                transition: "0.3s",
                              }}
                              key={code}
                              value={code}
                          >
                            {code}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel
                        sx={{
                          fontSize: "0.7rem",
                          fontWeight: 400,
                          color: "#202426",
                          opacity: "0.6425339366515838",
                          transition: "0.3s",
                        }}
                        htmlFor="employee-selector"
                        id="employee-select-label"
                    >
                      EMPLOYEE
                    </InputLabel>
                    <Select
                        labelId="employee-select-label"
                        id="employee-select"
                        value={selectedEmployeeId}
                        onChange={(e) => setSelectedEmployeeId(e.target.value)}
                        sx={{
                          fontSize: "0.8rem",
                          backgroundColor: "#ffffff",
                          minWidth: "8em",
                          borderRadius: "27px",
                        }}
                    >
                      {[
                        ...new Map(
                            employees
                                .slice()
                                .sort((a, b) => {
                                  return a.FirstName.localeCompare(b.FirstName);
                                })
                                .map((employee) => [
                                  employee.EmployeeId,
                                  {
                                    EmployeeId: employee.EmployeeId,
                                    FirstName: employee.FirstName,
                                    LastName: employee.LastName,
                                  },
                                ])
                        ).values(),
                      ].map((employee) => (
                          <MenuItem
                              sx={{
                                fontSize: "0.8rem",
                                fontWeight: 400,
                                color: "#202426",
                                opacity: "0.6425339366515838",
                                transition: "0.3s",
                              }}
                              key={employee.EmployeeId}
                              value={employee.EmployeeId}
                          >
                            {closedPayPeriods[employee.EmployeeId] ? (
                                <BsFillPatchCheckFill color="#4a6741" />
                            ) : isAnyPunchNeedsFix(employee.EmployeeId) ? (
                                <BsFillPatchCheckFill color="#EF3340" />
                            ) : areAllPunchesApproved(employee.EmployeeId) ? (
                                <BsFillPatchCheckFill color="#FFC107" />
                            ) : null}{" "}
                            {`${employee.FirstName} ${employee.LastName} (${employee.EmployeeId})`}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TableContainer
                sx={{
                  overflowX: "auto",
                }}
                component={Paper}
            >
              {/* MAIN TABLE */}
              <Table
                  id="PAYROLL"
                  sx={{
                    width: "100%",
                    borderRadius: "27px",
                  }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                        colSpan={3}
                        align="left"
                        sx={{
                          fontWeight: 400,
                          fontSize: "1rem",
                          backgroundColor: "#f5f5f5",
                          color: "#020202",
                          maxWidth: "3.6rem",
                          letterSpacing: "0.1rem",
                        }}
                    >
                      {selectedEmployeeId && payPeriodEnd
                          ? (() => {
                            const employee = employees.find(
                                (emp) => emp.EmployeeId === selectedEmployeeId
                            );

                            if (employee) {
                              const payPeriodEndDisplay = new Date(payPeriodEnd);
                              payPeriodEndDisplay.setDate(
                                  payPeriodEndDisplay.getDate() - 1
                              );
                              return `${employee.FirstName} ${
                                  employee.LastName
                              } ${payPeriodEndDisplay.toLocaleDateString()}`;
                            } else {
                              return "";
                            }
                          })()
                          : ""}
                    </TableCell>

                    <TableCell sx={maintitle200header} align="center"></TableCell>
                    <TableCell sx={maintitle200header} align="left">
                      IN
                    </TableCell>
                    <TableCell sx={maintitle200header} align="right">
                      OUT
                    </TableCell>
                    <TableCell
                        sx={{
                          fontWeight: 200,
                          fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
                          backgroundColor: "#f5f5f5",
                          color: "#020202",
                          maxWidth: "3.6rem",
                          letterSpacing: "0.1rem",
                        }}
                        align="left"
                    ></TableCell>
                    <TableCell sx={maintitle200header} align="left">
                      RMS
                    </TableCell>
                    <TableCell sx={maintitle200header} align="center">
                      TIME
                    </TableCell>
                    <TableCell sx={maintitle200header} align="left">
                      TIPS
                    </TableCell>
                    <TableCell sx={maintitle200header} align="left">
                      VAC
                    </TableCell>
                    <TableCell sx={maintitle200header} align="center">
                      HOL
                    </TableCell>
                    {/* CUMULATIVE */}
                    <TableCell sx={maintitle200header} align="center">
                      TOTAL
                    </TableCell>
                    <TableCell sx={maintitle200header} align="center">
                      STATUS
                    </TableCell>
                    <TableCell sx={maintitle200header} align="center"></TableCell>
                  </TableRow>
                </TableHead>

                {selectedEmployeeId && (
                    <React.Fragment>
                      {Object.entries(groupedEmployeesByWeeks)
                          .sort((a, b) => new Date(a[0]) - new Date(b[0]))
                          .map(([weekNumber, employeesInWeek]) => {
                            const selectedEmployeeFilteredInWeek = selectedEmployeeId
                                ? employeesInWeek.filter(
                                    (employee) =>
                                        employee.EmployeeId === selectedEmployeeId
                                )
                                : employeesInWeek;

                            return (
                                <React.Fragment key={`week-${weekNumber}`}>
                                  <TableBody>
                                    {sortEmployeesByInTime(employeesInWeek)
                                        .filter((employee) => {
                                          return (
                                              selectedEmployeeId === "" ||
                                              employee.EmployeeId === selectedEmployeeId
                                          );
                                        })
                                        .map((employee, index) => {
                                          const {
                                            InTime,
                                            Department,
                                            PTO,
                                            Rooms,
                                            Tips,
                                            PayType,
                                            Status,
                                            PayRate,
                                          } = employee;

                                          // Check whether InTime and OutTime are Firebase Timestamps or JavaScript Dates
                                          const inTime =
                                              InTime instanceof Date
                                                  ? InTime
                                                  : InTime.toDate();
                                          const outTime =
                                              employee.OutTime instanceof Date
                                                  ? employee.OutTime
                                                  : employee.OutTime.toDate();

                                          const timeDifference = getTimeDifference(
                                              inTime,
                                              outTime
                                          );
                                          const ptoValue = employee.PTO
                                              ? parseInt(employee.PTO, 10)
                                              : null;
                                          const totalTime = ptoValue
                                              ? ptoValue
                                              : timeDifference;

                                          // Calculate rate per hour
                                          const ratePerHour = calculateEarningsPerHour(
                                              PayType,
                                              PayRate,
                                              employee,
                                              employeeData
                                          );

                                          const sortedEmployees = employeesInWeek.sort(
                                              (a, b) => {
                                                return (
                                                    a.InTime.toDate() - b.InTime.toDate()
                                                );
                                              }
                                          );

                                          const filteredEmployees =
                                              sortedEmployees.filter((employee) => {
                                                return (
                                                    selectedEmployeeId === "" ||
                                                    employee.EmployeeId === selectedEmployeeId
                                                );
                                              });

                                          const cumulativeTotalHours = filteredEmployees
                                              .slice(0, index + 1)
                                              .reduce((totalHours, currentEmployee) => {
                                                // Check whether InTime and OutTime are Firebase Timestamps or JavaScript Dates
                                                const inTime =
                                                    currentEmployee.InTime instanceof Date
                                                        ? currentEmployee.InTime
                                                        : currentEmployee.InTime.toDate();
                                                const outTime =
                                                    currentEmployee.OutTime instanceof Date
                                                        ? currentEmployee.OutTime
                                                        : currentEmployee.OutTime.toDate();

                                                const timeDifference = getTimeDifference(
                                                    inTime,
                                                    outTime
                                                );

                                                const decimalHours = parseFloat(
                                                    differenceToDecimal(timeDifference)
                                                );

                                                const newTotalHours =
                                                    parseFloat(totalHours) +
                                                    (decimalHours || 0);

                                                return newTotalHours;
                                              }, 0);

                                          const totalTimeDecimal = parseFloat(
                                              differenceToDecimal(timeDifference)
                                          );
                                          const totalRoomsCleaned =
                                              (employee.A || 0) +
                                              (employee.B || 0) +
                                              (employee.C || 0);
                                          const timePerRoom = calculateTimePerRoom(
                                              totalTimeDecimal,
                                              totalRoomsCleaned
                                          );
                                          const timePerRoomInMinutes = timePerRoom * 60;

                                          const handleHolidayChange = (e, employeeId) => {
                                            const isChecked = e.target.checked;
                                            const updatedEmployees = employees.map(
                                                (employee) => {
                                                  if (employee.id === employeeId) {
                                                    return {
                                                      ...employee,
                                                      Holiday: isChecked,
                                                    };
                                                  }
                                                  return employee;
                                                }
                                            );

                                            setEmployees(updatedEmployees);
                                          };

                                          const payPeriodStartDateIndex =
                                              findPayPeriodStartDateIndex(
                                                  sortEmployeesByInTime(
                                                      employeesInWeek
                                                  ).filter((employee) => {
                                                    return (
                                                        selectedEmployeeId === "" ||
                                                        employee.EmployeeId ===
                                                        selectedEmployeeId
                                                    );
                                                  }),
                                                  payPeriodStart
                                              );

                                          return (
                                              <React.Fragment
                                                  key={`employee-${employee.id}`}
                                              >
                                                {payPeriodStartDateIndex === index && (
                                                    <TableRow>
                                                      <TableCell
                                                          colSpan={18}
                                                          sx={{
                                                            fontWeight: "200",
                                                            fontSize: "1rem",
                                                            letterSpacing: "0.2rem",
                                                            color: "#ffffff",
                                                            textAlign: "left",
                                                            backgroundColor: "#4a6741",
                                                          }}
                                                      >
                                                        <BsCalendarWeek /> WK: {weekNumber}
                                                      </TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow hover key={employee.id}>
                                                  <TableCell
                                                      sx={{
                                                        letterSpacing: "0.1rem",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem",
                                                        color: "#020202",
                                                      }}
                                                      size="small"
                                                      align="left"
                                                  >
                                                    {formatDate(InTime.toDate())}
                                                  </TableCell>
                                                  <TableCell
                                                      sx={maintable}
                                                      align="center"
                                                      size="small"
                                                  >
                                                    <Tooltip
                                                        className="nav-icon"
                                                        title={PayType}
                                                    >
                                                      <Box sx={{ fontSize: "1.2rem" }}>
                                                        {" "}
                                                        {PayType === "PER ROOM" ? (
                                                            <MdOutlineKingBed />
                                                        ) : PayType === "PER HOUR" ? (
                                                            <TbClockHour10 />
                                                        ) : PayType === "SALARY" ? (
                                                            <FaBusinessTime />
                                                        ) : PayType === "error" ? (
                                                            <MdClose />
                                                        ) : null}
                                                      </Box>
                                                    </Tooltip>
                                                  </TableCell>
                                                  {/* DEPT */}
                                                  {canViewRates && PayType !== "SALARY" && (
                                                      <TableCell
                                                          sx={maintable}
                                                          size="small"
                                                          align="left"
                                                      >
                                                        {canViewRates ? (
                                                            <Tooltip
                                                                title={
                                                                  PayType === "PER HOUR"
                                                                      ? "@" +
                                                                      PayRate.toFixed(2) +
                                                                      "/hr"
                                                                      : PayType === "PER ROOM" &&
                                                                      !isNaN(ratePerHour)
                                                                          ? "$" + ratePerHour.toFixed(2)
                                                                          : ""
                                                                }
                                                                className="nav-icon"
                                                            >
                                                              <Box
                                                                  sx={{
                                                                    backgroundColor:
                                                                        ratePerHour !== undefined &&
                                                                        ratePerHour < minWage &&
                                                                        PayType === "PER ROOM"
                                                                            ? "#F98B85" // Highlight red if ratePerHour is less than minWage and PayType is "PER ROOM"
                                                                            : "inherit", // Default to not being red for other cases
                                                                    color:
                                                                        Department === "IN-HOUSE"
                                                                            ? "#4a6741"
                                                                            : Department === "FIX"
                                                                                ? "#EF3340"
                                                                                : Department === "VERIFY"
                                                                                    ? "#EF3340"
                                                                                    : Department === "NO-SHOW"
                                                                                        ? "#FFA500" // Orange color
                                                                                        : "#020202",
                                                                    fontSize: "0.8rem",
                                                                  }}
                                                              >
                                                                <Typography
                                                                    sx={{
                                                                      letterSpacing: "0.1rem",
                                                                      fontWeight:
                                                                          Department === "FIX" ||
                                                                          Department === "VERIFY" ||
                                                                          Department === "NO-SHOW"
                                                                              ? "bold"
                                                                              : "600",
                                                                      fontSize: "0.6rem",
                                                                    }}
                                                                >
                                                                  {Department}
                                                                </Typography>
                                                              </Box>
                                                            </Tooltip>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                  backgroundColor:
                                                                      ratePerHour !== undefined &&
                                                                      ratePerHour < minWage &&
                                                                      PayType === "PER ROOM"
                                                                          ? "#F98B85" // Highlight red if ratePerHour is less than minWage and PayType is "PER ROOM"
                                                                          : "inherit", // Default to not being red for other cases
                                                                  color:
                                                                      Department === "IN-HOUSE"
                                                                          ? "#4a6741"
                                                                          : Department === "FIX"
                                                                              ? "#EF3340"
                                                                              : Department === "VERIFY"
                                                                                  ? "#EF3340"
                                                                                  : Department === "NO-SHOW"
                                                                                      ? "#FFA500" // Orange color
                                                                                      : "#020202",
                                                                  fontSize: "0.8rem",
                                                                }}
                                                            >
                                                              <Typography
                                                                  sx={{
                                                                    letterSpacing: "0.1rem",
                                                                    fontWeight:
                                                                        Department === "FIX" ||
                                                                        Department === "VERIFY" ||
                                                                        Department === "NO-SHOW"
                                                                            ? "bold"
                                                                            : "600",
                                                                    fontSize: "0.6rem",
                                                                  }}
                                                              >
                                                                {Department}
                                                              </Typography>
                                                            </Box>
                                                        )}
                                                      </TableCell>
                                                  )}
                                                  {/* MOOD IN */}
                                                  <TableCell
                                                      sx={maintable}
                                                      align="right"
                                                      size="small"
                                                  >
                                                    <Tooltip
                                                        className="nav-icon"
                                                        title={employee.MoodIn}
                                                    >
                                                      <Box sx={{ fontSize: "1.2rem" }}>
                                                        {" "}
                                                        {getMoodIcon(employee.MoodIn)}
                                                      </Box>
                                                    </Tooltip>
                                                  </TableCell>
                                                  {/* IN TIME */}
                                                  <TableCell
                                                      align="left"
                                                      size="small"
                                                      sx={maintable}
                                                  >
                                                    {formatDateTime(
                                                        employee.InTime.toDate()
                                                    )}
                                                  </TableCell>
                                                  {/* OUT TIME */}
                                                  <TableCell
                                                      align="right"
                                                      size="small"
                                                      sx={maintable}
                                                  >
                                                    {formatDateTime(
                                                        employee.OutTime instanceof Date
                                                            ? employee.OutTime
                                                            : employee.OutTime.toDate()
                                                    )}
                                                  </TableCell>
                                                  {/* MOOD OUT */}
                                                  <TableCell
                                                      sx={maintable}
                                                      align="left"
                                                      size="small"
                                                  >
                                                    <Tooltip
                                                        className="nav-icon"
                                                        title={employee.MoodOut}
                                                    >
                                                      <Box sx={{ fontSize: "1.2rem" }}>
                                                        {" "}
                                                        {getMoodIcon(employee.MoodOut)}
                                                      </Box>
                                                    </Tooltip>
                                                  </TableCell>
                                                  {/* ROOMS */}
                                                  <TableCell
                                                      align="center"
                                                      size="small"
                                                      sx={{
                                                        fontWeight: 600,
                                                        fontSize: {
                                                          xs: "0.6rem",
                                                          sm: "0.7rem",
                                                          md: "0.8rem",
                                                        },
                                                        maxWidth: "3.6rem",

                                                        backgroundColor:
                                                            Rooms > 0 ? "#f5df4d" : "inherit",
                                                      }}
                                                  >
                                                    {Rooms !== 0 ? Rooms : ""}
                                                  </TableCell>
                                                  {/* TOTAL TIME WORKED */}
                                                  <TableCell
                                                      align="center"
                                                      size="small"
                                                      sx={{
                                                        fontWeight: 400,
                                                        fontSize: "0.8rem",
                                                        backgroundColor:
                                                            parseFloat(
                                                                formatTimeDifference(
                                                                    timeDifference
                                                                ).split(":")[0]
                                                            ) > 15 ||
                                                            parseFloat(
                                                                formatTimeDifference(
                                                                    timeDifference
                                                                ).split(":")[0]
                                                            ) < 0
                                                                ? "#ffa500"
                                                                : "#ffffff",
                                                        color: "#202426",
                                                        minWidth: "2.7rem",
                                                      }}
                                                  >
                                                    {formatTimeDifference(timeDifference)}
                                                  </TableCell>
                                                  {/* TIPS */}
                                                  <TableCell
                                                      align="left"
                                                      size="small"
                                                      sx={maintable}
                                                  >
                                                    {isNaN(parseFloat(Tips))
                                                        ? ""
                                                        : parseFloat(Tips).toFixed(2)}
                                                  </TableCell>
                                                  {/* VACATION */}
                                                  <TableCell
                                                      align="left"
                                                      size="small"
                                                      sx={maintable}
                                                  >
                                                    {PTO !== 0 ? PTO : ""}
                                                  </TableCell>
                                                  {/* HOLIDAY */}
                                                  <TableCell align="center" size="small">
                                                    <Checkbox
                                                        checked={employee.Holiday}
                                                        disabled={true} // Always disabled
                                                        sx={{
                                                          height: "0.5rem",
                                                          width: "0.5rem",
                                                        }}
                                                    />
                                                  </TableCell>
                                                  {/* CUMULATIVE TIME */}
                                                  <TableCell
                                                      align="center"
                                                      size="small"
                                                      sx={{
                                                        fontWeight: 600,
                                                        fontSize: "0.8rem",
                                                        maxWidth: "3.6rem",

                                                        backgroundColor:
                                                            cumulativeTotalHours > 40
                                                                ? "#F98B85"
                                                                : "inherit",
                                                        color:
                                                            cumulativeTotalHours > 40
                                                                ? "white"
                                                                : "inherit",
                                                      }}
                                                  >
                                                    {cumulativeTotalHours.toFixed(2)}
                                                  </TableCell>
                                                  {/* STATUS */}
                                                  <TableCell
                                                      sx={{
                                                        fontWeight: 400,
                                                        fontSize: "0.8rem",
                                                        maxWidth: "3.6rem",
                                                      }}
                                                      align="center"
                                                      size="small"
                                                  >
                                                    <Tooltip
                                                        title={Status}
                                                        className="nav-icon"
                                                    >
                                                      <Box sx={{ fontSize: "1.2rem" }}>
                                                        {" "}
                                                        {Status === "Open" ? (
                                                            <FcUnlock />
                                                        ) : Status === "Modified" ? (
                                                            <FcDisclaimer />
                                                        ) : Status === "Manual" ? (
                                                            <FcDisclaimer />
                                                        ) : Status === "Closed" ? (
                                                            <FcPrivacy />
                                                        ) : Status === "Mod/Closed" ? (
                                                            <FcExpired />
                                                        ) : Status === "Man/Closed" ? (
                                                            <FcUnlock />
                                                        ) : Status === "Missing" ? (
                                                            <FcImport />
                                                        ) : Status === "Mod/Missing" ? (
                                                            <FcImport />
                                                        ) : null}
                                                      </Box>
                                                    </Tooltip>
                                                  </TableCell>
                                                  <TableCell align="right" size="small">
                                                    {employee.isEditable && (
                                                        <IconButton
                                                            onClick={() =>
                                                                openEditModal(employee.id)
                                                            }
                                                        >
                                                          <Box sx={{ fontSize: "1.2rem" }}>
                                                            {" "}
                                                            <EditIcon />
                                                          </Box>
                                                        </IconButton>
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              </React.Fragment>
                                          );
                                        })}
                                  </TableBody>
                                </React.Fragment>
                            );
                          })}
                    </React.Fragment>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Card>
        <Box height={16} />
        <Card
            sx={{
              borderRadius: "18px",
            }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Grid item>
              <CardHeader
                  title=""
                  sx={{
                    color: "#202426",
                    fontWeight: 200,
                    fontSize: "0.8rem",
                  }}
              />
            </Grid>
            <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
              {/* RENDER WEEK TOTAL TABLE */}
              <Table sx={{ width: "100%" }}>
                <TableHead>{/* ... */}</TableHead>
                {selectedEmployeeId && (
                    <React.Fragment>
                      {Object.entries(groupedEmployeesByWeeks)
                          .sort((b, a) => new Date(a[0]) - new Date(b[0])) // Sort the weeks in ascending order
                          .map(([weekNumber, employeesInWeek]) => {
                            const selectedEmployeeFilteredInWeek = selectedEmployeeId
                                ? employeesInWeek.filter(
                                    (employee) =>
                                        employee.EmployeeId === selectedEmployeeId
                                )
                                : employeesInWeek;

                            return (
                                <React.Fragment key={`week-${weekNumber}`}>
                                  <TableBody>{/* ... */}</TableBody>
                                  {renderWeekTotal(
                                      weekNumber,
                                      selectedEmployeeFilteredInWeek
                                  )}
                                </React.Fragment>
                            );
                          })}
                    </React.Fragment>
                )}
              </Table>
            </TableContainer>
            <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
              {/* TOTALS TABLE */}
              <Table id="TOTAL" sx={{ width: "100%" }}>
                {canViewRates && (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={maintitle} align="center" size="small">
                            TIME
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            HRS
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            OT
                          </TableCell>

                          <TableCell sx={maintitle} align="center" size="small">
                            VAC
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            HOL
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            TIPS
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            RMS
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            A
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            B
                          </TableCell>
                          <TableCell sx={maintitle} align="center" size="small">
                            C
                          </TableCell>
                          <TableCell
                              sx={{
                                letterSpacing: "0.1rem",
                                fontWeight: 400,
                                fontSize: "0.7rem",
                                backgroundColor: "#4a6741",
                                color: "#ffffff",
                                minWidth: "2.34rem",
                              }}
                              align="center"
                              size="small"
                          >
                            MINS/RM
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          {/* TIME */}
                          <TableCell
                              align="center"
                              size="small"
                              sx={{
                                fontWeight: 600,
                                fontSize: "1rem",
                                color: "#202426",
                                backgroundColor: "#ffffff",
                              }}
                          >
                            {formatTimeDifference(
                                selectedEmployeeFiltered.reduce((total, employee) => {
                                  const inTime =
                                      employee.InTime instanceof Date
                                          ? employee.InTime
                                          : employee.InTime.toDate();
                                  const outTime =
                                      employee.OutTime instanceof Date
                                          ? employee.OutTime
                                          : employee.OutTime.toDate();
                                  return total + getTimeDifference(inTime, outTime);
                                }, 0)
                            )}
                          </TableCell>
                          {/* DECIMAL - Total Regular Hours */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {parseFloat(
                                calculateRegularHoursForEmployees(
                                    selectedEmployeeFiltered
                                )
                            ).toFixed(2)}
                          </TableCell>
                          {/* OVERTIME */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {calculateOvertimeForEmployees(
                                selectedEmployeeFiltered,
                                payPeriodStart
                            ).toFixed(2)}
                          </TableCell>

                          {/* PTO */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {calculateTotalPTOForEmployees(
                                selectedEmployeeFiltered
                            )}
                          </TableCell>
                          {/* HOLIDAY */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {parseFloat(
                                calculateTotalHolidayHoursForEmployees(
                                    selectedEmployeeFiltered
                                )
                            ).toFixed(2)}
                          </TableCell>
                          {/* TIPS */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {selectedEmployeeFiltered
                                .reduce(
                                    (total, employee) =>
                                        total + (Number(employee.Tips) || 0),
                                    0
                                )
                                .toFixed(2)}
                          </TableCell>
                          {/* RMS */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {selectedEmployeeFiltered.reduce(
                                (total, employee) => total + employee.Rooms,
                                0
                            )}
                          </TableCell>
                          {/* A */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {selectedEmployeeFiltered.reduce(
                                (total, employee) => total + employee.A,
                                0
                            )}
                          </TableCell>
                          {/* B */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {selectedEmployeeFiltered.reduce(
                                (total, employee) => total + employee.B,
                                0
                            )}
                          </TableCell>
                          {/* C */}
                          <TableCell align="center" size="small" sx={mainSx}>
                            {selectedEmployeeFiltered.reduce(
                                (total, employee) => total + employee.C,
                                0
                            )}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx}>
                            {averageMinutesPerRoom > 0
                                ? `${Math.round(averageMinutesPerRoom)}m`
                                : ""}
                          </TableCell>
                        </TableRow>
                        {/*PER DEPARTMENT*/}
                        <TableRow>
                          <TableCell sx={deptitle} align="right" size="small">
                            DEPT I
                          </TableCell>
                          <TableCell sx={deptitle} align="center" size="small">
                            {employeeData && employeeData.PayD1_Type === 'PER ROOM' ? 'RMS' : 'HRS'}
                          </TableCell>
                          <TableCell sx={deptitle} align="center" size="small">
                            OT
                          </TableCell>

                          <TableCell sx={deptitle} align="center" size="small">
                            VAC
                          </TableCell>
                          <TableCell sx={deptitle} align="center" size="small">
                            HOL
                          </TableCell>
                          <TableCell
                              sx={deptitle}
                              align="center"
                              size="small"
                          ></TableCell>
                          <TableCell sx={deptitle} align="right" size="small">
                            DEPT II
                          </TableCell>
                          <TableCell sx={deptitle} align="center" size="small">
                            HRS
                          </TableCell>
                          <TableCell sx={deptitle} align="center" size="small">
                            OT
                          </TableCell>

                          <TableCell sx={deptitle} align="center" size="small">
                            VAC
                          </TableCell>
                          <TableCell sx={deptitle} align="center" size="small">
                            HOL
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {/* PAYD1 */}
                          <TableCell
                              align="right"
                              size="small"
                              sx={{
                                fontWeight: 600,
                                fontSize: "0.9rem",
                                color: "#202426",
                                backgroundColor: "#f9f9f9",
                              }}
                          >
                            {employeeData ? employeeData.PayD1 : ""}
                          </TableCell>
                          {/* DECIMAL */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {employeeData && employeeData.PayD1_Type === 'PER ROOM'
                                ? totalRoomsForPayD1  // Show the number of rooms if PayD1_Type is PER ROOM
                                : (typeof totalHoursForPayD1 === 'number' && !isNaN(totalHoursForPayD1)
                                    ? totalHoursForPayD1.toFixed(2)  // Show total hours if PayD1_Type is not PER ROOM
                                    : '0.00')}
                          </TableCell>

                          {/* OVERTIME */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {overtimeHoursForPayD1 > 0 ? overtimeHoursForPayD1.toFixed(2) : '0.00'}
                          </TableCell>

                          {/* PTO */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {ptoHoursForPayD1}
                          </TableCell>
                          {/* HOLIDAY */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {typeof holidayHoursForPayD2 === 'number' && !isNaN(holidayHoursForPayD2)
                                ? holidayHoursForPayD1.toFixed(2)
                                : '0.00'}
                          </TableCell>
                          {/* spare */}
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          {/* PAYD2 */}
                          <TableCell
                              align="right"
                              size="small"
                              sx={{
                                fontWeight: 600,
                                fontSize: "0.9rem",
                                color: "#202426",
                                backgroundColor: "#f9f9f9",
                              }}
                          >
                            {employeeData ? employeeData.PayD2 : ""}
                          </TableCell>
                          {/* DECIMAL */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {totalHoursForPayD2.toFixed(2)}
                          </TableCell>
                          {/* OVERTIME */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {overtimeHoursForPayD2.toFixed(2)}
                          </TableCell>

                          {/* PTO */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {ptoHoursForPayD2}
                          </TableCell>
                          {/* HOLIDAY */}
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {holidayHoursForPayD2.toFixed(2)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {/* PAYD1 */}
                          <TableCell
                              align="right"
                              size="small"
                              sx={{
                                fontWeight: 400,
                                fontSize: "0.8rem",
                                color: "#202426",
                                backgroundColor: "#f9f9f9",
                              }}
                          >
                            {employeeData ? employeeData.PayD1_Type : ""}: $
                            {employeeData ? employeeData.PayD1_Rate : ""}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {dollarAmountForPayD1.toFixed(2)}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {overtimeDollarAmounts?.overtimeDollarAmountForPayD1
                                ? overtimeDollarAmounts.overtimeDollarAmountForPayD1.toFixed(
                                    2
                                )
                                : "0.00"}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {calculateVacationDollarAmount(
                                selectedEmployeeFiltered,
                                employeeData
                            ).vacationDollarAmountForPayD1.toFixed(2)}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {calculateHolidayDollarAmount(
                                selectedEmployeeFiltered,
                                employeeData,
                                payPeriodStart
                            ).holidayDollarAmountForPayD1.toFixed(2)}
                          </TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          {/* PAYD2 */}
                          <TableCell
                              align="right"
                              size="small"
                              sx={{
                                fontWeight: 400,
                                fontSize: "0.8rem",
                                color: "#202426",
                                backgroundColor: "#f9f9f9",
                              }}
                          >
                            {employeeData ? employeeData.PayD2_Type : ""}: $
                            {employeeData ? employeeData.PayD2_Rate : ""}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {dollarAmountForPayD2.toFixed(2)}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {overtimeDollarAmounts?.overtimeDollarAmountForPayD2
                                ? overtimeDollarAmounts.overtimeDollarAmountForPayD2.toFixed(
                                    2
                                )
                                : "0.00"}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {calculateVacationDollarAmount(
                                selectedEmployeeFiltered,
                                employeeData
                            ).vacationDollarAmountForPayD2.toFixed(2)}
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            {calculateHolidayDollarAmount(
                                selectedEmployeeFiltered,
                                employeeData,
                                payPeriodStart
                            ).holidayDollarAmountForPayD2.toFixed(2)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {/* GROSS */}
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                          <TableCell
                              align="right"
                              size="small"
                              sx={{
                                fontWeight: 600,
                                fontSize: "1rem",
                                color: "#ffffff",
                                backgroundColor: "#4a6741",
                                letterSpacing: "0.1rem",
                              }}
                          >
                            GROSS:
                          </TableCell>
                          <TableCell align="center" size="small" sx={mainSx2}>
                            ${overallTotal.toFixed(2)}
                          </TableCell>
                          <TableCell
                              align="center"
                              size="small"
                              sx={mainSx2}
                          ></TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                )}
              </Table>
            </TableContainer>
          </Grid>
          <Grid container spacing={2} paddingTop={3} paddingLeft={2}>
            <Grid
                item
                xs={12}
                container
                sx={{
                  backgroundColor: "#f5f5f5",
                }}
            >
              <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    letterSpacing: "0.3rem",
                    fontWeight: "200",
                    fontSize: "0.9rem",
                    color: "#202426",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                    paddingLeft: "0.1rem",
                  }}
              >
                PAYROLL NOTES
              </Typography>

              <Grid container spacing={2} paddingRight={3} paddingLeft={1}>
                {existingChanges.map(
                    (change, index) =>
                        canViewRates &&
                        employeeData.PayD1_Type !== "SALARY" &&
                        employeeData.PayD2_Type !== "SALARY" && (
                            <Grid item xs={12} key={index}>
                              <Typography
                                  variant="body2"
                                  sx={{
                                    padding: "0.45rem",
                                    backgroundColor: "#e0e0e0",
                                    borderRadius: "4.5px",
                                    marginBottom: "0.27rem",
                                  }}
                              >
                                {change.changes}
                              </Typography>
                            </Grid>
                        )
                )}

                <Grid item xs={12}>
                  <TextField
                      fullWidth
                      label="ADD NOTE FOR THIS EMPLOYEE"
                      variant="outlined"
                      name="newChange"
                      value={newChange}
                      onChange={(e) => setNewChange(e.target.value)}
                      multiline
                      rows={3.6}
                      borderRadius={5}
                      paddingTop={0.9}
                  />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="flex-end"
                    paddingBottom={3}
                >
                  <Button
                      variant="contained"
                      onClick={handleAddPayrollChange}
                      disabled={!newChange}
                      sx={{
                        backgroundColor: "transparent",
                        borderRadius: "9px",
                        padding: "9px 9px 9px 9px",
                        color: "#202426",
                        fontWeight: "400",
                        fontSize: "0.72rem",
                        border: "2px solid #202426",
                        "&:hover": {
                          backgroundColor: "#202426",
                          opacity: "0.6425339366515838",
                          color: "#f9f9f9",
                        },
                      }}
                  >
                    ADD NOTE
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        {/* EDIT PUNCH DIALOG */}
        {employees !== null && selectedEmployee !== null && (
            <Dialog
                open={openModal}
                onClose={closeEditModal}
                fullWidth={true}
                maxWidth={"sm"}
                PaperProps={{
                  sx: {
                    padding: "1rem",
                    borderRadius: "27px",
                    backgroundColor: "#f5f5f5",
                  },
                }}
            >
              <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: 1,
                  }}
              >
                <IconButton onClick={() => closeEditModal(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {/* Dialog title */}
              <DialogTitle
                  sx={{
                    letterSpacing: "0.1rem",
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    fontSize: "1.08rem",
                    color: "#ffffff",
                    backgroundColor: "#93A683",
                    borderRadius: "18px",
                  }}
              >
                {getEmployeeName(selectedEmployeeId)} ({selectedEmployeeId})
              </DialogTitle>
              {/* Dialog content with form */}
              <DialogContent
                  sx={{
                    letterSpacing: "0.1rem",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} container justifyContent="center">
                    {/* Dropdown for Department */}
                    <FormControl variant="standard" fullWidth margin="normal">
                      <InputLabel
                          shrink
                          sx={{
                            ...commonSx,
                            ".MuiInputBase-input": { fontSize: "1rem" },
                          }}
                          id="modify-department-select-label"
                      >
                        SELECT DEPARTMENT
                      </InputLabel>
                      <Select
                          labelId="modify-department-select-label"
                          id="modify-department-select"
                          value={
                              dialogState?.Department || employees?.[selectedEmployee]?.Department || ""
                          }

                          onChange={handleModifyDepartmentChange}
                          name="Department"
                          disabled={dialogState.Department === "IN-HOUSE"} // Disable the select when dialogState.Department is "IN-HOUSE"
                          sx={{
                            borderRadius: "18px",
                            marginBottom: "0.2rem",
                            transition: "0.3s",
                            border: "none",
                            ".MuiInputLabel-root": {
                              fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
                              fontWeight: 600,
                              color: "#020202",
                            },
                            ".MuiInputBase-input": {
                              fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
                            },
                          }}
                      >
                        {departments.map((department) => (
                            <MenuItem key={department} value={department}>
                              {department}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    {" "}
                    {/* TextField for In Time */}
                    <TextField
                        sx={{
                          ...commonSx,
                          ".MuiInputBase-input": { fontSize: "1rem" },
                        }}
                        label="IN TIME"
                        type="datetime-local"
                        name="InTime"
                        value={
                          dialogState.InTime
                              ? toLocalISOString(dialogState.InTime)
                              : employees[selectedEmployee] &&
                              employees[selectedEmployee].InTime
                                  ? toLocalISOString(
                                      employees[selectedEmployee].InTime.toDate()
                                  )
                                  : ""
                        }
                        onChange={(event) => {
                          const { value } = event.target;
                          setDialogState({
                            ...dialogState,
                            InTime: isValidDate(value) ? new Date(value) : null,
                          });
                        }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: payPeriodStartISO,
                          max: payPeriodEndPlusOneISO,
                        }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {/* TextField for Out Time */}
                    <TextField
                        sx={{
                          ...commonSx,
                          ".MuiInputBase-input": { fontSize: "1rem" },
                        }}
                        label="OUT TIME"
                        type="datetime-local"
                        name="OutTime"
                        value={
                          dialogState.OutTime instanceof Date
                              ? toLocalISOString(dialogState.OutTime)
                              : employees[selectedEmployee]?.OutTime instanceof Date
                                  ? toLocalISOString(employees[selectedEmployee].OutTime)
                                  : employees[selectedEmployee]?.OutTime
                                      ? toLocalISOString(employees[selectedEmployee].OutTime.toDate())
                                      : ""
                        }
                        onChange={(event) => {
                          const { value } = event.target;
                          setDialogState({
                            ...dialogState,
                            OutTime: isValidDate(value) ? new Date(value) : null,
                          });
                        }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: payPeriodStartISO,
                          max: payPeriodEndPlusOneISO,
                        }}
                    />

                  </Grid>
                  <Grid item xs={3}>
                    {/* TextField for Rooms */}
                    <TextField
                        sx={commonSx}
                        label="ROOMS"
                        type="number"
                        name="Rooms"
                        value={
                            ((dialogState.A ?? employees[selectedEmployee].A) || 0) +
                            ((dialogState.B ?? employees[selectedEmployee].B) || 0) +
                            ((dialogState.C ?? employees[selectedEmployee].C) || 0)
                        }
                        disabled={employees[selectedEmployee].PayType !== "PER ROOM"}
                        inputProps={{ readOnly: true }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {/* TextField for A */}
                    <TextField
                        sx={commonSx}
                        label="A"
                        type="number"
                        name="A"
                        value={dialogState.A ?? employees[selectedEmployee].A ?? 0}
                        onChange={(event) =>
                            setDialogState({
                              ...dialogState,
                              A: parseInt(event.target.value, 10) || 0,
                            })
                        }
                        inputProps={{ min: 0, step: 1 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {/* TextField for B */}
                    <TextField
                        sx={commonSx}
                        label="B"
                        type="number"
                        name="B"
                        value={dialogState.B ?? employees[selectedEmployee].B ?? 0}
                        onChange={(event) =>
                            setDialogState({
                              ...dialogState,
                              B: parseInt(event.target.value, 10) || 0,
                            })
                        }
                        disabled={employees[selectedEmployee].PayType !== "PER ROOM"}
                        inputProps={{ min: 0, step: 1 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {/* TextField for C */}
                    <TextField
                        sx={commonSx}
                        label="C"
                        type="number"
                        name="C"
                        value={dialogState.C ?? employees[selectedEmployee].C ?? 0}
                        onChange={(event) =>
                            setDialogState({
                              ...dialogState,
                              C: parseInt(event.target.value, 10) || 0,
                            })
                        }
                        disabled={employees[selectedEmployee].PayType !== "PER ROOM"}
                        inputProps={{ min: 0, step: 1 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Accordion
                      sx={{
                        padding: "none",
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "#f5f5f5",
                      }}
                  >
                    <AccordionSummary>
                      <MdExpandCircleDown
                          size={21}
                          color="#4a6741"
                          style={{
                            verticalAlign: "middle",
                            marginRight: "99px",
                          }}
                      />
                      ENTER TIPS
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                          {" "}
                          {/* TextField for Tips */}
                          <TextField
                              sx={commonSx}
                              label="ENTER TIPS"
                              type="number"
                              name="Tips"
                              value={employees[selectedEmployee].Tips}
                              onChange={(event) =>
                                  handleInputChange(event, selectedEmployee)
                              }
                              inputProps={{ min: 0, step: 0.01 }}
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                          />
                        </Grid>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                      sx={{
                        padding: "none",
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "#f5f5f5",
                      }}
                  >
                    <AccordionSummary>
                      <MdExpandCircleDown
                          size={21}
                          color="#4a6741"
                          style={{
                            verticalAlign: "middle",
                            marginRight: "99px",
                          }}
                      />
                      PAID HOLIDAY{" "}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>

                        <Grid item xs={12}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={
                                        dialogState.Holiday ??
                                        employees[selectedEmployee].Holiday
                                    }
                                    onChange={(event) =>
                                        setDialogState({
                                          ...dialogState,
                                          Holiday: event.target.checked,
                                        })
                                    }
                                />
                              }
                              label="MARK THIS PUNCH AS A PAID HOLIDAY"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                                height: "90%",
                                fontSize: "0.9rem",
                                marginLeft: "99.9",
                              }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                      sx={{
                        padding: "none",
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "#f5f5f5",
                      }}
                  >
                    <AccordionSummary>
                      <MdExpandCircleDown
                          size={21}
                          color="#4a6741"
                          style={{
                            verticalAlign: "middle",
                            marginRight: "99px",
                          }}
                      />
                      ADD BREAK{" "}
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid container spacing={1}>
                        {/* Break Out field */}
                        <Grid item xs={6}>
                          <TextField
                              sx={commonSx}
                              label="START OF BREAK"
                              type="datetime-local"
                              name="BreakOut"
                              value={
                                dialogState.BreakOut
                                    ? toLocalISOString(dialogState.BreakOut)
                                    : minDateTime
                              }
                              inputProps={{
                                min: minDateTime,
                                max: maxDateTime,
                              }}
                              onChange={(event) => {
                                const { value } = event.target;
                                setDialogState({
                                  ...dialogState,
                                  BreakOut: isValidDate(value)
                                      ? new Date(value)
                                      : null,
                                  BreakEnabled: dialogState.BreakDuration > 0,
                                });
                              }}
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                          />
                        </Grid>
                        {/* Break Duration field */}
                        <Grid item xs={6}>
                          <TextField
                              sx={commonSx}
                              label="DURATION (MINS)"
                              type="number"
                              name="BreakDuration"
                              value={dialogState.BreakDuration}
                              onChange={(event) => {
                                const { value } = event.target;
                                setDialogState({
                                  ...dialogState,
                                  BreakDuration: parseInt(value, 10) || 0,
                                  BreakEnabled: parseInt(value, 10) > 0,
                                });
                              }}
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                      sx={{
                        padding: "none",
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "#f5f5f5",
                      }}
                  >
                    <AccordionSummary>
                      <MdExpandCircleDown
                          size={21}
                          color="#4a6741"
                          style={{
                            verticalAlign: "middle",
                            marginRight: "99px",
                          }}
                      />
                      DELETE PUNCH{" "}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={confirmDeleteChecked}
                                    onChange={handleConfirmDeleteChange}
                                    color="primary"
                                />
                              }
                              label="DELETE"
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </DialogContent>

              {/* Dialog action buttons */}
              <DialogActions>
                <Button
                    onClick={() => {
                      if (confirmDeleteChecked) {
                        handleDelete(selectedEmployee); // Perform delete action
                      } else {
                        handleSave(selectedEmployee); // Perform modify action
                      }

                      // Reset the checkbox state
                      setConfirmDeleteChecked(false);

                      // Close the modal
                      closeEditModal();
                    }}
                    color={confirmDeleteChecked ? "secondary" : "primary"} // Change color if deleting
                    startIcon={confirmDeleteChecked ? <MdDeleteForever /> : null} // Show delete icon if deleting
                    sx={{
                      borderRadius: "18px",
                      padding: "9.9px 27px 9.9px 27px",
                      marginRight: "2.7px",
                      marginBottom: "2.7px",
                      fontWeight: "800",
                      fontSize: "1rem",
                      fontFamily: "Nunito",
                      letterSpacing: "0.1rem",
                      border: "3.6px solid lightgrey",
                      backgroundColor: confirmDeleteChecked ? "#a40606" : "lightgrey", // Change background color if deleting
                      color: confirmDeleteChecked ? "#ffffff" : "#202426", // Change text color if deleting
                      "&:hover": {
                        border: "3.6px solid grey",
                        backgroundColor: confirmDeleteChecked ? "transparent" : "lightgrey", // Change hover behavior
                        color: confirmDeleteChecked ? "#202426" : "#202426", // Change hover color
                      },
                    }}
                    disabled={
                        !confirmDeleteChecked && (
                            !dialogState.Department || // Disable if no department is selected
                            dialogState.Department === "FIX" ||
                            dialogState.Department === "VERIFY" ||
                            dialogState.Department === "IN-HOUSE"
                        )
                    }
                >
                  {confirmDeleteChecked ? "DELETE PUNCH" : "MODIFY PUNCH"} {/* Change button text */}
                </Button>

              </DialogActions>
            </Dialog>
        )}
      </div>
  );
};

export default EmployeeTimePunches;

const commonSx = {
  marginBottom: "0.2rem",
  transition: "0.3s",
  // maxWidth: "18rem",
  ".MuiInputLabel-root": {
    fontSize: { xs: "0.81rem", sm: "0.81rem", md: "0.9rem" },
    fontWeight: 400,
    fontFamily: "Outfit",
    letterSpacing: "0.045rem",
    color: "#020202",
  },
  ".MuiInputBase-input": {
    fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.9rem" },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "9px",
  },
};
const mainSx = {
  letterSpacing: "0.1rem",
  fontWeight: "400",
  fontSize: "0.8rem",
  color: "#020202",
  maxWidth: "3.6rem",
};
const mainSx2 = {
  letterSpacing: "0.1rem",
  fontWeight: "400",
  fontSize: "0.8rem",
  color: "#020202",
  maxWidth: "3.6rem",
  backgroundColor: "f9f9f9",
};
const maintitle = {
  letterSpacing: "0.1rem",
  fontWeight: 200,
  fontSize: "0.9rem",
  backgroundColor: "#4a6741",
  color: "#ffffff",
  minWidth: "2.7rem",
};
const deptitle = {
  letterSpacing: "0.1rem",
  fontWeight: 600,
  fontSize: "0.81rem",
  backgroundColor: "#aec39f",
  color: "#ffffff",
  minWidth: "2.7rem",
};
const maintable = {
  letterSpacing: "0.1rem",
  fontWeight: 400,
  fontSize: "0.9rem",
  backgroundColor: "#ffffff",
  color: "#202426",
  minWidth: "2.7rem",
};

const maintitle200header = {
  fontWeight: 200,
  fontSize: "0.9rem",
  backgroundColor: "#f5f5f5",
  color: "#020202",
  maxWidth: "3.6rem",
  letterSpacing: "0.1rem",
};
const weeklytotals = {
  fontWeight: 400,
  fontSize: "0.8rem",
  minWidth: "2.7rem",
  backgroundColor: "#f9f9f9",
};

