import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase/firebase-config";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  onSnapshot,
  orderBy,
  doc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Box,
  Card,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { BsFillPatchCheckFill } from "react-icons/bs";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import * as XLSX from "xlsx";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { getFunctions, httpsCallable } from "firebase/functions";
import PunchTimecards from "../nsSummary/timecards";

const PunchSummary = () => {
  const { user } = useAuth();
  const [payRollData, setPayRollData] = useState([]);
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [selectedPropertyCode, setSelectedPropertyCode] = useState("");
  const [currentPayRollIndex, setCurrentPayRollIndex] = useState(0);
  const [tableTitle, setTableTitle] = useState("");
  const [selectedPayRoll, setSelectedPayRoll] = useState(null);
  const [payPeriodStart, setPayPeriodStart] = useState(null);
  const [payPeriodEnd, setPayPeriodEnd] = useState(null);
  const [value, setValue] = React.useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "FirstName",
    direction: "asc",
  });
  const [averageRooms, setAverageRooms] = useState({});
  const [averageTimes, setAverageTimes] = useState({});
  const [payrollChanges, setPayrollChanges] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const [showNonVoided, setShowNonVoided] = useState(true);
  const [enhancedEmployees, setEnhancedEmployees] = useState([]);
  const [claims, setClaims] = useState({});
  const [choiceDialogOpen, setChoiceDialogOpen] = useState(true);
  const [actionType, setActionType] = useState('summary');

  const handleMainSwitch = (event) => {
    setActionType(event.target.checked ? 'summary' : 'timecards');
  };

  const canViewGross =
    user.photoURL.includes("Regional") ||
    user.photoURL.includes("Global") ||
    user.photoURL.includes("Accounting") ||
    (claims[`${selectedPropertyCode} Employee Admin`] &&
      claims[`${selectedPropertyCode} View Pay Rates`]);

  useEffect(() => {
    if (user && user.email) {
      fetchUserClaims(user.email).then((claims) => setClaims(claims));
    }
  }, [user]);

  const fetchUserClaims = async (email) => {
    const functions = getFunctions();
    const getUserClaims = httpsCallable(functions, "getUserClaims");

    try {
      const result = await getUserClaims({ email });
      return result.data;
    } catch (error) {
      console.error("Error fetching user claims:", error);
      return {};
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // USE EFFECT FOR PAYROLL SUMMARY
  useEffect(() => {
    let formattedDate = "";
    if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
      let periodEndDate = new Date(
        payRollData[currentPayRollIndex].PeriodEndDate?.toDate()
      );
      periodEndDate.setDate(periodEndDate.getDate() - 1); // subtract one day
      formattedDate = periodEndDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
    setTableTitle(`${selectedPropertyCode} PAYROLL - ${formattedDate}`);
  }, [selectedPropertyCode, currentPayRollIndex, payRollData]);

  useEffect(() => {
    if (
      payRollData.length > 0 &&
      currentPayRollIndex >= 0 &&
      currentPayRollIndex < payRollData.length
    ) {
      setSelectedPayRoll(payRollData[currentPayRollIndex]);
    }
  }, [currentPayRollIndex, payRollData]);

  // USE EFFECT TO FETCH PROPERTY CODE
  useEffect(() => {
    const fetchPropertyCodes = async () => {
      if (!user) return;
      const role = user.photoURL;
      const email = user.email;

      if (!email || !role) return;

      const emailField = `${role}Email`;
      const propertiesRef = collection(db, "Properties");
      const q1 = query(propertiesRef, where(emailField, "==", email));
      const q2 = query(
        propertiesRef,
        where("AdminEmail", "array-contains", email)
      );

      // Fetch based on roleEmail
      const querySnapshot1 = await getDocs(q1);
      const codes = [];
      querySnapshot1.forEach((doc) => {
        codes.push(doc.data().propCode);
      });

      // Fetch based on AdminEmail
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        const propCode = doc.data().propCode;
        if (!codes.includes(propCode)) {
          codes.push(propCode);
        }
      });

      setPropertyCodes(codes);
    };

    fetchPropertyCodes();
  }, [user]);

  useEffect(() => {
    if (selectedPropertyCode) {
      const q = query(
        collection(db, "payRoll"),
        where("PropertyCode", "==", selectedPropertyCode),
        orderBy("PeriodEndDate", "desc")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedPayRollData = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

        setPayRollData(fetchedPayRollData);

        // Calculate average "Time" value and "Rooms" value for each employee
        let averageTimes = {};
        let averageRooms = {};
        const currentYear = new Date().getFullYear();
        fetchedPayRollData.forEach((doc) => {
          if (
            doc.PeriodEndDate?.toDate().getFullYear() === currentYear &&
            doc.Status !== "Void"
          ) {
            // Calculate average time
            const timeParts = doc.Totals?.Time.split(" ");
            const hours = timeParts[0] ? parseInt(timeParts[0]) : 0;
            const minutes = timeParts[2] ? parseInt(timeParts[2]) : 0;
            const totalMinutes = hours * 60 + minutes;

            if (doc.EmployeeId in averageTimes) {
              averageTimes[doc.EmployeeId].push(totalMinutes);
            } else {
              averageTimes[doc.EmployeeId] = [totalMinutes];
            }

            // Calculate average rooms
            const rooms = doc.Totals?.Rooms;
            if (doc.EmployeeId in averageRooms) {
              averageRooms[doc.EmployeeId].push(rooms);
            } else {
              averageRooms[doc.EmployeeId] = [rooms];
            }
          }
        });

        // Compute the average and convert back to "Hh Mm" format for time
        for (let employeeId in averageTimes) {
          const averageMinutes =
            averageTimes[employeeId].reduce((a, b) => a + b, 0) /
            averageTimes[employeeId].length;
          const averageHours = Math.floor(averageMinutes / 60);
          const remainingMinutes = Math.round(averageMinutes % 60);
          averageTimes[employeeId] = `${averageHours}h ${remainingMinutes}m`;
        }

        // Compute the average for rooms
        for (let employeeId in averageRooms) {
          const average =
            averageRooms[employeeId].reduce((a, b) => a + b, 0) /
            averageRooms[employeeId].length;
          averageRooms[employeeId] = Math.round(average);
        }

        setAverageTimes(averageTimes);
        setAverageRooms(averageRooms);
      });

      return () => unsubscribe();
    }
  }, [selectedPropertyCode]);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // PDF STYLESHEET
  const styles = StyleSheet.create({
    page: { flexDirection: "row", backgroundColor: "#ffffff", padding: 9 },
    table: { display: "table", width: "auto", margin: "auto" },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 0,
      borderBottomColor: "grey",
      pageBreakInside: "avoid",
      borderBottomStyle: "solid",
      alignItems: "stretch",
      paddingBottom: 5,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableCol: { width: "9%", flexDirection: "column" },
    tableColWide: { width: "10%", flexDirection: "column" },
    tableColWider: { width: "20%", flexDirection: "column" },
    tableCell: { margin: 3, fontSize: 7, fontWeight: "bold" },
    tableCellD1: { margin: 3, fontSize: 7, textAlign: "left" },
    tableCellD2: { margin: 3, fontSize: 7, textAlign: "left" },
    strikethrough: {
      textDecoration: "line-through",
    },
    tableHead: { margin: 3, fontSize: 11, fontWeight: "bold" },
    borderedRow: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderColor: "black",
      borderStyle: "solid",
    },
    borderedRowTop: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 2,
      borderColor: "black",
      borderStyle: "solid",
    },
    borderedRowBottom: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 2,
      borderColor: "black",
      borderStyle: "solid",
    },
    additionalRow: {
      flexDirection: "row",
      alignItems: "stretch",
    },
  });

  const formatDateMMDDYY = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    const year = String(date.getFullYear()).slice(-2);

    return month + "/" + day + "/" + year;
  };

  // PAYROLL TABLE EXPORT:
  const PayrollTable = ({
    payRollData,
    selectedPayRoll,
    sortKey,
    sortDirection,
  }) => {
    // Filter out voided entries
    const filteredData = payRollData.filter(
      (payRoll) => payRoll.Status !== "Void"
    );

    // Sort the filtered payroll data based on the provided key and direction
    const sortedData = filteredData
      .filter(
        (payRoll) =>
          payRoll.PeriodEndDate?.toDate().getTime() ===
          selectedPayRoll?.PeriodEndDate?.toDate().getTime()
      )
      .sort((a, b) => {
        if (sortDirection === "asc") {
          return a[sortKey].localeCompare(b[sortKey]);
        } else {
          return b[sortKey].localeCompare(a[sortKey]);
        }
      });

    return (
      <View style={styles.section}>
        <Text>{`${calculatedDate} - Payroll Summary (${selectedPropertyCode})`}</Text>

        <View style={styles.section}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>ID</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableHead}>NAME</Text>
            </View>
            <View style={styles.tableColWide}>
              <Text style={styles.tableHead}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>TIME</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>DEC</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>OT</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>VAC</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>HOL</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>RMS</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>A</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>B</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>C</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>TIPS</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHead}>CLOSED</Text>
            </View>
          </View>

          {sortedData.map((payRoll, index) => (
            <React.Fragment key={index}>
              <View
                style={[
                  styles.tableRow,
                  payRoll.Status === "Void"
                    ? styles.strikethrough
                    : styles.borderedRowTop,
                ]}
              >
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.EmployeeId}</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{payRoll.FirstName}</Text>
                </View>
                <View style={styles.tableColWide}>
                  <Text style={styles.tableCell}>{payRoll.LastName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.Time}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Decimal?.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Overtime?.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.PTO}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Holiday}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.Rooms}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.A}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.B}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{payRoll.Totals?.C}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.Totals?.Tips?.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {payRoll.EntryDate
                      ? formatDateMMDDYY(
                          new Date(payRoll.EntryDate.seconds * 1000)
                        )
                      : "-"}
                  </Text>
                </View>
              </View>
              {/* Additional row for payroll.D1 */}
              <View style={styles.additionalRow}>
                <View style={styles.tableCol} />

                <View style={styles.tableColWider}>
                  <Text style={styles.tableCellD1}>{payRoll.PayD1}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>({payRoll.PayD1_Type})</Text>
                </View>
                <View
                  style={[
                    styles.tableCol,
                    payRoll.PayD1_Type === "PER ROOM" ? strikethroughStyle : {},
                  ]}
                >
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.HRS || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.OT || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.VAC || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellD1}>
                    {payRoll.D1?.HOL || "0.00"}
                  </Text>
                </View>
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
                <View style={styles.tableCol} />
              </View>
              {/* Additional row for payroll.D2 */}
              {payRoll.PayD2 && payRoll.PayD2 !== "N/A" && (
                <View style={styles.additionalRow}>
                  <View style={styles.tableCol} />

                  <View style={styles.tableColWider}>
                    <Text style={styles.tableCellD2}>{payRoll.PayD2}</Text>
                  </View>

                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      ({payRoll.PayD2_Type})
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.HRS || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.OT || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.VAC || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellD2}>
                      {payRoll.D2?.HOL || "0.00"}
                    </Text>
                  </View>
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                  <View style={styles.tableCol} />
                </View>
              )}
            </React.Fragment>
          ))}
        </View>
      </View>
    );
  };

  const MyDocument = ({
    payRollData,
    selectedPayRoll,
    sortKey,
    sortDirection,
  }) => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <PayrollTable
          payRollData={payRollData}
          selectedPayRoll={selectedPayRoll}
          sortKey={sortKey}
          sortDirection={sortDirection}
        />
      </Page>
    </Document>
  );

  const handlePropertyCodeChange = (event) => {
    setSelectedPropertyCode(event.target.value);
  };

  let calculatedDate;
  if (payRollData.length > 0 && currentPayRollIndex < payRollData.length) {
    let endDate = payRollData[currentPayRollIndex].PeriodEndDate?.toDate();
    endDate.setDate(endDate.getDate() - 1); // subtract one day
    calculatedDate = endDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else {
      direction = "asc";
    }
    setSortConfig({ key, direction });
  };

  // Function to perform the sorting
  const sortedAndFilteredData = useMemo(() => {
    if (!selectedPayRoll) return [];

    return payRollData
      .filter(
        (payRoll) =>
          payRoll.PeriodEndDate?.toDate().getTime() ===
          selectedPayRoll.PeriodEndDate?.toDate().getTime()
      )
      .filter((payRoll) =>
        showNonVoided ? payRoll.Status !== "Void" : payRoll.Status === "Void"
      )
      .sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
  }, [payRollData, selectedPayRoll, sortConfig, showNonVoided]);

  const getUniqueDates = (data) => {
    const uniqueDates = new Set();
    data.forEach((item) => {
      let endDate = new Date(item.PeriodEndDate.toDate());
      endDate.setDate(endDate.getDate()); // This seems unnecessary unless you're adjusting the date
      uniqueDates.add(endDate.toISOString().split("T")[0]); // Store date as ISO string
    });
    return Array.from(uniqueDates).sort((a, b) => new Date(b) - new Date(a)); // Sort descending
  };

  const uniqueDates = getUniqueDates(payRollData);

  useEffect(() => {
    if (selectedDate && selectedPropertyCode) {
      const fetchPayrollChanges = async () => {
        // Log the initial selectedDate and selectedPropertyCode
        console.log("Selected Date:", selectedDate);
        console.log("Selected Property Code:", selectedPropertyCode);

        // Convert the selectedDate string to a Date object
        const dateObj = new Date(selectedDate);
        // Format the date to MM-dd-yyyy without changing the date
        const formattedDate = dateObj.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });

        // Log the transformed date
        console.log("Transformed Date:", formattedDate);

        const payrollChangesQuery = query(
          collection(db, "payrollChanges"),
          where("payPeriod", "==", formattedDate),
          where("propCode", "==", selectedPropertyCode)
        );
        const querySnapshot = await getDocs(payrollChangesQuery);
        const changes = [];
        querySnapshot.forEach((doc) => {
          console.log("Fetched Change:", doc.data());
          changes.push(doc.data());
        });
        setPayrollChanges(changes);
      };

      fetchPayrollChanges();
    }
  }, [selectedDate, selectedPropertyCode]);

  const groupChangesByEmployee = (changes) => {
    return changes.reduce((acc, change) => {
      (acc[change.EmpID] = acc[change.EmpID] || []).push(change);
      return acc;
    }, {});
  };

  useEffect(() => {
    const dates = getUniqueDates(payRollData);
    if (dates.length > 0 && !selectedDate) {
      setSelectedDate(dates[0]); // Set to the most recent date only if selectedDate is not already set
    }
  }, [payRollData, selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      const newIndex = payRollData.findIndex(
        (period) =>
          new Date(period.PeriodEndDate.toDate())
            .toISOString()
            .split("T")[0] === selectedDate
      );
      if (newIndex !== -1) {
        setCurrentPayRollIndex(newIndex);
      }
    }
  }, [selectedDate, payRollData]);

  const exportToExcel = (payRollData, calculatedDate, selectedPropertyCode) => {
    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(payRollData);

    // Construct a base name for worksheet from date and property code
    const baseName = `${calculatedDate}_${selectedPropertyCode}`;

    // Sanitize the worksheet name to adhere to Excel's requirements
    const sanitizeWorksheetName = (name) => {
      // Excel does not allow certain characters in sheet names and limits the length to 31 characters
      return name.replace(/[:\\/?*\[\]]/g, "").substring(0, 31);
    };

    const worksheetName = sanitizeWorksheetName(baseName);

    // Add the worksheet to the workbook with a sanitized name
    XLSX.utils.book_append_sheet(wb, ws, worksheetName);

    // Write the workbook to a file with a standard naming convention
    const fileName = `${calculatedDate}_Payroll_Summary_${selectedPropertyCode}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const prepareDataForExport = (data) => {
    return data.map((payRoll) => ({
      Status: payRoll.Status,
      PropCode: selectedPropertyCode,
      PeriodEnd: calculatedDate,
      EmployeeId: payRoll.EmployeeId,
      FirstName: payRoll.FirstName,
      LastName: payRoll.LastName,
      Time: payRoll.Totals?.Time,
      Overtime: payRoll.Totals?.Overtime?.toFixed(2),
      Decimal: payRoll.Totals?.Decimal?.toFixed(2),
      Rooms: payRoll.Totals?.Rooms,
      A: payRoll.Totals?.A,
      B: payRoll.Totals?.B,
      C: payRoll.Totals?.C,
      PTO: payRoll.Totals?.PTO,
      Holiday: payRoll.Totals?.Holiday,
      Tips: payRoll.Totals?.Tips?.toFixed(2),
      EntryDate: payRoll.EntryDate
        ? new Date(payRoll.EntryDate.seconds * 1000).toLocaleDateString()
        : "N/A",
      D1: payRoll.PayD1,
      D1_TYPE: payRoll.PayD1_Type,
      D1_HRS:
        payRoll.PayD1_Type === "PER ROOM"
          ? `~~${payRoll.D1?.HRS || "0.00"}~~`
          : payRoll.D1?.HRS,
      D1_OT: payRoll.D1?.OT,
      D1_VAC: payRoll.D1?.VAC,
      D1_HOL: payRoll.D1?.HOL,
      D2: payRoll.PayD2 === "N/A" ? `` : payRoll.PayD2,
      D2_TYPE: payRoll.PayD2 === "N/A" ? `` : payRoll.PayD2_Type,
      D2_HRS: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.HRS,
      D2_OT: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.OT,
      D2_VAC: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.VAC,
      D2_HOL: payRoll.PayD2 === "N/A" ? `` : payRoll.D2?.HOL,
    }));
  };

  const entryTopBorderStyle = {
    borderTop: "1.8px solid black",
  };

  const entryBottomBorderStyle = {
    borderBottom: "1.8px solid black",
  };

  const strikethroughStyle = {
    textDecoration: "line-through",
    color: "red",
  };

  const calculateTotalGross = (data) => {
    return data
      .reduce((total, payRoll) => {
        if (payRoll.Status !== "Void") {
          return total + (parseFloat(payRoll.Totals?.Gross) || 0);
        }
        return total;
      }, 0)
      .toFixed(2);
  };

  const totalGross = useMemo(
    () => calculateTotalGross(sortedAndFilteredData),
    [sortedAndFilteredData]
  );

  // IS PAY PAY PERIOD CLOSED

  useEffect(() => {
    if (selectedDate && selectedPropertyCode) {
      console.log(
        "Setting payPeriodStart and payPeriodEnd based on selectedDate:",
        selectedDate
      );
      const propertiesDoc = doc(db, "Properties", selectedPropertyCode);
      getDoc(propertiesDoc).then((doc) => {
        if (doc.exists()) {
          const { payCycle } = doc.data();
          let selectedEndDate = new Date(selectedDate);
          let selectedStartDate = new Date(selectedDate);

          switch (payCycle) {
            case "Monthly":
              selectedStartDate.setMonth(selectedEndDate.getMonth() - 1);
              break;
            case "Bi-Weekly":
              selectedStartDate.setDate(selectedEndDate.getDate() - 14);
              break;
            case "Weekly":
              selectedStartDate.setDate(selectedEndDate.getDate() - 7);
              break;
            default:
              break;
          }

          console.log("Calculated payPeriodStart:", selectedStartDate);
          console.log("Calculated payPeriodEnd:", selectedEndDate);

          setPayPeriodStart(selectedStartDate);
          setPayPeriodEnd(selectedEndDate);
        }
      });
    }
  }, [selectedDate, selectedPropertyCode]);

  useEffect(() => {
    console.log("Fetching punches based on payPeriodStart and payPeriodEnd:");
    console.log("payPeriodStart:", payPeriodStart);
    console.log("payPeriodEnd:", payPeriodEnd);
    console.log("selectedPropertyCode:", selectedPropertyCode);

    if (payPeriodStart && payPeriodEnd && selectedPropertyCode) {
      const q = query(
        collection(db, "PUNCHES"),
        where("PropCode", "==", selectedPropertyCode),
        where("InTime", ">=", payPeriodStart),
        where("InTime", "<=", payPeriodEnd)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const punches = snapshot.docs.map((doc) => doc.data());
        console.log("Fetched punches:", punches);

        const closedForAll = punches.every(
          (punch) =>
            punch.Status === "Closed" ||
            punch.Status === "Mod/Closed" ||
            punch.Status === "Man/Closed" ||
            punch.Status === "Mod/Missing"
        );

        if (!closedForAll) {
          punches.forEach((punch) => {
            if (
              punch.Status !== "Closed" &&
              punch.Status !== "Mod/Closed" &&
              punch.Status !== "Man/Closed" &&
              punch.Status !== "Mod/Missing"
            ) {
              console.log("Open punch found:", punch);
            }
          });
        }

        console.log("All punches closed:", closedForAll);
        setIsClosed(closedForAll);
      });

      return () => unsubscribe();
    } else {
      console.log("One of the dependencies is missing:");
      console.log("payPeriodStart:", payPeriodStart);
      console.log("payPeriodEnd:", payPeriodEnd);
      console.log("selectedPropertyCode:", selectedPropertyCode);
    }
  }, [payPeriodStart, payPeriodEnd, selectedPropertyCode]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const querySnapshot = await getDocs(collection(db, "empRegister"));
      const employeesData = [];

      querySnapshot.forEach((doc) => {
        employeesData.push({
          id: doc.id,
          EmployeeId: doc.data().EmpID, // Ensure this matches the `EmployeeId` field in `payRollData`
          PayD1: doc.data().PayD1,
          PayD1_Type: doc.data().PayD1_Type,
          PayD2: doc.data().PayD2,
          PayD2_Type: doc.data().PayD2_Type,
        });
      });

      setEnhancedEmployees(employeesData);
    };

    fetchEmployeeData();
  }, []);

  const enhancePayRollData = (payRollData, enhancedEmployees) => {
    return payRollData.map((payRoll) => {
      const employee = enhancedEmployees.find(
        (emp) => emp.EmployeeId === payRoll.EmployeeId
      );
      return {
        ...payRoll,
        PayD1: employee?.PayD1,
        PayD1_Type: employee?.PayD1_Type,
        PayD2: employee?.PayD2,
        PayD2_Type: employee?.PayD2_Type,
      };
    });
  };

  useEffect(() => {
    if (payRollData.length > 0 && enhancedEmployees.length > 0) {
      const enhancedData = enhancePayRollData(payRollData, enhancedEmployees);
      setPayRollData(enhancedData);
    }
  }, [payRollData, enhancedEmployees]);

  return (
      <>
        {actionType && (
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "0.9rem",
                  paddingBottom: "0.9rem",
                  marginBottom: "1rem",
                }}
            >
              <Grid container alignItems="center" justifyContent="center" spacing={2} style={{ margin: '20px 0' }}>
                <Grid item>
                  <Typography
                      sx={{
                        fontFamily: "'Nunito', sans-serif",
                        fontWeight: "400",
                        letterSpacing: "0.09rem",
                        fontSize: "1rem",
                        color: actionType === 'timecards' ? "#010101" : "#a0a0a0", // Highlight active label
                      }}
                  >
                    TIMECARDS
                  </Typography>
                </Grid>

                <Grid item>
                  <Switch
                      checked={actionType === 'summary'}
                      onChange={handleMainSwitch}
                      name="actionTypeSwitch"
                      color="primary"
                      sx={{
                        "& .MuiSwitch-switchBase": {
                          "&.Mui-checked": {
                            color: "#FFC107",
                            "& + .MuiSwitch-track": {
                              backgroundColor: "#FFC107",
                            },
                          },
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "#74c365",
                        },
                      }}
                  />
                </Grid>

                <Grid item>
                  <Typography
                      sx={{
                        fontFamily: "'Nunito', sans-serif",
                        fontWeight: "400",
                        letterSpacing: "0.09rem",
                        fontSize: "1rem",
                        color: actionType === 'summary' ? "#010101" : "#a0a0a0", // Highlight active label
                      }}
                  >
                    SUMMARY
                  </Typography>
                </Grid>
              </Grid>

            </Box>
        )}

        {/* Conditionally render form based on actionType */}
        {actionType === 'summary' && (
    <div>
      {/* HEADER */}
      <Grid
        container
        alignItems="center"
        sx={{
          padding: "2rem",
          alignItems: "center",
        }}
      >
        {/*<Grid*/}
        {/*  item*/}
        {/*  xs={12}*/}
        {/*  sm={4}*/}
        {/*  sx={{*/}
        {/*    paddingTop: { xs: "1rem", sm: 0 },*/}
        {/*    paddingBottom: { xs: "1rem", sm: 0 },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    sx={{*/}
        {/*      ...sharedTextStyles,*/}
        {/*      marginRight: 4.5,*/}
        {/*      letterSpacing: "0.45rem",*/}
        {/*      fontWeight: "300",*/}
        {/*      fontSize: "1.71rem",*/}
        {/*      color: "#202426",*/}
        {/*    }}*/}
        {/*    variant="body1"*/}
        {/*    align="left"*/}
        {/*  >*/}
        {/*    SUMMARY*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}

        <Grid
          item
          xs={6}
          sm={4}
          sx={{
            paddingTop: { xs: "1rem", sm: 0 },
            paddingBottom: { xs: "1rem", sm: 0 },
          }}
        >
          <FormControl
            sx={{
              minWidth: "18rem",
              "@media (max-width: 600px)": {
                minWidth: "12rem",
              },
            }}
          >
            <InputLabel
              htmlFor="property-code"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "#101010",
                marginBottom: "0.2rem",
                transition: "0.3s",
              }}
            >
              PROPERTY CODE
            </InputLabel>
            <Select
              value={selectedPropertyCode}
              onChange={handlePropertyCodeChange}
              inputProps={{
                name: "property-code",
                id: "property-code",
              }}
              sx={{
                fontSize: "0.9rem",
                backgroundColor: "#fcfcfc",
                minWidth: "10em",
                borderRadius: "27px",
              }}
            >
              {propertyCodes.map((code) => (
                <MenuItem key={code} value={code} sx={{ fontSize: "0.9rem" }}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          sx={{
            paddingTop: { xs: "1rem", sm: 0 },
            paddingBottom: { xs: "1rem", sm: 0 },
          }}
        >
          <FormControl
            sx={{
              minWidth: "18rem",
              "@media (max-width: 600px)": {
                minWidth: "12rem",
              },
            }}
          >
            <InputLabel
              htmlFor="Select Pay Period"
              sx={{
                letterSpacing: "0.1rem",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "#101010",
                marginBottom: "0.2rem",
                marginTop: "0.2rem",
                transition: "0.3s",
              }}
            >
              PERIOD
            </InputLabel>
            <Select
              value={selectedDate || ""}
              onChange={(event) => setSelectedDate(event.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Select Pay Period" }}
              sx={{
                fontSize: "0.81rem",
                backgroundColor: "#fcfcfc",
                minWidth: "10em",
                borderRadius: "27px",
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:focus": {
                  border: "none",
                  outline: "none",
                },
                "&:hover": {
                  border: "none",
                },
                marginTop: "0.2rem",
              }}
            >
              {uniqueDates.map((date, index) => (
                <MenuItem
                  key={index}
                  value={date}
                  sx={{
                    fontSize: "0.81rem",
                  }}
                >
                  {new Date(date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box height={16} />

      <Card
        sx={{
          borderRadius: "18px",
        }}
      >
        <Box height={16} />

        {/* PAYROLL SUMMARY */}

        <Box>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={2}
            backgroundColor="#fcfcfc"
            sx={{ borderBottom: "0.5px solid grey" }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
              sx={{
                padding: "2rem",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Grid
                container
                item
                xs={12}
                sm={6}
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-start" }}
                spacing={2}
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!showNonVoided}
                        onChange={(event) =>
                          setShowNonVoided(!event.target.checked)
                        }
                        sx={{
                          "& .MuiSwitch-switchBase": {
                            "&.Mui-checked": {
                              color: "#F98B85",
                              "& + .MuiSwitch-track": {
                                backgroundColor: "#F98B85",
                              },
                            },
                            "&.Mui-checked:hover": {
                              backgroundColor: "rgba(249, 139, 133, 0.08)",
                            },
                            "&.Mui-checked .MuiSwitch-thumb": {
                              backgroundColor: "#F98B85",
                            },
                            "& .MuiSwitch-thumb": {
                              backgroundColor: "#74c365",
                              "&::before": {
                                content: '""',
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundSize: "cover",
                              },
                            },
                          },
                          "& .MuiSwitch-thumb": {
                            backgroundColor: "#74c365",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              backgroundSize: "cover",
                            },
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: "#74c365",
                          },
                          marginLeft: "27px",
                        }}
                      />
                    }
                    label={!showNonVoided ? "VOIDED CARDS" : "FINAL CARDS"}
                    labelPlacement="right"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#101010",
                        fontWeight: "800",
                        fontSize: "0.9rem",
                      },
                    }}
                  />
                </Grid>

                <Grid container item xs={12} sm={6} justifyContent="center">
                  <Grid item>
                    <PDFDownloadLink
                      document={
                        <MyDocument
                          payRollData={payRollData}
                          selectedPayRoll={selectedPayRoll}
                          sortKey={sortConfig.key}
                          sortDirection={sortConfig.direction}
                        />
                      }
                      fileName={`${calculatedDate}_Payroll_Summary_${selectedPropertyCode}.pdf`}
                    >
                      {() => (
                        <IconButton
                          sx={{
                            fontSize: "1.5rem",
                            color: "#FF0000",
                            backgroundColor: "#fcfcfc",
                          }}
                        >
                          <FaFilePdf />
                        </IconButton>
                      )}
                    </PDFDownloadLink>
                    <Typography
                      sx={{
                        fontWeight: "800",
                        fontSize: "0.72rem",
                        color: "#010101",
                      }}
                      variant="caption"
                    >
                      EXPORT TO PDF
                    </Typography>
                  </Grid>

                  <Grid item>
                    <IconButton
                      onClick={() => {
                        exportToExcel(
                          prepareDataForExport(sortedAndFilteredData),
                          `${calculatedDate}_Payroll_Summary_${selectedPropertyCode}`
                        );
                      }}
                      sx={{
                        fontSize: "1.5rem",
                        color: "green",
                        backgroundColor: "#fcfcfc",
                      }}
                    >
                      <FaFileExcel />
                    </IconButton>
                    <Typography
                      sx={{
                        fontWeight: "800",
                        fontSize: "0.72rem",
                        color: "#010101",
                      }}
                      variant="caption"
                    >
                      EXPORT TO EXCEL
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
            <Table id="PAYROLL-SUMMARY" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  {" "}
                  <TableCell align="right" colSpan={14}>
                    <Grid
                      item
                      sx={{
                        letterSpacing: "0.18rem",
                        fontWeight: "800",
                        fontSize: "0.9rem",
                        color: isClosed ? "green" : "#FF0000",
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "0.9rem",
                        marginLeft: "27px",
                      }}
                    >
                      {isClosed ? (
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "800",
                            fontSize: "0.9rem",
                          }}
                        >
                          <BsFillPatchCheckFill
                            style={{
                              color: "#4CAF50",
                              marginRight: "0.5rem",
                              fontSize: "1.5rem",
                            }}
                          />
                          ALL TIMECARDS ARE CLOSED
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "800",
                            fontSize: "0.9rem",
                          }}
                        >
                          <BsFillPatchCheckFill
                            style={{
                              color: "#F98B85",
                              marginRight: "0.5rem",
                              fontSize: "1.5rem",
                            }}
                          />
                          INCOMPLETE SUMMARY
                        </Typography>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" sx={totals} colSpan={1}>
                    {tableTitle}
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    <TableSortLabel
                      active={sortConfig.key === "FirstName"}
                      direction={sortConfig.direction}
                      onClick={() => requestSort("FirstName")}
                    >
                      FIRST
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    <TableSortLabel
                      active={sortConfig.key === "LastName"}
                      direction={sortConfig.direction}
                      onClick={() => requestSort("LastName")}
                    >
                      LAST
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    TIME
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    DEC
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    OT
                  </TableCell>
                  <TableCell align="center" sx={totals}>
                    VAC
                  </TableCell>
                  <TableCell align="center" sx={totals}>
                    HOL
                  </TableCell>
                  <TableCell align="center" sx={totals}>
                    RMS
                  </TableCell>
                  <TableCell align="center" sx={totals}>
                    A
                  </TableCell>
                  <TableCell align="center" sx={totals}>
                    B
                  </TableCell>
                  <TableCell align="center" sx={totals}>
                    C
                  </TableCell>
                  <TableCell align="left" sx={totals}>
                    TIPS
                  </TableCell>
                  <TableCell align="right" sx={totals}>
                    GROSS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAndFilteredData.map((payRoll, index) => {
                  const borderedRowStyle = {
                    borderLeft: "0px solid black",
                    borderRight: "0px solid black",
                  };
                  return (
                    <React.Fragment key={payRoll.id}>
                      <TableRow
                        style={
                          payRoll.Status === "Void"
                            ? strikethroughStyle
                            : { ...borderedRowStyle, ...entryTopBorderStyle }
                        }
                      >
                        <TableCell align="left" size="small" sx={maintable3}>
                          {payRoll.EmployeeId}
                        </TableCell>
                        <TableCell align="left" size="small" sx={maintable3}>
                          {payRoll.FirstName}
                        </TableCell>
                        <TableCell align="left" size="small" sx={maintable3}>
                          {payRoll.LastName}
                        </TableCell>
                        <TableCell align="left" size="small" sx={maintable3}>
                          {payRoll.Totals?.Time}
                        </TableCell>
                        <TableCell align="left" size="small" sx={maintable3}>
                          {payRoll.Totals?.Decimal
                            ? payRoll.Totals.Decimal.toFixed(2)
                            : ""}
                        </TableCell>
                        <TableCell align="left" size="small" sx={maintable3}>
                          {payRoll.Totals?.Overtime
                            ? payRoll.Totals.Overtime.toFixed(2)
                            : ""}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable3}>
                          {payRoll.Totals?.PTO ? payRoll.Totals.PTO : ""}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable3}>
                          {payRoll.Totals?.Holiday
                            ? payRoll.Totals.Holiday
                            : ""}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable3}>
                          {payRoll.Totals?.Rooms ? payRoll.Totals.Rooms : ""}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable3}>
                          {payRoll.Totals?.A ? payRoll.Totals.A : ""}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable3}>
                          {payRoll.Totals?.B ? payRoll.Totals.B : ""}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable3}>
                          {payRoll.Totals?.C ? payRoll.Totals.C : ""}
                        </TableCell>
                        <TableCell align="left" size="small" sx={maintable3}>
                          {payRoll.Totals?.Tips ? payRoll.Totals.Tips : ""}
                        </TableCell>
                        {canViewGross &&
                          payRoll.PayD1_Type !== "SALARY" &&
                          payRoll.PayD2_Type !== "SALARY" && (
                            <TableCell
                              align="right"
                              size="small"
                              sx={maintable3}
                            >
                              ${payRoll.Totals?.Gross || "0.00"}
                            </TableCell>
                          )}
                        {/* <TableCell align="right" size="small" sx={maintable3}>
                            {payRoll.EntryDate
                              ? new Date(
                                  payRoll.EntryDate.seconds * 1000
                                ).toLocaleString()
                              : "-"}
                          </TableCell> */}
                      </TableRow>
                      {/* DEPT 1 Row */}
                      <TableRow
                        key={`${payRoll.id}-D1`}
                        style={borderedRowStyle}
                      >
                        <TableCell
                          align="left"
                          size="small"
                          sx={maintable}
                        ></TableCell>
                        <TableCell
                          align="right"
                          size="small"
                          sx={maintable}
                          colSpan={3}
                        >
                          {payRoll.PayD1} ({payRoll.PayD1_Type})
                        </TableCell>
                        <TableCell
                          align="left"
                          size="small"
                          sx={maintable}
                          style={
                            payRoll.PayD1_Type === "PER ROOM"
                              ? strikethroughStyle
                              : {}
                          }
                        >
                          {payRoll.D1?.HRS || "0.00"}
                        </TableCell>
                        <TableCell align="left" size="small" sx={maintable}>
                          {payRoll.D1?.OT || "0.00"}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable}>
                          {payRoll.D1?.VAC || "0.00"}
                        </TableCell>
                        <TableCell align="center" size="small" sx={maintable}>
                          {payRoll.D1?.HOL || "0.00"}
                        </TableCell>
                        <TableCell colSpan={6} sx={maintable} />
                        {/* {canViewGross &&
                            payRoll.PayD1_Type !== "SALARY" &&
                            payRoll.PayD2_Type !== "SALARY" && (
                              <TableCell
                                align="right"
                                size="small"
                                sx={maintable}
                              >
                                {payRoll.Totals?.Gross || "0.00"}
                              </TableCell>
                            )} */}
                      </TableRow>

                      {/* DEPT 2 Row */}
                      {payRoll.PayD2 && payRoll.PayD2 !== "N/A" && (
                        <TableRow
                          key={`${payRoll.id}-D2`}
                          style={{
                            ...borderedRowStyle,
                            ...entryBottomBorderStyle,
                          }}
                        >
                          <TableCell
                            align="left"
                            size="small"
                            sx={maintable}
                          ></TableCell>
                          <TableCell
                            align="right"
                            size="small"
                            sx={maintable}
                            colSpan={3}
                          >
                            {payRoll.PayD2} (
                            {payRoll.PayD2_Type || "Unknown Type"})
                          </TableCell>
                          <TableCell align="left" size="small" sx={maintable}>
                            {payRoll.D2?.HRS || "0.00"}
                          </TableCell>
                          <TableCell align="left" size="small" sx={maintable}>
                            {payRoll.D2?.OT || "0.00"}
                          </TableCell>
                          <TableCell align="center" size="small" sx={maintable}>
                            {payRoll.D2?.VAC || "0.00"}
                          </TableCell>
                          <TableCell align="center" size="small" sx={maintable}>
                            {payRoll.D2?.HOL || "0.00"}
                          </TableCell>
                          <TableCell colSpan={6} sx={maintable} />
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={12} />
                  <TableCell
                    align="right"
                    size="small"
                    sx={{ fontWeight: "bold" }}
                  >
                    PAYROLL
                  </TableCell>
                  {sortedAndFilteredData.some(
                    (payRoll) =>
                      payRoll.PayD1_Type !== "SALARY" &&
                      payRoll.PayD2_Type !== "SALARY"
                  ) && (
                    <TableCell
                      align="right"
                      size="small"
                      sx={{ fontWeight: "bold" }}
                    >
                      {totalGross}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  marginLeft: 1,
                  letterSpacing: "0.18rem",
                  fontWeight: "200",
                  fontSize: "0.8rem",
                  color: "#101010",
                  paddingTop: "1.8rem",
                  paddingLeft: "0.54rem",
                }}
              >
                NOTES THIS PERIOD
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(groupChangesByEmployee(payrollChanges)).map(
                      ([empId, changes]) => (
                        <React.Fragment key={empId}>
                          {changes.map((change, index) => (
                            <TableRow key={index}>
                              <TableCell
                                align="left"
                                size="small"
                                sx={maintable}
                              >
                                {change.EmpID}
                              </TableCell>
                              <TableCell
                                align="left"
                                size="small"
                                sx={maintable}
                              >{`${change.NameFirst} ${change.NameLast}`}</TableCell>
                              <TableCell
                                align="left"
                                size="small"
                                sx={maintable}
                              >
                                {change.changes.join(", ")}
                              </TableCell>
                              <TableCell
                                align="left"
                                size="small"
                                sx={maintable}
                              >
                                {change.ChangedBy}
                              </TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Box>
      </Card>
    </div>
        )}

        {actionType === 'timecards' && (
            <div>
              <PunchTimecards />
            </div>
        )}
      </>
  );
}

export default PunchSummary;

const maintable = {
  fontWeight: 400,
  fontSize: "0.63rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
  rowHeight: "0.1rem",
};
const maintable3 = {
  fontWeight: 800,
  fontSize: "0.81rem",
  backgroundColor: "#e1dfdc",
  color: "#010101",
  minWidth: "2.7rem",
  letterSpacing: "0.036rem",
};
const totals = {
  fontWeight: 600,
  fontSize: "0.7rem",
  backgroundColor: "#fcfcfc",
  color: "#101010",
};

const sharedTextStyles = {
  fontFamily: "'Outfit', sans-serif",
  fontWeight: 600,
  fontSize: "18px",
  color: "#202426",
  letterSpacing: "1.8px",
};
